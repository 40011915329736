import {useLibrary} from "../../context/LibraryContext";
import {Button, Flex, FormControl, FormLabel, Switch, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import PodcastPostProductionLibrary from "./PodcastPostProductionLibrary";
import PodcastProductionLibrary from "./PodcastProductionLibrary";
import {PodcastCard} from "../../components/podcast/podcast-card";
import PodcastSidebar from "./Podcast-Drawer";
import LibraryNavigationProvider from "../../context/LibraryNavigationContext";

export enum LibraryMode {
    POST_PRODUCTION = 'post-production',
    PRODUCTION = 'production',
}
export default function PodcastLibrary() {
    const library: any = useLibrary()
    const [podcast, setPodcast] = useState<any>()
    const { podcastId }: any = useParams();
    const [currentMode, setCurrentMode] = useState<LibraryMode>(LibraryMode.POST_PRODUCTION)

    const switchMode = () => {
        if (currentMode === LibraryMode.POST_PRODUCTION) {
            setCurrentMode(LibraryMode.PRODUCTION)
        } else {
            setCurrentMode(LibraryMode.POST_PRODUCTION)
        }
    }

    useEffect(() => {
        if (!library.podcastLoading) {
            setPodcast(library.podcasts.get(podcastId))
        }
    }, [library.podcastLoading]);

    //TODO: Add the correct component for each mode
    return (
        <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
            {podcast &&
                <LibraryNavigationProvider>
                    <PodcastSidebar podcast={podcast} />
                </LibraryNavigationProvider>
            }
        </Flex>

    )
}
