import {PodcastEpisode} from "../../models/PodcastEpisode";
import React, {useEffect, useState} from "react";
import {Flex, Grid, useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import {SearchLogo} from "../../assets/SearchIcon";
import {CustomPagination} from "../custom-pagination";
import {ShowCardSkeleton} from "../skeletons";
import {useUser} from "../../context/UserContext";
import {
    LocalizationProcessingType,
    PodcastTranscriptionTaskMeta,
    TranscriptionSession
} from "../../models/Localization";
import NoTaskModal from "../no-task-modal";
import {ReviewerEpisodeCard} from "../reviewer/reviewer-episode-card";
import {MediaContentTranscriptionTaskMeta} from "../../models/MediaContent";

export const ReviewerEpisodeModule = ({sessions, podcastId, loading}: {sessions: TranscriptionSession[], podcastId: string, loading: boolean}) => {
    const user: any = useUser()
    const [searchTerm, setSearchTerm] = useState('');
    const [multiConfigurationEpisodes, setMultiConfigurationEpisodes] = useState<PodcastEpisode[]>([])
    const [displayStyle, setDisplayStyle] = useState<"grid" | "list">('grid')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [page, setPage] = useState(1); // initialize the current page to 1

    const dynamicPageSize = useBreakpointValue({ base: 12, md: 12, lg: 12 , xl: 8 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 8);

    useEffect(() => {
        setPageSize(dynamicPageSize || 8);
    }, [dynamicPageSize]);
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1)
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    // calculate the start and end indices of the episodes to display on the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredEpisodes = sessions.filter((session) => {
        const { title } = session.processing_type === LocalizationProcessingType.POST_PUBLISHING ?
            (session as PodcastTranscriptionTaskMeta).episode
            :
            (session as MediaContentTranscriptionTaskMeta).media
        const normalizedSearchTerm = searchTerm.toLowerCase()
        return (
            title.toLowerCase().includes(normalizedSearchTerm)
        );
    });

    const updateDisplayStyle = (val: "grid" | "list") => {
        setDisplayStyle(val)
    }

    return (
        <Flex w={'full'} flexDirection={'column'} alignItems={'flex-start'} gap={'24px'}>
            <Flex w={'full'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Flex w={'33%'} justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                    <SearchLogo onType={handleSearchInputChange} onOpenWidth={'full'} />
                </Flex>
                <Flex w={'full'} justifyContent={'center'} alignItems={'center'} direction={'row'} gap={'12px'}>
                    <CustomPagination
                        totalPages={Math.ceil(filteredEpisodes.length / pageSize)}
                        currentPage={page}
                        onPageChange={setPage}
                    />
                </Flex>
                <Flex w={'33%'} alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
                </Flex>
            </Flex>
            <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
                {!loading?
                    <>
                        {filteredEpisodes.length > 0 && <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                            {filteredEpisodes.slice(startIndex, endIndex).map((episode: TranscriptionSession) => (
                                <ReviewerEpisodeCard key={`${episode.id}`} episode={episode} userType={user.user.category}/>
                            ))}
                        </Grid>
                        }
                        {filteredEpisodes.length == 0 && <NoTaskModal/>}
                    </>
                    :
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {[...Array(2)].map((_, index) => (
                            <ShowCardSkeleton key={index}/>
                        ))}
                    </Grid>
                }
            </Flex>
        </Flex>
    )
}
