import {PodcastEpisode} from "../../models/PodcastEpisode";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    Grid,
    Slide, Spinner,
    Text, Tooltip,
    useBreakpointValue,
    useDisclosure, useToast
} from "@chakra-ui/react";
import {SearchLogo} from "../../assets/SearchIcon";
import {CustomPagination} from "../custom-pagination";
import {ArrowBackIcon} from "@chakra-ui/icons";
import {EpisodeCard} from "./episode-card";
import {ShowCardSkeleton} from "../skeletons";
import EpisodeDashboardPanel from "./episode-dashboard-panel";
import {useLibrary} from "../../context/LibraryContext";
import {Podcast} from "../../models/Podcast";
import {useUser} from "../../context/UserContext";
import {useLocalization} from "../../context/LocalizationContext";
import { GrUpdate } from "react-icons/gr";


export const BackCatalogModule = ({episodes, podcastId, loading}: {episodes: PodcastEpisode[], podcastId: string, loading: boolean}) => {
    const library: any = useLibrary()
    const user: any = useUser()
    const localization: any = useLocalization()
    const [searchTerm, setSearchTerm] = useState('');
    const [displayCheck, setDisplayCheck] = useState<"hidden" | "visible">('hidden')
    const [displayStyle, setDisplayStyle] = useState<"grid" | "list">('grid')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [page, setPage] = useState(1); // initialize the current page to 1
    const [inConfigurationSize, setInConfigurationSize] = useState<number>(0)
    const [checkAll, setCheckAll] = useState<boolean>(false)
    const [rssLoading, setRssLoading] = useState(false)
    const toast = useToast();


    const dynamicPageSize = useBreakpointValue({ base: 12, md: 12, lg: 12 , xl: 8 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 8);


    useEffect(() => {
        setPageSize(dynamicPageSize || 8);
    }, [dynamicPageSize]);
    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1)
    };
    const selectAllEpisodes = (e: any) => {
        if (e.target.checked) {
            localization.configureAllEpisodes(e, episodes)
        }

        if (!e.target.checked) {
            localization.configureAllEpisodes(e, episodes)
        }
        setCheckAll(e.target.checked)
    }
    const handleClearSearch = () => {
        setSearchTerm('');
    };

    useEffect(() => {
        setInConfigurationSize(Object.keys(localization.episodesConfiguration).length)
    }, [localization.episodesConfiguration])

    // calculate the start and end indices of the episodes to display on the current page
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredEpisodes = episodes.filter((episode) => {
        const { title } = episode;
        const normalizedSearchTerm = searchTerm.toLowerCase();
        return (
            title.toLowerCase().includes(normalizedSearchTerm)
        );
    });


    const updateDisplayStyle = (val: "grid" | "list") => {
        setDisplayStyle(val)
    }
    const fetchRSSFeed =  () => {
        console.log('fetching rss feed')
        setRssLoading(true)
        const podcast: Podcast = library.podcasts.get(podcastId)
        library.setRssLink(podcast.rss_feed, user.user, podcastId)
    }

    return (
        <Flex w={'full'} flexDirection={'column'} alignItems={'flex-start'} gap={'24px'}>
            <Flex w={'full'} direction={'row'} alignItems={'center'}>
                <Flex w={ '85%'} justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                    <SearchLogo onType={handleSearchInputChange} onOpenWidth={'full'} />
                </Flex>
                <Flex w={'full'} justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
                    <Flex justifyContent={'flex-start'} alignItems={'flex-start'} direction={'row'} gap={'12px'}>
                        <CustomPagination
                            totalPages={Math.ceil(filteredEpisodes.length / pageSize)}
                            currentPage={page}
                            onPageChange={setPage}
                        />
                    </Flex>
                    <Flex alignItems={'flex-end'} justifyContent={'flex-end'} direction={'row'}>
                        <Flex alignItems={'center'} justifyContent={'center'} direction={'row'} gap={'12px'}>
                            {rssLoading && <Spinner colorScheme={'teal'}/>}
                            {!rssLoading && <GrUpdate onClick={fetchRSSFeed} />}
                            {displayCheck === 'hidden'?
                                <Button onClick={() => {setDisplayCheck('visible')}} bgColor={'teal.500'} _hover={{bgColor: 'teal.600'}} h={'36px'} borderRadius={'6px'} borderColor={'gray.500'} size="lg" variant={'solid'}>
                                    <Text color={'white'} fontSize={'16px'} fontFamily={'sans-serif'} fontWeight={'600px'}>Multi-configuration</Text>
                                </Button>
                                :
                                <Flex direction={'row'} justifyContent={"center"} align={"center"} gap={'24px'}>
                                    <ArrowBackIcon onClick={() => {
                                        localization.resetConfiguration()
                                        setCheckAll(false)
                                        setDisplayCheck('hidden')
                                    }}/>
                                    <Checkbox colorScheme={'teal'} boxSizing={'border-box'} onChange={selectAllEpisodes} size={'md'} textColor={'gray.600'} border={2} borderRadius={4} borderColor={'gray.700'}>
                                        <Text color={'gray.800'} fontSize={14} fontWeight={500}>Select all</Text>
                                    </Checkbox>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
                {!loading ?
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {filteredEpisodes.slice(startIndex, endIndex).map((episode: PodcastEpisode) => (
                            <EpisodeCard isInMultiConfiguration={checkAll} key={`${episode.id}`} episode={episode} displayCheck={displayCheck}/>
                        ))}
                    </Grid>
                    :
                    <Grid w={'full'} templateColumns='repeat(4, 1fr)' gap={'24px'}>
                        {[...Array(2)].map((_, index) => (
                            <ShowCardSkeleton key={index}/>
                        ))}
                    </Grid>
                }
            </Flex>
            <Slide in={inConfigurationSize > 0 && displayCheck === 'visible'} direction='bottom' style={{ zIndex: 10 }}>
                <Box alignItems="center" bottom="0" bg="white" boxShadow={'lg'} h={20} w="100%" zIndex={2}>
                    <Center>
                        <Button w={'25%'} onClick={onOpen} mt="5" size= "md" colorScheme="teal">Configure ({inConfigurationSize})</Button>
                    </Center>
                </Box>
            </Slide>
            {inConfigurationSize > 0 && <EpisodeDashboardPanel isMultiConfiguration={displayCheck === 'visible'} episode={Object.values(localization.episodesConfiguration) as PodcastEpisode[]} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>}
        </Flex>
    )
}
