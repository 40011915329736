import {Flex, Spinner, TabIndicator, TabList, TabPanel, TabPanels, Tabs, Text, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Tab} from "../../components/ui-utils";
import {TranscriptionSession} from "../../models/Localization";
import {useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {ReviewerEpisodeModule} from "../../components/review/reviewer-episodes-module";

export default function ReviewerDashboard() {
    const user: any = useUser()

    const [sessions, setSessions] = useState<TranscriptionSession[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [tabIndex, setTabIndex] = useState<number>(0)
    const { podcastId }: any = useParams();
    const sessionsReq = useApi('/reviewer/episodes/DeJywyienIQBwckWsjOm3JdMH3t1', user.token, false)

    useEffect(() => {
        if (!user.loading && user.user) {
            sessionsReq.setPayloadData({editor: user.user})
        }
    }, [user.loading])

    useEffect(() => {
        if (!sessionsReq.loading && sessionsReq.data) {
            console.log(sessionsReq.data.length)
            setSessions(sessionsReq.data)
            setLoading(false)
        } else if (!sessionsReq.loading && sessionsReq.error) {
            console.log(`LIBRARY_CONTEXT -> Unable to fetch episodes for publisher: ${user.userId}`)
        }
    }, [sessionsReq.loading])

    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    return (
        <Flex px={'112px'} w="full" flexDirection="column" alignItems={'flex-start'}>
            <Tabs w={'full'} position={'relative'} _selected={{textColor:'teal.500'}} alignItems={'flex-start'} isLazy={true} index={tabIndex} onChange={handleTabsChange} size={'lg'} variant={'unstyled'}>
                <TabList w={'full'}>
                    <Tab>
                        <Text>
                            {`Task (${sessions.length})`}
                        </Text>
                    </Tab>

                </TabList>
                <TabIndicator
                    height="2px"
                    bg="teal.500"
                    alignSelf={'stretch'}
                />
                <TabPanels w={'full'} h={'full'} pt={'24px'}>
                    <TabPanel p={'0px'}>
                        {!loading && <ReviewerEpisodeModule sessions={sessions} podcastId={podcastId} loading={loading}/>}
                        {loading && <Flex w={'full'} h={'full'} justifyContent={'center'} alignItems={'center'}>
                            <Spinner size={'lg'} alignSelf={'center'} color={'teal.500'}/>
                        </Flex>
                        }
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    )
}
