import 'react-circular-progressbar/dist/styles.css';
import {Flex, Image, Spinner, Text} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {LocalizedEpisode, PodcastEpisode} from "../../models/PodcastEpisode";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {TranscriptionState} from "../../models/Localization";
import {TranscriptionProgressComponent} from "./transcription-progress";
import {LocalizationStepComponent} from "./localization-progress";

export default function EpisodeModule({episode}: {episode: PodcastEpisode}) {
    const user: any = useUser()
    const [hasData, setHasData] = useState<boolean>(false)
    const [localizedEpisodes, setLocalizedEpisodes] = useState<LocalizedEpisode[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const localizedEpisodeReq = useApi(ENDPOINTS.PUBLISHER_GET_LOCALIZED_EPISODES.concat(`${episode.id}`), user.token, true)

    useEffect(() => {
        if (!localizedEpisodeReq.loading && localizedEpisodeReq.data) {
            let current: LocalizedEpisode[] = []
            if (localizedEpisodeReq.data.length > 0) {
                console.log(`localizedEpisodeReq.data.length: ${localizedEpisodeReq.data.length}`)
                localizedEpisodeReq.data.forEach((localizedEpisode: LocalizedEpisode) => {
                    current.push(localizedEpisode)
                })
                setHasData(true)
                setLocalizedEpisodes(current)
                setLoading(false)
            }
            setHasData(false)
            setLoading(false)
        }
    }, [localizedEpisodeReq.loading])

    return (
        <Flex w={'full'} direction={'column'} alignItems={'flex-start'} gap="24px" alignSelf={'stretch'}>
            <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="12px" flex="1 0 0" borderRadius="12px" border="1px" borderColor="gray.300">
                <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                    <Image src={episode.image_url? episode.image_url : episode.podcast.image_url} boxSize={'64px'} borderRadius="12px" />
                    <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                        <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            {episode.title}
                        </Text>
                        <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                            <Text color="gray.500" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                {secondsToHHMMSS(episode.duration)}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex display={'flex'} direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} alignSelf={'stretch'}>
                    <Text textAlign={'left'} wordBreak={'break-word'} color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                        {episode.summary}
                    </Text>
                </Flex>
            </Flex>
            <Flex w={'full'} p="16px" flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
                <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    TRANSCRIPTION
                </Text>
                {episode.transcription !== undefined && episode.transcription.state !== TranscriptionState.NOT_CREATED? <TranscriptionProgressComponent progress={episode.transcription}/> : null}
            </Flex>
            <Flex w={'full'} p="16px" flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
                <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    LOCALIZATION
                </Text>
                {loading?
                    <Spinner alignSelf={'center'} size={'lg'} color={'teal'} p={'6px'}/>
                    :
                    <>
                        {!loading && localizedEpisodes.length === 0? <Text w={'full'} color="gray.600" align={'start'} fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            No episodes in progress.
                        </Text> : null}
                        {!loading && localizedEpisodes.length > 0?
                            <>
                                {localizedEpisodes.map((localizedEpisode: LocalizedEpisode) => {
                                    return (
                                        <LocalizationStepComponent key={localizedEpisode.id} localizedEpisode={localizedEpisode}/>
                                    )
                                })}
                            </>
                            :
                            null
                        }
                    </>
                }
            </Flex>
        </Flex>
    )
}
