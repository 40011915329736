import {Editor, Path, Transforms} from "slate";
const alignSTT = require('stt-align-node')
function isSelectionCollapsed(anchorOffset: number, focusOffset: number): boolean {
    return anchorOffset === focusOffset;
}

function splitTextAtOffset(text: string, offset: number): [string, string] {
    const textBefore = text.slice(0, offset);
    const textAfter = text.slice(offset);
    return [textBefore, textAfter];
}

function splitWordsListAtOffset(words: any[], offset: number): [any[], any[]] {
    // Need to split based on word boundaries, not character offset
    let wordIndex = 0;
    let charCount = 0;
    while (wordIndex < words.length && charCount < offset) {
        charCount += words[wordIndex].text.trim().length + 1; // +1 for space
        wordIndex++;
    }
    // Adjust the wordIndex if the offset falls in the middle of a word
    if (charCount > offset && wordIndex > 0) {
        wordIndex--;
    }
    const wordsBefore = words.slice(0, wordIndex);
    const wordsAfter = words.slice(wordIndex);
    return [wordsBefore, wordsAfter];
}


export const splitOnWhiteSpaces = (text: string): string[] => {
    return removeExtraWhiteSpaces(text).split(' ');
};

export const countChar = (text: string): number => {
    return splitOnWhiteSpaces(text).join('').length;
};

const countWords = (text: string): number => {
    return splitOnWhiteSpaces(text).length;
};

function isTextSameAsWordsList(textBefore: string, wordsBefore: any[]): boolean {
    const textBeforeList = textBefore.trim().replace(/\s\s+/g, ' ').split(' ');
    const wordsBeforeList = wordsBefore.map((w) => {
        return w.text;
    });
    const lastTextWord = textBeforeList[textBeforeList.length - 1];
    const lastWord = wordsBeforeList[wordsBeforeList.length - 1];
    const result = !(lastTextWord.trim() === lastWord.trim());
    return result;
}

function convertWordsToText(words: any[]): string {
    return words
        .map((word) => {
            return word.text ? word.text.trim() : '';
        })
        .join(' ');
}

export const removeExtraWhiteSpaces = (text: string): string => {
    return text.trim().replace(/\s\s+/g, ' ');
};

export function isTextAndWordsListChanged({ text, words }: { text: string, words: any[] }): boolean {
    const wordsText = convertWordsToText(words);
    return !(removeExtraWhiteSpaces(text) === wordsText);
}

function removeEmptyWords(words: any[]): any[] {
    return words.filter((word) => {
        return word.text;
    });
}

function isEqualNumberOfWords({ text, words }: { text: string, words: any[] }): boolean {
    const sanitizedWords = removeEmptyWords(words);
    const wordsText = convertWordsToText(sanitizedWords);
    const textCount = countChar(text);
    const wordsCount = countChar(wordsText);
    return textCount === wordsCount;
}


export function alignBlock({ block, text, words }: { block: any, text: string, words: any[] }): any {
    const newBlock = JSON.parse(JSON.stringify(block));
    if (isEqualNumberOfWords({ text, words })) {
        const textList = splitOnWhiteSpaces(text);
        const newWords = JSON.parse(JSON.stringify(words));
        newBlock.children[0].words = newWords.map((word: any, index: number) => {
            word.text = textList[index];
            return word;
        });
        return newBlock;
    }
    const alignedWords = alignSTT({ words }, text);
    newBlock.children[0].words = removeEmptyWords(alignedWords);
    return newBlock;
}
function getClosestBlock(editor: any): [blockNode: any, path: number[]] | null {
    if (!editor.selection) return null;
    const [blockNode, path] = Editor.above(editor, { match: n => Editor.isBlock(editor, n) }) || [null, null];
    if (blockNode && path) {
        return [blockNode, path];
    }
    return null;
}

function isEndOftheBlock({ anchorOffset, focusOffset, totalChar }: { anchorOffset: number, focusOffset: number, totalChar: number }): boolean {
    return anchorOffset === focusOffset && anchorOffset === totalChar;
}

function isBeginningOftheBlock(anchorOffset: number, focusOffset: number): boolean {
    return anchorOffset === 0 && focusOffset === 0;
}

function isSameBlock(anchorPath: number[], focusPath: number[]): boolean {
    return anchorPath[0] === focusPath[0];
}

function createNewParagraphBlock({ speaker, start, text = '', words = [], end, speakersList }: { speaker: any, start: any, text?: string, words?: any[], end: any, speakersList: any }): any {
    return {
        speaker,
        start,
        end,
        type: 'timedText',
        speakersList,
        children: [
            {
                text,
                words,
            },
        ],
    };
}

function removeNodes({ editor, options = {} }: { editor: Editor, options?: any }): void {
    Transforms.removeNodes(editor, options);
}

function getSplitIndex(text: string, anchorOffset: number): number {
    const leftSpaceIndex = text.lastIndexOf(' ', anchorOffset - 1);
    const rightSpaceIndex = text.indexOf(' ', anchorOffset);
    if (anchorOffset !== text.length && leftSpaceIndex !== -1) {
        return leftSpaceIndex + 1; // to start after the space
    } else if (anchorOffset !== 0 && rightSpaceIndex !== -1) {
        return rightSpaceIndex; // to start from the space
    }
    return anchorOffset;
}
function insertNodesAtSelection({ editor, blocks, moveSelection = false, options = {} }: { editor: any, blocks: any[], moveSelection?: boolean, options?: any }): void {
    Transforms.insertNodes(editor, [...blocks], options);
    if (moveSelection) {
        const nextPoint = Editor.after(editor, editor.selection.anchor);
        console.info('nextPoint', nextPoint);
        Transforms.setSelection(editor, { anchor: nextPoint, focus: nextPoint });
    }
}
function collapseSelectionToAsinglePoint(editor: Editor): void {
    Transforms.collapse(editor, { edge: 'start' });
}

export const handleSplitParagraph = (editor: any): boolean => {
    if (!editor.selection) {
        console.info('No selection is present.');
        return false;
    }

    const { anchor } = editor.selection;
    const { offset: anchorOffset, path: anchorPath } = anchor;

    const [blockNode, blockPath] = Editor.above(editor, {
        match: n => Editor.isBlock(editor, n)
    }) || [null, null];

    if (!blockNode) {
        console.info('No block found at the selection path.');
        return false;
    }

    // Index of the selected child node within the blockNode
    const childIndex = anchorPath[anchorPath.length - 1];
    const childNode: any = blockNode.children[childIndex];

    if (!childNode) {
        console.info('No child node found at the selection path.');
        return false;
    }

    const text = childNode.text;
    const [textBefore, textAfter] = splitTextAtOffset(text, anchorOffset);

    // Construct the new block for text before the split
    const blockBefore = {
        ...blockNode,
        children: blockNode.children.slice(0, childIndex),
    };
    blockBefore.children.push({
        ...childNode,
        text: textBefore,
    });

    // Construct the new block for text after the split
    const blockAfter = {
        ...blockNode,
        children: [{
            ...childNode,
            text: textAfter,
        }],
    };
    blockAfter.children = blockAfter.children.concat(blockNode.children.slice(childIndex + 1) as any);

    // Replace the original block with the new blocks
    Transforms.removeNodes(editor, { at: blockPath });
    Transforms.insertNodes(editor, blockBefore, { at: blockPath });
    Transforms.insertNodes(editor, blockAfter, { at: Path.next(blockPath) });

    // Set the selection to the start of the new paragraph
    Transforms.select(editor, Editor.start(editor, Path.next(blockPath)));

    return true;
};
