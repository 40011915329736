import {Drawer, DrawerContent, DrawerOverlay} from "@chakra-ui/modal";
import {Button, Flex, Icon, Text, useToast} from "@chakra-ui/react";
import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {GrMore} from "react-icons/gr";
import 'react-circular-progressbar/dist/styles.css';
import {TranscriptionOnlyItems, TranslationOnlyItems} from "./checkout-items";
import {PodcastCartItem} from "../../models/Cart";
import {useLibrary} from "../../context/LibraryContext";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {apiStates, ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {PodcastTranscriptionConfig} from "../../models/Localization";
import {SplashLogo} from "../../assets/SplashIcon";
import {Publisher} from "../../models/Publisher";

export default function CartPanel({isOpen, onOpen, onClose}: {isOpen: boolean, onOpen: any, onClose: any}) {
    const auth: any = useAuth()
    const user: any = useUser()
    const library: any = useLibrary()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [transcriptionItems, setTranscriptionItems] = useState<PodcastTranscriptionConfig[]>([])
    const [translationItems, setTranslationItems] = useState<PodcastTranscriptionConfig[]>([])
    const [transcriptionPrice, setTranscriptionPrice] = useState<number>(0)
    const [translationPrice, setTranslationPrice] = useState<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false)
    const toast = useToast()

    const checkoutReq = useApi(ENDPOINTS.CART_CHECKOUT, user.token, false)
    const creditedCheckoutReq = useApi('/publisher/cart/creditedCheckout', user.token, false)

    useEffect(() => {
        if (!library.cartLoading && library.cartDownloaded && library.cart.length > 0) {
            updateCarts()
        }
    }, [library.cart])
    const updateCarts = () => {
        let transcription: PodcastTranscriptionConfig[] = []
        let translation: PodcastTranscriptionConfig[] = []
        library.cart.forEach((cartItem: any) => {
            if (cartItem.service.type === 'TRANSCRIPTION') {
                transcription.push(cartItem)
            } else {
                translation.push(cartItem)
            }
        })
        setTranscriptionItems(transcription)
        setTranslationItems(translation)
    }
    const handleCheckout = () => {
        if (translationItems.length > 0 || transcriptionItems.length > 0) {
            setCheckoutLoading(true)
            checkoutReq.setPayloadData({
                cart: library.cart,
                publisher: user.user
            })
        }
    }

    useEffect(() => {
        if (!checkoutReq.loading && checkoutReq.data) {
            console.log(`Payment link available: ${JSON.stringify(checkoutReq.data)}`)
            if (checkoutReq.data) {
                setCheckoutLoading(false)
                window.location.href = checkoutReq.data.url
            }
        } else if (!checkoutReq.loading && checkoutReq.error) {
            console.log(`Error checking out: ${JSON.stringify(checkoutReq.error)}`)
            setCheckoutLoading(false)
            toast({
                title: 'Error',
                description: 'Error checking out',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            auth.setOpenDrawer(false)
            onClose()
        }

    }, [checkoutReq.loading])

    return (
        <Drawer isOpen={isOpen} onClose={() => {
            setCheckoutLoading(false)
            auth.setOpenDrawer(false)
            onClose()
        }}
                size={'xl'}>
            <DrawerOverlay />
            <DrawerContent w={'full'} h={'100vh'}>
                <Flex h={'full'} px={"2%"} py={'2%'} flexDirection="column" alignItems="flex-start" justifyContent={'space-between'} flexShrink={0} boxShadow="-4px 0px 10px 0px rgba(0, 0, 0, 0.06)">
                    <Flex w="full" style={{overflow: 'auto'}} flexDirection="column" alignItems="flex-start" gap="36px">
                        <Flex w="full" flexDirection="row" alignItems="center" justifyContent={'space-between'}>
                            <ChevronLeftIcon boxSize={'36px'} onClick={onClose}/>
                            <Text color="gray.700" textAlign="center" isTruncated fontSize="lg" fontStyle="normal" fontWeight="medium" lineHeight="7">
                                Cart
                            </Text>
                            <Icon visibility={'hidden'} as={GrMore} />
                        </Flex>
                        {library.cart.length === 0 &&
                            <Flex w="full" mt='80px' flexDirection="column">
                                <Flex w="full" p="56px 48px" flexDirection="column" justifyContent="center" alignItems="center" gap="56px" borderRadius="12px" bg="#FFF" boxShadow="0px 8px 24px 0px rgba(0, 0, 0, 0.06)">
                                    <SplashLogo/>
                                    <Text alignSelf="stretch" color="gray.700" textAlign="center" fontSize="24px" fontWeight="500" lineHeight="40px">
                                        Your cart is empty. Add items to your cart to checkout
                                    </Text>
                                </Flex>
                            </Flex>
                        }
                        {transcriptionItems.length > 0 && library.cart.length > 0?
                            <Flex w={'full'} h={'full'} style={{overflow: 'auto'}} p="16px" flexDirection="column" alignItems="flex-start" gap="20px" borderRadius="12px" border="1px" borderColor="gray.300">
                                <Flex w="full"  alignItems={'center'} justifyContent={'space-between'}>
                                    <Text color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                                        TRANSCRIPTION
                                    </Text>
                                </Flex>
                                <TranscriptionOnlyItems cart={transcriptionItems} setTotalPrice={setTranscriptionPrice} setUpdateCart={library.setCart}/>
                            </Flex>
                            :
                            null
                        }
                        {translationItems.length > 0 && library.cart.length > 0?
                            <Flex w={'full'} style={{overflow: 'auto'}} p="16px" flexDirection="column" alignItems="flex-start" gap="20px" borderRadius="12px" border="1px" borderColor="gray.300">
                                <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
                                    <Text color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                                        TRANSLATION
                                    </Text>
                                </Flex>
                                <TranslationOnlyItems cart={translationItems} setTotalPrice={setTranslationPrice} setUpdateCart={library.setCart}/>
                            </Flex>
                            :
                            null
                        }
                    </Flex>
                    <Flex boxShadow={'sm'} direction={'column'} w={'full'} p={'16px'} gap={'24px'} alignItems={'flex-start'}>
                        <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            ORDER SUMMARY
                        </Text>
                        <Flex w={'full'} alignItems={'center'} justifyContent={'space-between'}>
                            <Text color="gray.800" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                                Total
                            </Text>
                            <Text color="gray.800" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                                {`$${translationPrice + transcriptionPrice}`}
                            </Text>
                        </Flex>
                        <Button isDisabled={checkoutLoading || library.cart.length === 0} onClick={() => {handleCheckout()}} alignItems={'center'} justifyContent={'center'} w={'full'} colorScheme={'teal'} type='submit' form='my-form'>
                            {checkoutLoading ? 'Loading...' : 'Checkout'}
                        </Button>
                    </Flex>
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}
