import {Icon} from "@chakra-ui/react";
import React from "react";

export const SplashLogo = (props: any) => {
    return (
        <Icon width="346px" height="332px" viewBox="0 0 346 332" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M311.466 152.314L309.055 119.901C309.055 119.901 323.871 115.29 340.673 119.267C341.481 119.458 343.11 119.753 342.77 121.86C341.827 127.708 339.66 148.837 339.411 152.53C339.411 152.53 340.893 170.894 336.631 175.107C333.089 178.609 324.132 179.268 314.63 179.428C300.26 179.666 284.644 178.087 284.644 178.087C284.644 178.087 292.781 156.486 299.109 153.507C305.438 150.527 311.466 152.314 311.466 152.314Z" fill="#E2E8F0"/>
                <path d="M334.143 122.357C337.314 123.473 338.477 125.826 338.673 131.063C338.851 135.708 337.485 147.166 337.315 151.822C336.635 170.349 332.744 173.283 330.325 174.378C327.059 175.858 320.469 176.719 317.233 175.22C314.591 173.998 314.778 170.912 314.916 168.316C315.098 164.935 315.986 161.613 316.699 158.317C317.684 153.769 318.03 149.201 318.732 144.615C319.769 137.832 321.815 130.682 325.714 125.038C326.074 124.518 326.104 123.953 326.668 123.473L334.143 122.357Z" fill="#4A5568"/>
                <path d="M307.838 94.685C307.655 97.4537 305.733 99.5867 303.547 99.4486C301.36 99.3104 299.735 96.9546 299.919 94.1859C300.102 91.4172 302.024 89.285 304.211 89.4223C306.397 89.5596 308.021 91.9163 307.838 94.685Z" fill="#E2E8F0"/>
                <path d="M315.347 78.382C316.72 77.8812 318.16 77.585 319.621 77.5029C321.973 77.379 323.572 78.9646 323.595 81.1233C324.595 80.9714 325.611 80.571 326.555 80.9714C327.719 81.4629 328.964 82.4947 329.458 83.5928C330.023 84.8516 330.321 86.6442 329.918 88.022C329.547 89.2888 328.496 90.2198 328.562 91.3499C328.625 92.4288 329.641 93.3967 329.762 94.5699C330.166 98.4796 327.255 99.1334 325.19 99.0678C324.368 101.306 323.151 101.714 320.876 102.237C319.76 102.494 318.887 102.895 317.885 103.443C317.066 103.89 316.11 104.641 315.17 104.789C315.09 102.401 315.769 100.179 315.988 97.8067C316.31 94.3501 316.056 90.9056 316.18 87.4458C316.277 84.7549 316.514 82.2813 316.072 79.6535L315.347 78.382Z" fill="#273238"/>
                <path d="M317.125 78.12C314.481 79.0792 310.932 76.233 309.995 75.1876C308.085 73.0535 303.779 73.9389 304.848 77.5906C301.895 75.2166 298.585 80.0564 300.582 82.7058C298.943 83.7638 298.524 86.0453 299.397 87.8821C300.696 90.6162 304.998 91.5777 307.759 92.1032C310.234 92.5738 315.262 92.9824 317.556 91.6609C318.953 90.8546 320.212 88.7378 320.768 87.3151C322.019 84.0995 320.492 80.4431 317.235 78.7333L317.125 78.12Z" fill="#273238"/>
                <path d="M289.087 174.792L285.771 175.976L280.947 184.784H265.575C265.575 184.784 272.508 166.174 274.919 163.499C278.771 159.226 302.768 149.685 302.768 149.685L304.125 144.631L326.28 145.077L330.088 151.02C330.088 151.02 330.686 167.068 324.657 174.199C321.33 178.135 306.571 175.983 306.571 175.983L307.59 184.786H290.895L289.087 174.792Z" fill="#2D3748"/>
                <path d="M283.056 150.762C283.056 150.762 293.067 128.854 295.028 121.717C297.149 113.998 303.807 109.328 311.39 107.211C313.219 106.701 317.632 107.211 317.632 107.211C317.632 107.211 328.409 109.114 334.745 115.806C335.718 116.834 330.723 154.926 330.723 154.926C330.723 154.926 332.533 161.169 331.929 162.658C331.326 164.147 329.215 165.335 329.215 165.335C329.215 165.335 320.466 160.577 314.432 159.092C308.398 157.607 285.169 157.903 284.264 157.011C283.358 156.118 283.056 150.762 283.056 150.762Z" fill="#9AEDE3"/>
                <path d="M303.894 86.1001C302.966 88.6724 302.42 94.7636 302.152 97.1164C301.883 99.4691 304.441 102.616 306.972 103.848C309.504 105.08 319.046 102.515 320.196 98.8247C321.346 95.1342 319.84 84.7474 315.682 82.8597C311.524 80.972 305.474 81.7171 303.894 86.1001Z" fill="#D9897A"/>
                <path d="M312.301 82.9915C314.536 82.8426 315.819 83.2457 316.845 85.1529C317.367 86.1216 317.288 88.4502 316.845 89.9954C316.306 91.8781 316.489 93.8764 318.261 95.0645C320.991 93.1264 322.568 91.2627 322.062 87.8957C321.796 86.1287 319.73 80.4577 317.291 80.9813L312.301 82.9915Z" fill="#273238"/>
                <path d="M310.394 102.131V108.868H319.93L319.601 98.2012L310.394 102.131Z" fill="#D9897A"/>
                <path d="M305.432 98.2101C306.526 98.419 308.899 98.4646 310.657 98.3117C312.227 98.1751 311.975 100.914 309.826 101.831C307.677 102.748 306.152 102.142 305.334 101.066C304.211 99.5883 304.705 98.071 305.432 98.2101Z" fill="#D9897A"/>
                <path d="M305.628 99.1901C305.628 99.1901 308.984 99.4356 310.715 99.1426C310.715 99.1426 310.378 101.637 307.929 101.653C307.22 101.658 305.749 101.16 305.628 99.1901Z" fill="white"/>
                <path d="M322.767 90.9866C322.767 90.9866 323.479 80.2015 317.48 77.865C317.08 77.7092 316.349 77.9148 316.118 78.142C316.118 78.142 319.93 79.8105 319.489 90.9248L322.767 90.9866Z" fill="#E2E8F0"/>
                <path d="M324.684 94.685C324.502 97.4537 322.58 99.5867 320.394 99.4486C318.207 99.3104 316.582 96.9546 316.766 94.1859C316.949 91.4172 318.871 89.285 321.057 89.4223C323.244 89.5596 324.868 91.9163 324.684 94.685Z" fill="#E2E8F0"/>
                <path d="M323.424 94.709C323.345 96.0075 322.498 97.0102 321.535 96.9428C320.572 96.8754 319.859 95.7733 319.939 94.4772C320.02 93.1812 320.865 92.176 321.828 92.2434C322.79 92.3108 323.504 93.4105 323.424 94.709Z" fill="#A0AEC0"/>
                <path d="M313.255 92.711C313.255 93.4908 312.828 94.1231 312.301 94.1231C311.774 94.1231 311.347 93.4916 311.347 92.711C311.347 91.9304 311.774 91.2988 312.301 91.2988C312.828 91.2988 313.255 91.9312 313.255 92.711Z" fill="#273238"/>
                <path d="M305.627 92.711C305.627 93.4908 305.129 94.1231 304.514 94.1231C303.9 94.1231 303.401 93.4916 303.401 92.711C303.401 91.9304 303.9 91.2988 304.514 91.2988C305.129 91.2988 305.627 91.9319 305.627 92.711Z" fill="#273238"/>
                <path d="M307.737 91.4858C307.838 92.2587 307.966 93.099 307.677 93.8478C307.368 94.644 306.61 95.2008 305.961 95.7045C305.971 95.6255 305.981 95.5466 305.992 95.4676C306.191 95.8191 306.503 96.0918 306.878 96.2405C307.109 96.3311 307.008 96.7093 306.774 96.6178C306.303 96.4388 305.908 96.1025 305.655 95.6654C305.634 95.6282 305.625 95.5847 305.63 95.5418C305.635 95.4989 305.655 95.4591 305.685 95.4285C306.286 94.9623 306.96 94.4695 307.292 93.7622C307.613 93.0807 307.441 92.1972 307.347 91.4858C307.315 91.2365 307.705 91.2365 307.737 91.4858V91.4858Z" fill="#A16559"/>
                <path d="M311.982 87.9032C310.656 88.1766 310.901 89.2872 311.616 89.2453C314.119 89.0983 314.178 90.8897 314.662 90.6498C315.272 90.3505 314.374 87.4104 311.982 87.9032Z" fill="#273238"/>
                <path d="M305.853 88.2603C305.568 87.6472 304.766 87.8038 304.232 88.1713C303.279 88.828 303.328 90.129 303.498 90.04C304.177 89.6939 304.487 89.4776 305.279 89.3878C306.208 89.2819 305.913 88.3911 305.853 88.2603Z" fill="#273238"/>
                <path d="M318.344 85.2016C316.832 83.6862 316.048 82.9354 313.796 82.9094C311.545 82.8834 309.459 83.7764 307.439 84.7433C306.055 85.4055 302.12 86.8332 302.472 83.7293C303.501 83.7577 304.468 82.2545 305.253 81.667C306.554 80.692 308.044 80.2451 309.656 80.0655C311.467 79.864 313.231 80.1646 314.819 81.0942C315.733 81.6288 317.113 82.2618 317.668 83.2531L318.344 85.2016Z" fill="#273238"/>
                <path d="M320.322 106.129C320.322 106.129 317.478 107.394 314.335 107.394C311.192 107.394 310.297 106.511 309.722 106.508C307.972 106.501 308.599 108.293 308.599 108.293C308.599 108.293 311.94 110.145 318.523 109.253C319.796 109.08 320.937 108.746 321.517 108.36C323.035 107.355 321.54 105.647 320.322 106.129Z" fill="white"/>
                <path d="M199.136 184.786H203.681L210.58 158.434H206.035L199.136 184.786Z" fill="#4A5568"/>
                <path d="M225.842 184.786H221.297L214.398 158.434H218.942L225.842 184.786Z" fill="#A0AEC0"/>
                <path d="M346 184.786H341.455L334.556 158.434H339.101L346 184.786Z" fill="#4A5568"/>
                <path d="M319.296 184.786H323.841L330.74 158.434H326.195L319.296 184.786Z" fill="#A0AEC0"/>
                <path d="M341.55 155.297H203.589V159.689H341.55V155.297Z" fill="#A0AEC0"/>
                <path d="M345.681 151.219H199.456V155.924H345.681V151.219Z" fill="#E2E8F0"/>
                <path d="M281.149 120.789H280.195V149.337H281.149V120.789Z" fill="#1E2248"/>
                <path d="M285.754 109.616L280.139 120.648L281.082 121.116L286.697 110.084L285.754 109.616Z" fill="#1E2248"/>
                <path d="M282.423 120.79C282.423 121.1 282.32 121.403 282.128 121.661C281.936 121.92 281.663 122.121 281.344 122.239C281.024 122.358 280.673 122.389 280.334 122.329C279.994 122.268 279.683 122.119 279.438 121.899C279.194 121.68 279.027 121.4 278.96 121.096C278.892 120.792 278.927 120.476 279.059 120.189C279.192 119.903 279.416 119.658 279.703 119.485C279.991 119.313 280.329 119.221 280.675 119.221C281.138 119.221 281.583 119.386 281.911 119.68C282.239 119.975 282.423 120.374 282.423 120.79Z" fill="#1E2248"/>
                <path d="M281.469 120.79C281.469 120.914 281.423 121.035 281.335 121.138C281.248 121.242 281.124 121.322 280.979 121.37C280.833 121.417 280.674 121.43 280.519 121.405C280.365 121.381 280.223 121.321 280.112 121.234C280.001 121.146 279.925 121.034 279.895 120.912C279.864 120.79 279.88 120.664 279.94 120.55C280 120.435 280.102 120.337 280.233 120.268C280.363 120.199 280.517 120.162 280.674 120.162C280.885 120.162 281.087 120.228 281.237 120.346C281.386 120.464 281.469 120.623 281.469 120.79V120.79Z" fill="#6F688F"/>
                <path d="M286.872 151.445C286.872 152.186 284.098 152.787 280.674 152.787C277.25 152.787 274.475 152.186 274.475 151.445C274.475 150.704 277.251 149.336 280.674 149.336C284.097 149.336 286.872 150.701 286.872 151.445Z" fill="#1E2248"/>
                <path d="M280.023 108.116L279.549 109.047L290.728 114.588L291.201 113.658L280.023 108.116Z" fill="#1E2248"/>
                <path d="M288.437 114.134C288.215 114.567 287.826 114.897 287.356 115.049C286.886 115.201 286.373 115.165 285.931 114.947L282.179 113.101C281.96 112.993 281.765 112.844 281.604 112.662C281.444 112.481 281.322 112.27 281.245 112.042C281.167 111.814 281.137 111.573 281.155 111.334C281.173 111.094 281.239 110.86 281.349 110.646L287.214 99.209C287.324 98.9944 287.477 98.8031 287.662 98.6461C287.848 98.4891 288.063 98.3694 288.296 98.2939C288.528 98.2184 288.774 98.1885 289.018 98.206C289.263 98.2235 289.502 98.288 289.721 98.3958L293.472 100.243C293.915 100.46 294.25 100.841 294.406 101.302C294.562 101.762 294.525 102.264 294.302 102.697L288.437 114.134Z" fill="#B5B9C9"/>
                <path d="M289.733 111.706L288.47 114.141C288.247 114.571 287.856 114.898 287.384 115.05C286.912 115.201 286.397 115.165 285.952 114.949L282.184 113.114C281.964 113.007 281.767 112.859 281.606 112.679C281.445 112.498 281.322 112.289 281.245 112.062C281.168 111.836 281.137 111.596 281.155 111.358C281.173 111.121 281.239 110.888 281.35 110.675L282.612 108.24L289.733 111.706Z" fill="#1E2248"/>
                <path d="M289.97 102.548C290.02 102.573 290.074 102.588 290.13 102.592C290.186 102.596 290.242 102.589 290.295 102.572C290.348 102.554 290.397 102.526 290.44 102.489C290.482 102.453 290.517 102.408 290.542 102.358L291.96 99.5261L291.197 99.1426L289.779 101.973C289.754 102.024 289.739 102.078 289.735 102.134C289.731 102.19 289.738 102.247 289.756 102.3C289.773 102.354 289.801 102.403 289.838 102.445C289.875 102.488 289.919 102.523 289.97 102.548V102.548Z" fill="#778BBC"/>
                <path d="M289.78 105.353C289.754 105.407 289.739 105.465 289.735 105.524C289.731 105.583 289.738 105.643 289.756 105.7C289.773 105.756 289.801 105.809 289.838 105.854C289.875 105.899 289.92 105.936 289.97 105.963L291.889 106.984L292.278 106.173L290.357 105.152C290.307 105.124 290.252 105.108 290.196 105.103C290.139 105.098 290.082 105.106 290.029 105.124C289.975 105.143 289.925 105.173 289.882 105.212C289.84 105.252 289.805 105.3 289.78 105.353V105.353Z" fill="#778BBC"/>
                <path d="M293.413 100.395L292.788 100.084L291.366 102.919C291.317 103.02 291.309 103.137 291.345 103.244C291.381 103.351 291.458 103.439 291.559 103.489C291.659 103.54 291.776 103.549 291.883 103.514C291.99 103.479 292.079 103.403 292.131 103.303L293.549 100.475C293.504 100.445 293.459 100.416 293.413 100.395Z" fill="#778BBC"/>
                <path d="M289.142 106.922C289.117 106.975 289.101 107.033 289.097 107.093C289.093 107.152 289.1 107.212 289.118 107.268C289.136 107.325 289.164 107.377 289.201 107.423C289.237 107.468 289.282 107.505 289.333 107.531L291.255 108.555L291.64 107.743L289.717 106.721C289.667 106.694 289.612 106.678 289.556 106.673C289.5 106.669 289.443 106.676 289.39 106.695C289.337 106.713 289.287 106.743 289.245 106.782C289.202 106.821 289.167 106.868 289.142 106.922V106.922Z" fill="#778BBC"/>
                <path d="M288.38 101.919C288.43 101.944 288.485 101.959 288.541 101.963C288.597 101.968 288.653 101.961 288.706 101.943C288.759 101.925 288.808 101.897 288.851 101.86C288.893 101.823 288.928 101.778 288.953 101.728L290.369 98.8883L289.75 98.5767C289.703 98.5534 289.655 98.5331 289.607 98.5137L288.188 101.343C288.163 101.394 288.148 101.449 288.144 101.505C288.14 101.561 288.147 101.618 288.165 101.671C288.183 101.725 288.211 101.774 288.248 101.817C288.285 101.859 288.33 101.894 288.38 101.919Z" fill="#778BBC"/>
                <path d="M290.687 109.311L288.764 108.289C288.714 108.262 288.659 108.246 288.603 108.241C288.547 108.237 288.491 108.245 288.437 108.263C288.384 108.282 288.334 108.312 288.292 108.351C288.249 108.39 288.214 108.437 288.189 108.491C288.163 108.544 288.148 108.602 288.144 108.661C288.14 108.721 288.147 108.781 288.165 108.837C288.182 108.894 288.211 108.946 288.247 108.991C288.284 109.037 288.329 109.074 288.379 109.1L290.302 110.123L290.687 109.311Z" fill="#778BBC"/>
                <path d="M285.872 107.366C285.923 107.258 285.931 107.134 285.896 107.019C285.86 106.905 285.783 106.81 285.682 106.756L283.759 105.73L283.374 106.542L285.297 107.564C285.398 107.619 285.515 107.628 285.623 107.591C285.731 107.554 285.821 107.473 285.872 107.366V107.366Z" fill="#778BBC"/>
                <path d="M284.963 103.404L287.123 104.426C287.179 104.454 287.241 104.47 287.304 104.475C287.368 104.48 287.432 104.473 287.492 104.454C287.553 104.436 287.609 104.406 287.657 104.367C287.705 104.328 287.745 104.28 287.773 104.226C287.802 104.173 287.819 104.114 287.823 104.055C287.828 103.995 287.819 103.935 287.799 103.878C287.779 103.821 287.746 103.769 287.704 103.723C287.662 103.678 287.611 103.642 287.554 103.615L285.396 102.594L284.963 103.404Z" fill="#778BBC"/>
                <path d="M286.83 105.793C286.881 105.686 286.889 105.561 286.854 105.447C286.818 105.333 286.741 105.239 286.639 105.185L284.72 104.162L284.332 104.973L286.252 105.995C286.302 106.022 286.357 106.039 286.414 106.044C286.47 106.048 286.527 106.041 286.581 106.022C286.635 106.004 286.684 105.974 286.727 105.934C286.77 105.895 286.805 105.847 286.83 105.793V105.793Z" fill="#778BBC"/>
                <path d="M285.462 117.651C286.676 117.649 287.866 117.314 288.899 116.683C289.932 116.052 290.768 115.15 291.315 114.077L291.959 112.8L291.147 112.396L290.503 113.672C290.171 114.327 289.712 114.91 289.152 115.389C288.592 115.868 287.942 116.233 287.24 116.464C286.538 116.694 285.796 116.785 285.058 116.732C284.321 116.679 283.6 116.482 282.939 116.154C282.278 115.825 281.689 115.37 281.205 114.816C280.721 114.262 280.352 113.619 280.12 112.923C279.887 112.228 279.795 111.494 279.849 110.763C279.902 110.033 280.101 109.32 280.433 108.665L281.077 107.389L280.265 106.984L279.62 108.261C279.12 109.248 278.884 110.346 278.933 111.449C278.981 112.553 279.314 113.625 279.899 114.566C280.484 115.506 281.302 116.283 282.275 116.823C283.249 117.363 284.345 117.647 285.461 117.65L285.462 117.651Z" fill="#1E2248"/>
                <path d="M311.54 152.16H290.806C290.387 152.16 290.047 152.498 290.047 152.915V153.602C290.047 154.019 290.387 154.357 290.806 154.357H311.54C311.959 154.357 312.299 154.019 312.299 153.602V152.915C312.299 152.498 311.959 152.16 311.54 152.16Z" fill="#4A5568"/>
                <path d="M298.62 153.591C298.639 153.686 298.636 153.783 298.612 153.877C298.587 153.97 298.541 154.057 298.478 154.131C298.414 154.204 298.335 154.263 298.245 154.301C298.155 154.34 298.058 154.359 297.96 154.355H268.423C268.205 154.349 267.996 154.269 267.831 154.13C267.665 153.992 267.552 153.801 267.511 153.591L263.678 131.905C263.659 131.811 263.662 131.713 263.686 131.619C263.711 131.526 263.757 131.439 263.82 131.366C263.884 131.292 263.963 131.234 264.053 131.195C264.143 131.156 264.24 131.138 264.338 131.141H293.875C294.092 131.148 294.3 131.228 294.466 131.367C294.631 131.505 294.744 131.695 294.787 131.905L298.62 153.591Z" fill="#4A5568"/>
                <path d="M283.012 143.219C283.302 144.865 282.205 146.199 280.564 146.199C278.922 146.199 277.355 144.865 277.065 143.219C276.776 141.574 277.872 140.238 279.513 140.238C281.155 140.238 282.723 141.573 283.012 143.219Z" fill="#A0AEC0"/>
                <path d="M279.588 140.238C277.897 140.238 276.769 141.53 277.067 143.123C277.206 143.814 277.564 144.449 278.094 144.944C279.438 143.923 280.887 142.799 282.423 141.578C281.728 140.774 280.677 140.238 279.588 140.238Z" fill="#A0AEC0"/>
                <path d="M319.291 146.826L312.978 148.177C312.978 148.177 311.195 147.852 309.642 147.539C308.09 147.225 303.615 148.417 303.08 148.705C302.545 148.994 299.804 150.29 299.296 151.483C298.789 152.676 304.89 152.392 304.89 152.392C304.89 152.392 302.972 152.272 303.359 152.985C303.746 153.699 306.098 153.354 306.909 153.145C307.719 152.936 310.876 152.681 313.433 151.931C315.026 151.463 322.154 152.081 322.154 152.081C322.154 152.081 321.145 148.133 319.291 146.826Z" fill="#D9897A"/>
                <path d="M303.107 152.979C302.81 151.712 305.368 151.375 306.331 151.222C306.583 151.183 306.687 151.509 306.436 151.547C305.747 151.656 305.051 151.786 304.403 152.021C303.997 152.165 303.383 152.438 303.489 152.89C303.539 153.104 303.157 153.192 303.107 152.979V152.979Z" fill="#A16559"/>
                <path d="M303.537 152.728C302.142 152.882 300.542 152.774 299.35 151.909C299.153 151.765 299.34 151.421 299.539 151.566C300.681 152.396 302.201 152.479 303.537 152.332C303.776 152.306 303.776 152.702 303.537 152.728Z" fill="#A16559"/>
                <path d="M334.384 115.455C334.384 115.455 337.264 118.131 338.477 121.998C339.689 125.865 344.096 139.28 344.399 141.511C344.702 143.741 346.824 148.055 344.854 149.988C342.883 151.921 336.062 152.07 332.878 152.517C329.694 152.965 320.6 152.815 320.149 152.369C319.698 151.923 320.755 149.246 318.027 146.866C318.027 146.866 324.755 144.704 328.805 142.591C329.567 142.195 327.758 140.14 328.483 139.903C329.208 139.666 331.494 141.268 332.028 141.168C332.028 141.168 327.262 134.146 327.999 126.624C328.547 120.999 331.531 115.582 334.384 115.455Z" fill="#4FD1C5"/>
                <path d="M288.999 175.093C287.91 175.578 286.839 176.101 285.767 176.629C285.665 176.68 285.514 176.819 285.607 176.677C285.581 176.719 285.554 176.757 285.527 176.801C285.367 177.052 285.21 177.303 285.056 177.556C284.511 178.438 283.978 179.326 283.463 180.226C282.993 181.049 282.494 181.89 282.163 182.777C282.083 183.002 281.716 182.905 281.8 182.678C282.133 181.784 282.622 180.943 283.092 180.115C283.618 179.187 284.17 178.272 284.731 177.364C284.936 177.032 285.126 176.526 285.491 176.346C286.589 175.802 287.691 175.264 288.811 174.766C289.03 174.668 289.222 174.992 289.001 175.09L288.999 175.093Z" fill="#5C4753"/>
                <path d="M296.47 94.4272C294.692 93.9324 293.122 92.9775 291.432 92.2838C291.187 92.1831 291.398 91.8441 291.641 91.944C293.298 92.6244 294.838 93.5627 296.58 94.0481C296.837 94.1196 296.728 94.4995 296.47 94.4272Z" fill="#1E2248"/>
                <path d="M296.534 96.0541C294.882 96.1872 293.146 96.4665 291.566 96.9369C291.322 97.0092 291.218 96.6597 291.46 96.5881C293.075 96.1076 294.845 95.8283 296.536 95.6923C296.791 95.6713 296.789 96.0338 296.536 96.0541H296.534Z" fill="#1E2248"/>
                <path d="M296.363 92.176C295.626 91.3903 295.059 90.4795 294.247 89.747C294.048 89.5688 294.36 89.2958 294.558 89.474C295.371 90.2058 295.937 91.1173 296.674 91.9031C296.851 92.0935 296.543 92.3657 296.363 92.176Z" fill="#1E2248"/>
                <path d="M280.621 83.393L268.064 73.0966H243.995C243.133 73.0966 242.305 72.753 241.695 72.1414C241.085 71.5298 240.743 70.7003 240.743 69.8354V36.2872C240.744 35.4232 241.087 34.595 241.697 33.9846C242.307 33.3741 243.134 33.0312 243.995 33.0313H288.639C289.501 33.0312 290.327 33.3741 290.937 33.9846C291.547 34.595 291.89 35.4232 291.891 36.2872V69.8384C291.891 70.7033 291.549 71.5329 290.939 72.1445C290.329 72.7561 289.502 73.0996 288.639 73.0996H280.621V83.393Z" fill="#4FD1C5"/>
                <path d="M281.514 41.3133V45.1054H276.721C276.538 47.8448 275.398 52.7898 270.266 58.0889C272.58 59.9822 275.156 61.5272 277.914 62.6751L276.516 66.0599C273.265 64.7109 270.238 62.8731 267.539 60.6099C264.716 62.9519 261.046 65.3053 256.268 67.5169L254.736 64.1919C259.022 62.2087 262.311 60.1328 264.837 58.095C260.685 53.8008 258.915 49.8069 258.796 49.5331L262.147 48.0731C262.173 48.1315 263.791 51.7378 267.57 55.621C271.888 51.1956 272.877 47.2168 273.064 45.1092H252.961V41.317H265.345V37.8715H269.127V41.317L281.514 41.3133Z" fill="white"/>
                <path d="M37.777 227.363L40.192 195.13C40.192 195.13 25.3489 190.546 8.51692 194.501C7.70737 194.691 6.07543 194.984 6.41562 197.079C7.36076 202.895 9.53106 223.906 9.78058 227.577C9.78058 227.577 8.29708 245.843 12.5663 250.03C16.1141 253.511 25.0874 254.167 34.6062 254.325C49.0039 254.565 64.6477 252.992 64.6477 252.992C64.6477 252.992 56.4952 231.512 50.1553 228.55C43.8153 225.587 37.777 227.363 37.777 227.363Z" fill="#E2E8F0"/>
                <path d="M15.0618 197.283C11.8842 198.399 10.7199 200.75 10.5221 205.984C10.3452 210.626 11.7129 222.079 11.8842 226.732C12.5652 245.251 16.4633 248.182 18.8859 249.278C22.1576 250.757 28.7597 251.618 32.0024 250.12C34.6486 248.898 34.4612 245.813 34.3221 243.219C34.1412 239.839 33.2511 236.519 32.5363 233.225C31.5506 228.679 31.2032 224.114 30.5005 219.529C29.4608 212.75 27.4105 205.604 23.5052 199.963C23.145 199.443 23.1152 198.878 22.5508 198.399L15.0618 197.283Z" fill="#4A5568"/>
                <path d="M18.5838 233.406L19.1487 223.755L41.2789 223.309L42.6335 228.377C42.6335 228.377 58.6368 233.586 60.1339 235.863C60.7509 236.799 71.6507 259.995 71.6507 259.995H53.8174L43.5753 250.72C43.5753 250.72 22.359 252.73 19.0359 248.783C13.0179 241.634 18.5838 233.406 18.5838 233.406Z" fill="#1E2248"/>
                <path d="M45.4943 174.785C45.4501 176.175 45.4734 177.494 45.3337 178.869C45.0927 181.181 44.0191 184.077 45.4188 186.1C46.1504 187.158 48.8519 187.745 48.3403 189.571C47.836 191.373 45.0422 191.652 43.7484 190.626C43.5629 193.423 39.6464 194.189 37.3239 193.693C28.7111 191.858 31.1219 181.99 33.083 176.315C33.9607 173.772 34.8947 171.261 35.2697 168.578C35.4986 166.937 35.3653 164.413 36.6108 163.069C39.4448 160.012 42.4972 165.515 43.5974 167.576C43.5926 167.578 45.622 170.743 45.4943 174.785Z" fill="#4C2D33"/>
                <path d="M61.7798 194.993C61.7798 194.993 62.0424 193.103 62.1294 192.244C62.2164 191.385 62.7408 190.611 64.1386 190.183C65.5363 189.755 67.3714 189.581 67.9828 189.238C68.5943 188.895 69.6424 187.863 70.1668 188.292C70.6912 188.722 70.0798 189.323 69.2935 189.839C68.5073 190.356 69.0309 190.269 70.0798 190.183C71.1287 190.098 73.3988 189.753 74.6217 189.153C75.8446 188.553 75.5828 189.841 74.8843 190.442C74.4137 190.898 73.9181 191.329 73.3996 191.732C72.6126 192.333 72.0011 192.935 71.4775 193.02C70.9539 193.106 66.7599 192.935 65.7111 193.536C64.6622 194.138 64.9248 196.028 64.9248 196.028L61.7798 194.993Z" fill="#FEA18F"/>
                <path d="M27.7516 189.083C27.7516 189.083 20.847 190.737 20.0354 191.736C19.2238 192.736 18.7896 223.935 18.7896 223.935L41.5503 223.638C41.5503 223.638 42.9925 204.635 42.9925 201.634C42.9925 198.632 41.8948 193.983 41.8948 193.983C41.8948 193.983 40.3125 191.427 38.4353 190.988C36.2641 190.481 34.3213 189.409 33.4504 189.083C31.824 188.483 27.7516 189.083 27.7516 189.083Z" fill="#514DFF"/>
                <path d="M35.4798 195.757C32.6368 197.981 25.7016 193.351 23.5649 190.242C25.6426 189.591 27.856 189.054 27.856 189.054C27.856 189.054 31.4586 188.093 33.0966 188.706C33.5855 188.889 35.5792 189.681 36.7566 189.981C37.3756 191.264 37.8451 193.907 35.4798 195.757Z" fill="#FEA18F"/>
                <path d="M40.4332 169.844C41.2759 172.235 41.7719 177.9 42.0158 180.09C42.2598 182.281 39.9365 185.204 37.6362 186.35C35.336 187.496 29.0844 184.872 28.0399 181.44C26.9954 178.009 25.947 168.589 29.7246 166.834C33.5022 165.078 38.9972 165.767 40.4332 169.844Z" fill="#FEA18F"/>
                <path d="M34.3937 184.631V190.698H29.2983V181.918L34.3937 184.631Z" fill="#FEA18F"/>
                <path d="M60.3858 195.692C60.3858 195.692 60.3858 194.141 61.256 194.141C62.1263 194.141 65.2569 195.692 65.2569 196.207C65.2569 196.722 64.7347 197.931 64.7347 197.931C64.7347 197.931 66.6597 201.833 65.6122 205.453C64.5647 209.072 61.7774 213.271 60.2117 215.856C58.646 218.441 55.1313 222.21 52.3472 221.693C49.5631 221.175 47.74 219.019 46.7711 217.591C45.9585 216.393 44.376 215.055 42.4622 212.986C40.5484 210.918 38.8143 206.721 38.8143 206.721L37.8999 190.385C37.8999 190.385 42.5472 191.557 44.3215 194.905C45.2639 196.685 49.0971 201.558 50.4888 203.109C51.8804 204.66 52.036 206.721 52.036 206.721C52.036 206.721 52.3271 205.364 53.0234 205.537C53.7196 205.709 53.6017 206.548 53.6017 206.548C53.6017 206.548 54.938 202.611 55.3406 200.517C56.0368 196.898 60.3858 195.692 60.3858 195.692Z" fill="#C2BEED"/>
                <path d="M36.3042 189.756C36.3042 189.756 36.7119 190.477 37.0993 196.21C37.4867 201.943 36.9427 211.2 36.9427 216.065C36.9427 218.495 36.8775 220.141 36.8783 221.181C36.8783 222.652 38.1065 222.652 38.1065 222.652H42.6333C42.6333 222.652 43.4765 222.948 43.5572 221.609C43.6738 219.678 43.7456 215.601 43.6486 214.193C43.5197 212.324 45.7305 196.412 42.4889 193.162C39.7464 190.412 36.3042 189.756 36.3042 189.756Z" fill="#C2BEED"/>
                <path d="M26.7423 189.443C26.7423 189.443 29.1849 193.556 29.3095 198.283C29.434 203.01 30.7172 221.379 29.2036 222.323C27.3033 223.504 17.3464 222.767 17.3464 222.767C17.3464 222.767 15.7994 212.338 15.9313 209.727C16.0632 207.115 18.895 192.304 19.5382 191.807C20.1815 191.309 23.483 189.886 26.7423 189.443Z" fill="#C2BEED"/>
                <path d="M13.2485 215.169C14.0366 213.262 15.5807 210.663 18.9383 209.123C22.2959 207.583 25.8915 207.714 27.1131 207.884C28.3347 208.054 29.8908 208.318 29.8908 208.318C29.8908 208.318 31.595 207.466 32.2657 207.657C32.9364 207.848 34.2899 210.639 34.0478 211.461C33.9505 211.79 31.911 212.547 31.911 212.547C31.911 212.547 30.2937 215.563 28.3725 217.155C26.4512 218.747 21.6822 221.668 18.3801 220.549C15.0781 219.43 12.2931 217.482 13.2485 215.169Z" fill="#C2BEED"/>
                <path d="M20.2121 191.347C21.1965 191.202 22.2819 191.768 23.2357 194.208C24.7327 198.04 23.2357 206.489 22.7135 209.082C22.1912 211.675 14.1874 218.94 13.3178 217.038C12.4482 215.136 11.3924 208.824 11.9259 206.142C13.1926 199.734 17.929 191.685 20.2121 191.347Z" fill="#C2BEED"/>
                <path d="M32.1107 165.364C32.1244 165.933 31.7708 169.368 27.8005 173.903C26.1859 175.748 27.0182 178.83 28.1993 181C29.3893 183.186 31.9194 185.449 32.3658 187.884C32.7541 190.001 32.2108 192.231 30.2361 193.519C28.5198 194.638 26.8826 194.581 25.9187 192.83C25.339 194.291 24.6754 195.269 22.9268 195.391C21.07 195.521 20.1908 194.223 20.3499 192.41C17.5889 193.478 16.21 189.559 16.6363 187.384C16.9866 185.589 18.5052 184.42 19.1898 182.748C20.1262 180.465 18.6247 177.76 18.3566 175.522C17.827 171.114 20.0383 160.86 27.3508 162.694C28.8613 163.074 32.076 163.935 32.1107 165.364Z" fill="#4C2D33"/>
                <path d="M28.1071 163.419C28.4302 162.085 30.5231 160.365 31.9456 159.762C34.7317 158.578 37.3141 160.089 39.3942 161.893C41.1713 163.437 50.9624 168.819 45.4881 170.619C41.6107 171.895 37.7487 169.211 35.9918 165.97C36.1162 167.724 34.1048 169.151 32.4222 168.633C29.8333 167.837 27.5634 165.691 28.1071 163.419Z" fill="#4C2D33"/>
                <path d="M24.2519 174.079C24.2519 174.079 23.3392 162.678 29.6194 161.537C29.6194 161.537 26.6673 164.252 27.0426 174.025L24.2519 174.079Z" fill="#4A5568"/>
                <path d="M21.9852 177.763C22.1541 180.358 23.925 182.353 25.9403 182.227C27.9555 182.102 29.4536 179.892 29.2847 177.296C29.1158 174.7 27.3442 172.706 25.3289 172.832C23.3136 172.957 21.8229 175.169 21.9852 177.763Z" fill="#E2E8F0"/>
                <path d="M23.5706 177.786C23.6439 178.911 24.414 179.776 25.2904 179.72C26.1669 179.664 26.8172 178.707 26.7439 177.584C26.6706 176.46 25.9005 175.594 25.024 175.649C24.1475 175.704 23.4973 176.662 23.5706 177.786Z" fill="#A0AEC0"/>
                <path d="M31.5239 175.647C31.5239 176.341 31.9516 176.902 32.4801 176.902C33.0085 176.902 33.4354 176.341 33.4354 175.647C33.4354 174.954 33.0077 174.393 32.4801 174.393C31.9524 174.393 31.5239 174.955 31.5239 175.647Z" fill="#4C2D33"/>
                <path d="M38.5327 175.334C38.5327 176.027 38.9612 176.589 39.4889 176.589C40.0165 176.589 40.4442 176.027 40.4442 175.334C40.4442 174.641 40.0165 174.08 39.4889 174.08C38.9612 174.08 38.5327 174.642 38.5327 175.334Z" fill="#4C2D33"/>
                <path d="M37.161 174.884C37.052 175.616 36.968 176.368 37.1788 177.092C37.4093 177.886 38.0811 178.481 38.793 178.97C38.8258 178.999 38.8468 179.037 38.8528 179.077C38.8587 179.118 38.8494 179.159 38.8261 179.194C38.5734 179.575 38.1803 179.867 37.7121 180.024C37.4593 180.111 37.3548 179.753 37.6005 179.668C37.9645 179.54 38.2684 179.307 38.4625 179.008C38.4741 179.087 38.4848 179.157 38.4956 179.232C37.7639 178.729 37.1431 178.138 36.8313 177.357C36.5196 176.576 36.6214 175.692 36.742 174.884C36.7769 174.648 37.1976 174.648 37.1628 174.884H37.161Z" fill="#DD7980"/>
                <path d="M38.5353 181.499C38.5353 181.499 34.9165 181.63 33.4697 180.977C33.4697 180.977 32.9513 184.104 36.2434 184.113C36.9503 184.115 38.4147 183.59 38.5353 181.499Z" fill="white"/>
                <path d="M32.8885 171.62C34.1065 171.863 33.8817 172.849 33.225 172.812C30.9262 172.681 30.8747 174.273 30.4273 174.06C29.8675 173.794 30.6912 171.182 32.8885 171.62Z" fill="#4C2D33"/>
                <path d="M38.3036 171.924C38.5892 171.399 39.3937 171.533 39.9278 171.848C40.8831 172.41 40.8342 173.524 40.6632 173.449C39.9834 173.152 39.6725 172.968 38.879 172.89C37.9514 172.803 38.2429 172.036 38.3036 171.924Z" fill="#4C2D33"/>
                <path d="M32.1665 208.717L35.0084 207.467C35.0084 207.467 35.8935 205.784 36.4286 205.292C36.9636 204.8 39.9956 204.247 40.6671 204.183C41.3386 204.119 41.2143 204.987 40.3528 205.255C39.4913 205.522 38.3229 205.963 38.3229 205.963C38.3229 205.963 40.4235 205.92 41.2306 206.281C42.0377 206.642 43.1209 206.953 42.982 207.429C42.8432 207.906 42.2683 209.42 41.3873 210.201C40.5063 210.981 38.4804 209.883 38.4804 209.883C38.4804 209.883 36.8735 211.359 36.0818 211.391C35.2902 211.422 33.3138 210.596 33.3138 210.596L32.1665 208.717Z" fill="#FEA18F"/>
                <path d="M0.000488281 259.994H4.55324L11.465 233.342H6.91147L0.000488281 259.994Z" fill="#4A5568"/>
                <path d="M26.754 259.994H22.3277L15.6079 233.342H20.0342L26.754 259.994Z" fill="#A0AEC0"/>
                <path d="M147.447 259.994H142.894L135.983 233.342H140.536L147.447 259.994Z" fill="#4A5568"/>
                <path d="M120.698 259.994H125.124L131.844 233.342H127.417L120.698 259.994Z" fill="#A0AEC0"/>
                <path d="M142.992 230.52H4.4624V234.596H142.992V230.52Z" fill="#A0AEC0"/>
                <path d="M147.132 226.129H0.321777V230.832H147.132V226.129Z" fill="#E2E8F0"/>
                <path d="M57.3221 216.096H56.3657V225.189H57.3221V216.096Z" fill="#1E2248"/>
                <path d="M51.8514 204.922L50.9067 205.389L56.5319 216.415L57.4765 215.948L51.8514 204.922Z" fill="#1E2248"/>
                <path d="M55.0923 216.095C55.0921 216.405 55.1947 216.708 55.3871 216.966C55.5794 217.224 55.8529 217.425 56.1729 217.544C56.493 217.663 56.8452 217.694 57.185 217.634C57.5248 217.573 57.837 217.424 58.082 217.205C58.3271 216.986 58.494 216.706 58.5616 216.402C58.6293 216.098 58.5946 215.782 58.4621 215.496C58.3295 215.209 58.1051 214.964 57.817 214.792C57.5289 214.619 57.1903 214.527 56.8438 214.527C56.3794 214.527 55.934 214.692 55.6056 214.986C55.2771 215.28 55.0925 215.679 55.0923 216.095V216.095Z" fill="#1E2248"/>
                <path d="M56.0503 216.095C56.0501 216.219 56.0967 216.341 56.1841 216.444C56.2715 216.547 56.3958 216.628 56.5414 216.675C56.6869 216.723 56.847 216.736 57.0016 216.711C57.1561 216.687 57.2981 216.628 57.4096 216.54C57.5211 216.452 57.597 216.34 57.6278 216.219C57.6586 216.097 57.6429 215.971 57.5826 215.856C57.5224 215.741 57.4203 215.643 57.2893 215.575C57.1583 215.506 57.0043 215.469 56.8467 215.469C56.6356 215.469 56.4332 215.535 56.2839 215.652C56.1345 215.77 56.0505 215.929 56.0503 216.095V216.095Z" fill="#6F688F"/>
                <path d="M50.6343 227.736C50.6343 228.473 53.4148 228.308 56.8442 228.308C60.2736 228.308 63.0542 228.471 63.0542 227.736C63.0542 227.001 60.2744 224.875 56.8442 224.875C53.414 224.875 50.6343 226.999 50.6343 227.736Z" fill="#1E2248"/>
                <path d="M57.5899 203.419L46.3911 208.957L46.8656 209.887L58.0643 204.349L57.5899 203.419Z" fill="#1E2248"/>
                <path d="M49.2417 209.15C49.3493 209.36 49.498 209.548 49.6793 209.702C49.8607 209.856 50.0711 209.973 50.2986 210.047C50.5262 210.121 50.7664 210.151 51.0054 210.133C51.2445 210.116 51.4778 210.053 51.692 209.947L55.361 208.135C55.5753 208.03 55.7663 207.884 55.9232 207.705C56.08 207.527 56.1996 207.32 56.2751 207.097C56.3506 206.873 56.3805 206.637 56.3631 206.402C56.3457 206.167 56.2813 205.938 56.1737 205.727L50.4373 194.508C50.3297 194.298 50.181 194.11 49.9995 193.956C49.8181 193.802 49.6076 193.685 49.38 193.611C49.1524 193.536 48.9122 193.507 48.673 193.524C48.4339 193.541 48.2005 193.605 47.9863 193.71L44.3173 195.521C44.103 195.627 43.9121 195.773 43.7553 195.952C43.5985 196.13 43.4791 196.337 43.4037 196.56C43.3283 196.784 43.2984 197.02 43.3159 197.255C43.3333 197.49 43.3977 197.719 43.5053 197.93L49.2417 209.15Z" fill="#B5B9C9"/>
                <path d="M48.0874 206.859L49.3054 209.182C49.412 209.386 49.5594 209.567 49.7391 209.716C49.9188 209.864 50.1273 209.978 50.3528 210.05C50.5783 210.121 50.8163 210.149 51.0532 210.133C51.2902 210.116 51.5213 210.055 51.7336 209.953L55.3695 208.202C55.5819 208.1 55.7712 207.959 55.9267 207.787C56.0821 207.615 56.2006 207.415 56.2754 207.199C56.3502 206.983 56.3798 206.754 56.3626 206.527C56.3453 206.3 56.2816 206.079 56.175 205.875L54.9561 203.553L48.0874 206.859Z" fill="#1E2248"/>
                <path d="M47.8519 197.865C47.7507 197.916 47.6335 197.924 47.5261 197.888C47.4187 197.853 47.3297 197.776 47.2785 197.675L45.8589 194.848L46.6222 194.461L48.0418 197.288C48.0676 197.338 48.0832 197.393 48.0876 197.449C48.092 197.506 48.0852 197.562 48.0675 197.616C48.0499 197.67 48.0217 197.719 47.9847 197.762C47.9477 197.805 47.9026 197.84 47.8519 197.865V197.865Z" fill="#778BBC"/>
                <path d="M47.723 200.668C47.7484 200.721 47.7635 200.779 47.7677 200.839C47.7718 200.898 47.7648 200.958 47.747 201.015C47.7293 201.071 47.7012 201.124 47.6643 201.169C47.6274 201.214 47.5825 201.251 47.532 201.278L45.6053 202.3L45.2202 201.489L47.1462 200.466C47.1966 200.44 47.2515 200.424 47.3078 200.419C47.3641 200.415 47.4206 200.422 47.4742 200.441C47.5277 200.46 47.5773 200.489 47.62 200.528C47.6627 200.567 47.6977 200.615 47.723 200.668V200.668Z" fill="#778BBC"/>
                <path d="M44.105 195.425L44.8126 195.088L46.4331 198.161C46.465 198.216 46.4849 198.276 46.4918 198.338C46.4986 198.4 46.4921 198.463 46.4727 198.523C46.4534 198.583 46.4215 198.638 46.379 198.686C46.3366 198.734 46.2844 198.773 46.2257 198.801C46.167 198.829 46.1029 198.846 46.0373 198.85C45.9717 198.854 45.906 198.845 45.844 198.824C45.7821 198.803 45.7252 198.77 45.6769 198.728C45.6286 198.685 45.5897 198.634 45.5627 198.577L43.9458 195.513C43.9998 195.48 44.0511 195.448 44.105 195.425Z" fill="#778BBC"/>
                <path d="M48.6737 202.238C48.7021 202.291 48.7191 202.349 48.7237 202.408C48.7284 202.468 48.7205 202.527 48.7006 202.584C48.6807 202.64 48.6491 202.693 48.6077 202.738C48.5663 202.783 48.5159 202.819 48.4593 202.846L46.2964 203.868L45.8589 203.058L48.0218 202.036C48.0786 202.009 48.1406 201.992 48.2043 201.988C48.2679 201.983 48.3319 201.99 48.3925 202.009C48.4532 202.028 48.5093 202.058 48.5575 202.097C48.6058 202.136 48.6452 202.184 48.6737 202.238V202.238Z" fill="#778BBC"/>
                <path d="M49.4474 197.233C49.346 197.288 49.2286 197.297 49.121 197.258C49.0134 197.219 48.9243 197.136 48.8732 197.025L47.4497 193.929L48.0713 193.589C48.1179 193.563 48.1661 193.541 48.2143 193.52L49.6347 196.608C49.685 196.718 49.6935 196.846 49.6585 196.963C49.6234 197.08 49.5476 197.177 49.4474 197.233V197.233Z" fill="#778BBC"/>
                <path d="M46.8169 204.625L48.7404 203.603C48.7908 203.576 48.8458 203.559 48.9023 203.554C48.9589 203.549 49.0157 203.556 49.0696 203.575C49.1235 203.594 49.1734 203.623 49.2164 203.662C49.2594 203.702 49.2946 203.749 49.32 203.803C49.3454 203.856 49.3605 203.915 49.3644 203.975C49.3684 204.034 49.3611 204.094 49.3429 204.151C49.3248 204.208 49.2961 204.261 49.2587 204.306C49.2212 204.351 49.1757 204.387 49.1248 204.414L47.2021 205.435L46.8169 204.625Z" fill="#778BBC"/>
                <path d="M51.636 202.675C51.6107 202.622 51.5956 202.564 51.5915 202.505C51.5874 202.445 51.5944 202.386 51.6121 202.329C51.6298 202.273 51.6579 202.221 51.6947 202.176C51.7315 202.131 51.7764 202.094 51.8267 202.067L53.7498 201.045L54.1389 201.856L52.215 202.877C52.1646 202.904 52.1095 202.921 52.0529 202.925C51.9964 202.93 51.9395 202.923 51.8856 202.904C51.8318 202.885 51.782 202.855 51.7391 202.816C51.6962 202.777 51.6612 202.729 51.636 202.675V202.675Z" fill="#778BBC"/>
                <path d="M52.5481 198.721L50.6242 199.743C50.5738 199.77 50.5189 199.786 50.4626 199.791C50.4063 199.795 50.3497 199.788 50.296 199.77C50.2424 199.751 50.1927 199.722 50.1498 199.683C50.1069 199.644 50.0716 199.596 50.0461 199.543C50.0205 199.49 50.0051 199.432 50.0008 199.372C49.9964 199.313 50.0032 199.253 50.0208 199.196C50.0383 199.139 50.0662 199.087 50.103 199.042C50.1397 198.996 50.1845 198.959 50.2349 198.932L52.1588 197.91L52.5481 198.721Z" fill="#778BBC"/>
                <path d="M50.6857 201.107C50.6572 201.054 50.6402 200.996 50.6356 200.936C50.6309 200.877 50.6388 200.817 50.6587 200.761C50.6786 200.704 50.7102 200.652 50.7516 200.607C50.793 200.562 50.8435 200.525 50.9001 200.498L53.0628 199.477L53.5004 200.286L51.3377 201.309C51.2809 201.336 51.2189 201.353 51.1553 201.357C51.0916 201.362 51.0277 201.355 50.967 201.336C50.9064 201.317 50.8503 201.287 50.802 201.248C50.7537 201.209 50.7142 201.161 50.6857 201.107V201.107Z" fill="#778BBC"/>
                <path d="M52.0502 212.96C53.1678 212.958 54.2663 212.673 55.2414 212.134C56.2165 211.594 57.036 210.818 57.6221 209.877C58.2081 208.937 58.5414 207.865 58.5901 206.762C58.6389 205.659 58.4016 204.562 57.9008 203.574L57.2552 202.299L56.4409 202.703L57.0865 203.979C57.7583 205.3 57.8713 206.831 57.4005 208.235C56.9297 209.639 55.9137 210.8 54.576 211.464C53.2384 212.128 51.6887 212.239 50.2678 211.774C48.8469 211.309 47.6712 210.305 46.9994 208.984L46.3538 207.708L45.5396 208.112L46.1851 209.387C46.7323 210.46 47.5702 211.362 48.6053 211.993C49.6405 212.623 50.8325 212.958 52.0486 212.959L52.0502 212.96Z" fill="#1E2248"/>
                <path d="M54.9956 176.96C53.4838 177.788 52.1001 178.813 50.6217 179.694C50.3964 179.828 50.1915 179.484 50.416 179.35C51.8944 178.472 53.2732 177.444 54.7899 176.615C55.0193 176.489 55.225 176.834 54.9956 176.96Z" fill="#1E2248"/>
                <path d="M56.1658 180.978C54.8137 180.978 53.4632 180.933 52.112 180.932C51.8422 180.932 51.8422 180.664 52.112 180.664C53.4632 180.664 54.8137 180.709 56.1658 180.711C56.4355 180.711 56.4355 180.978 56.1658 180.978Z" fill="#1E2248"/>
                <path d="M54.7973 185.02C53.4291 184.113 51.9974 183.261 50.7041 182.248C50.5013 182.088 50.7846 181.806 50.989 181.965C52.2541 182.96 53.6585 183.788 55.0009 184.676C55.2157 184.817 55.0145 185.164 54.7973 185.02Z" fill="#1E2248"/>
                <path d="M77.15 158.6L86.7715 149.934H96.937C97.264 149.935 97.5879 149.864 97.8901 149.726C98.1922 149.588 98.4668 149.386 98.6981 149.132C98.9293 148.877 99.1128 148.575 99.238 148.243C99.3632 147.91 99.4276 147.554 99.4276 147.194V110.977C99.4275 110.617 99.363 110.261 99.2377 109.928C99.1125 109.596 98.929 109.294 98.6977 109.04C98.4664 108.785 98.1919 108.584 97.8898 108.446C97.5877 108.309 97.2639 108.238 96.937 108.238H50.7668C50.107 108.239 49.4743 108.527 49.0078 109.041C48.5412 109.554 48.279 110.251 48.2788 110.977V147.199C48.2788 147.558 48.3432 147.915 48.4684 148.248C48.5936 148.58 48.777 148.882 49.0083 149.137C49.2396 149.391 49.5142 149.593 49.8163 149.731C50.1185 149.868 50.4424 149.939 50.7694 149.939H77.1544L77.15 158.6Z" fill="#514DFF"/>
                <path d="M75.4123 113.54L85.3531 142.318H80.8473L78.7765 136.026H67.399L65.1781 142.318H60.6369L70.6157 113.54H75.4123ZM77.2674 132.049L73.0127 118.847L68.7201 132.049H77.2674Z" fill="white"/>
                <path d="M318.9 222.9C319.182 222.12 318.778 221.261 317.998 220.98C317.219 220.699 316.36 221.102 316.078 221.882L318.9 222.9ZM161.997 330.5V329H161.995L161.997 330.5ZM61.5386 295.595C60.7181 295.709 60.1456 296.467 60.26 297.287L62.1227 310.658C62.237 311.479 62.9948 312.051 63.8153 311.937C64.6358 311.823 65.2083 311.065 65.094 310.244L63.4382 298.359L75.3234 296.703C76.144 296.589 76.7164 295.831 76.6021 295.011C76.4878 294.19 75.73 293.618 74.9095 293.732L61.5386 295.595ZM316.078 221.882C293.573 284.274 233.074 329 161.997 329V332C234.355 332 295.972 286.465 318.9 222.9L316.078 221.882ZM161.995 329C126.073 329.055 91.1446 317.41 62.6498 295.884L60.8414 298.277C89.8615 320.2 125.428 332.056 161.999 332L161.995 329Z" fill="#B2F5EA"/>
                <path d="M26.0665 73.2029C25.5824 73.8751 25.7348 74.8125 26.407 75.2967C27.0793 75.7808 28.0167 75.6284 28.5009 74.9561L26.0665 73.2029ZM216.895 15.8381C217.639 15.4732 217.946 14.5744 217.581 13.8307L211.634 1.71103C211.269 0.967309 210.37 0.660227 209.627 1.02515C208.883 1.39007 208.576 2.2888 208.941 3.03252L214.227 13.8056L203.454 19.0915C202.71 19.4565 202.403 20.3552 202.768 21.0989C203.133 21.8426 204.031 22.1497 204.775 21.7848L216.895 15.8381ZM28.5009 74.9561C49.0066 46.4852 78.3473 25.3329 112.115 14.6837L111.213 11.8226C76.8342 22.6645 46.9538 44.2021 26.0665 73.2029L28.5009 74.9561ZM112.115 14.6837C145.884 4.03444 182.251 4.46512 215.749 15.9109L216.719 13.072C182.615 1.41924 145.592 0.980862 111.213 11.8226L112.115 14.6837Z" fill="#DFDCFB"/>
        </Icon>
    );
}
