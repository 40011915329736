import {LocalizedEpisode} from "../../models/PodcastEpisode";
import {useEffect, useState} from "react";
import {LocalizationStatus} from "../../models/Localization";
import {Step, Steps, useSteps} from "chakra-ui-steps";
import {Flex, Text} from "@chakra-ui/react";

export const LocalizationStepComponent = ({localizedEpisode}: {localizedEpisode: LocalizedEpisode}) => {
    const [currentState, setCurrentState] = useState<number>(0)

    useEffect(() => {
        console.log(`localizedEpisode.state: ${localizedEpisode.status}`)
        switch (localizedEpisode.status) {
            case LocalizationStatus.TRANSCRIBING, LocalizationStatus.PUBLISHER_EDITING: {
                setCurrentState(0)
                break
            }
            case LocalizationStatus.TRANSCRIBED: {
                setCurrentState(0)
                break
            }
            case LocalizationStatus.TRANSLATING: {
                setCurrentState(1)
                break
            }
            case LocalizationStatus.TRANSLATED: {
                setCurrentState(1)
                break
            }
            case LocalizationStatus.DUBBING: {
                setCurrentState(2)
                break
            }
            case LocalizationStatus.DUBBED: {
                setCurrentState(2)
            }
        }
    }, [localizedEpisode.status])

    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: currentState,
    });
    return (
        <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'center'} justifyContent={'center'}>
            <Flex direction={'row'} w={'full'} gap={'36px'} alignItems={'flex-start'}>
                <Text w={'20%'} p={'0px'} color="gray.700" align={'start'} fontSize="12px" fontStyle="normal" fontWeight="500" lineHeight="16px">
                    {localizedEpisode.language.name.toUpperCase()}
                </Text>
                <Flex direction={'column'} w={'full'} alignItems={'center'} justifyContent={'center'}>
                    <Steps w={'full'} variant={'simple'} activeStep={currentState}>
                        <Step label="Transcription">
                            <></>
                        </Step>
                        <Step label="Translation"/>
                        <Step label="Dubbing"/>
                    </Steps>
                </Flex>
            </Flex>
        </Flex>
    )
}
