import {
    Button,
    Flex,
    Grid, IconButton, Menu, MenuButton, MenuItem, MenuList,
    TabIndicator,
    TabList,
    TabPanel,
    TabPanels,
    Tabs, Tag,
    Text, useBreakpointValue,
    useDisclosure
} from "@chakra-ui/react";
import {AddIcon, ChevronLeftIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon} from "@chakra-ui/icons";
import React, {useEffect, useState} from "react";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {useLibrary} from "../../context/LibraryContext";
import {Link, useParams} from "react-router-dom";
import { MdFilterList } from "react-icons/md";
import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {PublisherReviewModule} from "../../components/episode/publisher-review-module";
import {Podcast} from "../../models/Podcast";
import {useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {SearchLogo} from "../../assets/SearchIcon";
import {CustomPagination} from "../../components/custom-pagination";
import {EpisodeReviewCard} from "../../components/episode/episode-review-card";
import {ShowCardSkeleton} from "../../components/skeletons";
import {LocalizationCard} from "../../components/episode/localization-card";
import ReviewDashboardPanel from "../../components/episode/review-dashboard-panel";

export default function PodcastPostProductionLibrary({podcast, loading}: {podcast: Podcast, loading: boolean}) {
    const library: any = useLibrary()
    const user: any = useUser()
    const [searchTerm, setSearchTerm] = useState('');
    const [stateFilter, setStateFilter] = useState<TranscriptionState>()
    const [currentSortingParam, setCurrentSortingParam] = useState<'title' | 'state'>('title')
    const [tabIndex, setTabIndex] = useState<number>(0)
    const { podcastId }: any = useParams();
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [sessions, setSessions] = useState<TranscriptionSession[]>([])
    const [transcriptionStates, setTranscriptionStates] = useState<Map<TranscriptionState, number>>(new Map())
    const getLocalizationSessionReq = useApi(`/publisher/transcription/sessions/${podcastId}`, user.token, true)

    const [page, setPage] = useState(1); // initialize the current page to 1

    const { isOpen, onOpen, onClose } = useDisclosure()

    const dynamicPageSize = useBreakpointValue({ base: 4, md: 4, lg: 4 , xl: 4 });
    const [pageSize, setPageSize] = useState(dynamicPageSize || 4);

    const [transcriptionStateFilters, setTranscriptionStateFilters] = useState<Map<TranscriptionState, [string, string]>>(new Map())

    useEffect(() => {
        setPageSize(dynamicPageSize || 8);
    }, [dynamicPageSize]);

    useEffect(() => {
        if (isSearching) {
            setCurrentSortingParam('title')
        }
    }, [isSearching]);

    useEffect(() => {
        if (!getLocalizationSessionReq.loading && getLocalizationSessionReq.data) {
            let stateMap = new Map<TranscriptionState, number>()
            let stateFilters = new Map<TranscriptionState, [string, string]>()
            getLocalizationSessionReq.data.forEach((session: TranscriptionSession) => {
                if (stateMap.has(session.state)) {
                    stateMap.set(session.state, stateMap.get(session.state) as number + 1)
                } else {
                    stateMap.set(session.state, 1)
                }
            })
            stateFilters.set(TranscriptionState.SPEECH_TO_TEXT, ['Speech to Text: In Progress', 'yellow'])
            stateFilters.set(TranscriptionState.READY_FOR_PROOFREADING, ['Proofreading: Not Started', 'gray']);
            stateFilters.set(TranscriptionState.PROOFREADING, ['Proofreading: In Progress', 'yellow']);
            stateFilters.set(TranscriptionState.PROOFREADING_COMPLETED, ['Proofreading: Ready for next step', 'red']);
            stateFilters.set(TranscriptionState.EDITOR_REVIEW, ['Editor Review: Not Started', 'gray']);
            stateFilters.set(TranscriptionState.EDITOR_REVIEWING, ['Editor Review: In Progress', 'yellow']);
            stateFilters.set(TranscriptionState.PUBLISHER_REVIEWING, ['Publisher Review: In Progress', 'blue']);
            stateFilters.set(TranscriptionState.EDITOR_REVIEW_COMPLETED, ['Publisher Review: Not Started', 'red']);
            stateFilters.set(TranscriptionState.COMPLETED, ['Publisher Review: Completed', 'green']);

            setSessions(getLocalizationSessionReq.data)
            setTranscriptionStates(stateMap)
            setTranscriptionStateFilters(stateFilters)
        }
    }, [getLocalizationSessionReq.loading])

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1)
    };

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredEpisodes = sessions.filter((session) => {
        const { title } = (session as PodcastTranscriptionTaskMeta).episode;
        const normalizedSearchTerm = searchTerm.toLowerCase();

        switch (currentSortingParam) {
            case 'title': {
                return (
                    title.toLowerCase().includes(normalizedSearchTerm)
                );
            }
            case 'state': {
                return (
                    session.state === stateFilter
                );
            }
        }
    });

    return (
        <Flex w="full" flexDirection="column" alignItems={'flex-start'} gap={'24px'}>
            <Flex w={'full'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Flex w={'33%'} justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                    <SearchLogo onType={handleSearchInputChange} onOpenWidth={'full'} setIsSearching={setIsSearching}/>
                </Flex>
                <Flex w={'full'} justifyContent={'center'} alignItems={'center'} direction={'row'} gap={'12px'}>
                    <CustomPagination
                        totalPages={Math.ceil(filteredEpisodes.length / pageSize)}
                        currentPage={page}
                        onPageChange={setPage}
                    />
                </Flex>
                <Flex w={'33%'} gap={'8px'} alignItems={'center'} justifyContent={'flex-end'} direction={'row'}>
                    <Button onClick={onOpen} bgColor={'teal.500'} _hover={{bgColor: 'teal.600'}} h={'36px'} borderRadius={'6px'} borderColor={'gray.500'} size="lg" variant={'solid'}>
                        <Text color={'white'} fontSize={'16px'} fontFamily={'sans-serif'} fontWeight={'600px'}>Manage Reviews</Text>
                    </Button>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Filter'
                            icon={<MdFilterList />}
                            variant='outline'
                        />
                        {!getLocalizationSessionReq.loading && sessions.length > 0 &&
                            <MenuList>
                                <MenuItem gap={'12px'} onClick={() => {setCurrentSortingParam('title')}}>
                                    <Tag size="md" variant="solid" colorScheme={'gray'}>
                                        All {sessions.length}
                                    </Tag>
                                </MenuItem>
                                {Array.from(transcriptionStateFilters).map(([state, label]) => (
                                        <MenuItem gap={'12px'} onClick={() => {
                                            setStateFilter(state)
                                            setIsSearching(false)
                                            setPage(1)
                                            setCurrentSortingParam('state')
                                        }}>
                                            <Tag key={state} size="md" variant="solid" colorScheme={label[1]}>
                                                {label[0]} {transcriptionStates.get(state as TranscriptionState)? transcriptionStates.get(state as TranscriptionState) : 0}
                                            </Tag>
                                        </MenuItem>
                                ))}
                            </MenuList>
                        }
                    </Menu>
                </Flex>
            </Flex>
            <Flex w="full" flexDirection="column" alignItems={'flex-start'}>
                {!getLocalizationSessionReq.loading && sessions.length > 0 &&
                    <Grid w={'full'} templateColumns='repeat(1, 1fr)' gap={'24px'}>
                        {filteredEpisodes.slice(startIndex, endIndex).map((session: TranscriptionSession) => (
                            <LocalizationCard session={session}/>
                        ))}
                    </Grid>
                }
                {!getLocalizationSessionReq.loading && sessions.length === 0 &&
                    <Flex w={'full'} direction={'column'} alignItems={'center'} justifyContent={'center'}>
                        <Text fontSize={'18px'} fontWeight={'600'} color={'gray.500'}>You have not localized any episodes yet</Text>
                    </Flex>

                }
                {getLocalizationSessionReq.loading &&
                    <Grid w={'full'} templateColumns='repeat(1, 1fr)' gap={'24px'}>
                        <ShowCardSkeleton/>
                        <ShowCardSkeleton/>
                        <ShowCardSkeleton/>
                    </Grid>
                }
            </Flex>
            <ReviewDashboardPanel isOpen={isOpen} onOpen={onOpen} onClose={onClose} podcastId={podcastId} />
        </Flex>
    )
}
