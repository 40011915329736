import {useUser} from "../context/UserContext";
import React, {useEffect, useState} from "react";
import {Stack} from "@chakra-ui/react";
import LoadingSpinner from "../components/loading-spinner";
import {UserCategory} from "../models/Users";

export default function Entry () {
    const user: any = useUser()
    const [loading, setLoading] = useState(true)

    //TODO: Add path for non identified users
    useEffect(() => {
        if (!user.loading && user.user) {
            console.log(`User loading completed ${JSON.stringify(user.user.category)}`)
            console.log(user.user.category)
            if (user.user.category === UserCategory.PUBLISHER) {
                console.log(`UserType is Publisher`)
                window.location.href = '/library'
            } else if (user.user.category === UserCategory.EDITOR) {
                console.log(`UserType is Labeler`)
                window.location.href = "/r/dashboard"
            }
        }
        if (!user.loading && !user.user) {
            console.log(`User loading completed ${JSON.stringify(user.user)}`)
            window.location.href = "/login"
        }

    }, [user.loading])

    return (
        <>
            {loading  && <LoadingSpinner/>}
        </>
    )
}
