import {Publisher} from "./Publisher";

export enum MemberRole {
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR',
    VIEWER = 'VIEWER'
}

export interface TeamMemberInvitation {
    id: string,
    invited_by: Pick<Publisher, 'id'| 'email'| 'name'>
    role: MemberRole,
    status: TeamInvitationStatus,
    created_at: any,
    email: string,
    episodes: string[],
}

export interface TeamMember extends TeamMemberInvitation {
    accepted_at: any,
    user_id: string
    name: string
    revoked_at?: any,

}

export enum TeamInvitationStatus {
    NOT_SENT = "NOT_SENT",
    SENT = "SENT",
    OPENED = "OPENED",
    REMINDED = "REMINDED",
    DECLINED = "DECLINED",
    ACCEPTED = "ACCEPTED",
    REVOKED = "REVOKED",
}
