import {Podcast} from "../../models/Podcast";
import {useUser} from "../../context/UserContext";
import React, {useEffect, useState} from "react";
import {Avatar, Button, Divider, Flex, Icon, Menu, MenuButton, Spinner, Tag, TagLabel, Text} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import {Publisher} from "../../models/Publisher";
import {Language, Subscription} from "../../models/AppConfig";
import {useLocalization} from "../../context/LocalizationContext";
import {LuEdit2} from "react-icons/lu";
import {UserCategory} from "../../models/Users";
import {GiPlainCircle} from "react-icons/gi";
import {FiCheckCircle} from "react-icons/fi";

export const SubscriptionSettings = () => {
    const user: any = useUser()
    const localization: any = useLocalization()
    const [subscriptions, setSubscriptions] = useState<Subscription[]>([])


    useEffect(() => {
        if (!localization.appConfigLoading && localization.appConfigDownloaded) {
            console.log(`subscriptions: ${JSON.stringify(localization.subscriptions)}`)
            let data: any[] = []
            console.log(`subscriptions: ${JSON.stringify(Object.values(localization.subscriptions))}`)
            Object.values(localization.subscriptions).forEach((subscription: any) => {
                data.push(subscription)
            })
            setSubscriptions(data)
        }
    }, [localization.appConfigLoading, localization.appConfigDownloaded])

    return (
        <Flex w={'full'} direction={'column'} alignItems={'flex-start'} gap="24px" alignSelf={'stretch'}>
            {subscriptions.length > 0 && subscriptions.map((subscription: Subscription) => {
                return (
                    <SubscriptionComponent key={subscription.type} subscription={subscription}/>
                )
            })
            }
        </Flex>
    )
}

export const SubscriptionComponent = ({subscription}: {subscription: Subscription}) => {
    const user: any = useUser()
    const [isCurrentPlan, setIsCurrentPlan] = useState<boolean>(false)

    useEffect(() => {
        if (!user.loading && user.user) {
            if (user.user.category == UserCategory.PUBLISHER) {
                let publisher: Publisher = user.user as Publisher
                console.log(`publisher: ${JSON.stringify(publisher.subscription.type)}`)
                console.log(`subscription: ${JSON.stringify(subscription.type)}`)
                setIsCurrentPlan(publisher.subscription.type === subscription.type)
            }
        }

    }, [user.loading])

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Flex w={'full'} direction={'column'} gap={'24px'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                    <Flex w="full" direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            {subscription.type.toUpperCase()}
                        </Text>
                    </Flex>
                    {subscription.description && <Text align={'start'} color="gray.600" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                        {subscription.description}
                    </Text>
                    }
                    <Flex w={'full'} direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={'8px'}>
                        <Flex key={'transcription'} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'center'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                            <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Transcription
                            </Text>
                            <Tag size={'sm'} borderRadius={'12px'} bgColor={'teal.100'} color={'teal.900'} ml={'12px'}>
                                <TagLabel>${subscription.transcription_price}/min</TagLabel>
                            </Tag>
                        </Flex>
                        <Flex key={'translation'} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'center'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                            <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Translation
                            </Text>
                            <Tag size={'sm'} borderRadius={'12px'} bgColor={'teal.100'} color={'teal.900'} ml={'12px'}>
                                <TagLabel>${subscription.translation_price}/min</TagLabel>
                            </Tag>
                        </Flex>
                        <Flex key={'dubbing'} gap={'4px'} h={'36px'} p={'6px 12px'} justifyContent={'center'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent'>
                            <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Dubbing
                            </Text>
                            <Tag size={'sm'} borderRadius={'12px'} bgColor={'teal.100'} color={'teal.900'} ml={'12px'}>
                                <TagLabel>${subscription.dubbing_price}/min</TagLabel>
                            </Tag>
                        </Flex>
                    </Flex>
                    {subscription.details && subscription.details.length > 0 ?
                        <>
                            {subscription.details.map((detail: string) => {
                                return (
                                    <Flex key={detail} w={'full'} direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={'8px'}>
                                        <Icon as={FiCheckCircle} color={'teal.400'} boxSize={'16px'}/>
                                        <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                            {detail}
                                        </Text>
                                    </Flex>
                                )
                            })
                            }
                        </>
                        :
                        null
                    }
                </Flex>
                <Button isDisabled={!!isCurrentPlan} variant={'outline'} py={'10px'} px={'16px'} gap={'8px'}>
                    {isCurrentPlan ? 'Current Plan' : 'Select Plan'}
                </Button>
            </Flex>
        </Flex>
    )
}
