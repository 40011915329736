import {
    PodcastTranscriptionTaskMeta,
    TranscriptionProgress,
    TranscriptionSession,
    TranscriptionState
} from "../../models/Localization";
import {useEffect, useState} from "react";
import {Step, Steps, useSteps} from "chakra-ui-steps";
import {Flex, Text} from "@chakra-ui/react";

export const TranscriptionProgressComponent = ({progress}: {progress: TranscriptionProgress}) => {
    const [currentState, setCurrentState] = useState<number>(0)

    useEffect(() => {
        switch (progress.state) {
            case TranscriptionState.SPEECH_TO_TEXT: {
                setCurrentState(0)
                break
            }
            case TranscriptionState.PROOFREADING: {
                setCurrentState(1)
                break
            }
            case TranscriptionState.COMPLETED: {
                setCurrentState(2)
                break
            }
        }
    }, [progress])
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: currentState,
    });
    return (
        <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'center'} justifyContent={'center'}>
            <Flex direction={'row'} w={'full'} gap={'36px'} alignItems={'flex-start'}>
                <Text w={'20%'} p={'0px'} color="gray.700" align={'start'} fontSize="12px" fontStyle="normal" fontWeight="500" lineHeight="16px">
                    English
                </Text>
                <Flex direction={'column'} w={'full'} alignItems={'center'} justifyContent={'center'}>
                    <Steps w={'full'} variant={'simple'} activeStep={currentState}>
                        <Step label="Speech-to-text">
                            <></>
                        </Step>
                        <Step label="Proofreading"/>
                        <Step label="Completed"/>
                    </Steps>
                </Flex>
            </Flex>
        </Flex>
    )
}

export const TranscriptionStateProgressComponent = ({progress, session}: {progress: TranscriptionState, session: TranscriptionSession}) => {
    const [currentState, setCurrentState] = useState<number>(0)
    const proofreading = [
        TranscriptionState.PROOFREADING,
        TranscriptionState.READY_FOR_PROOFREADING,
        TranscriptionState.PROOFREADING_COMPLETED
    ]

    useEffect(() => {
        switch (progress) {
            case TranscriptionState.SPEECH_TO_TEXT: {
                setCurrentState(0)
                break
            }
            case TranscriptionState.PROOFREADING: {
                setCurrentState(1)
                break
            }
            case TranscriptionState.PROOFREADING_COMPLETED: {
                setCurrentState(3)
                break
            }

            case TranscriptionState.READY_FOR_PROOFREADING: {
                setCurrentState(1)
                break
            }
            case TranscriptionState.EDITOR_REVIEWING: {
                setCurrentState(2)
                break
            }
            case TranscriptionState.EDITOR_REVIEW: {
                setCurrentState(3)
                break
            }

            case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                setCurrentState(3)
                break
            }
            case TranscriptionState.PUBLISHER_REVIEWING: {
                setCurrentState(3)
                break
            }
            case TranscriptionState.COMPLETED: {
                setCurrentState(4)
                break
            }
        }
    }, [progress])
    const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
        initialStep: currentState,
    });
    return (
        <Flex direction={'column'} w={'full'} gap={'36px'} alignItems={'flex-start'}>
            <Flex direction={'column'} w={'full'} alignItems={'center'} justifyContent={'center'}>
                <Steps w={'full'} variant={'simple'} activeStep={currentState}>
                    <Step label="AI Transcription" >
                        <></>
                    </Step>
                    <Step label="Human Proofreading"/>
                    {session.review_session !== undefined && <Step label="Editor Review"/>}
                    <Step label="Publisher Review"/>
                    <Step label="Completed"/>
                </Steps>
            </Flex>
        </Flex>
    )
}
