import {Button, Checkbox, Flex, Image, Spinner, Tag, TagLabel, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useUser} from "../../context/UserContext";
import {EpisodeLocalizationMeta, PodcastEpisode} from "../../models/PodcastEpisode";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {useLibrary} from "../../context/LibraryContext";
import {useLocalization} from "../../context/LocalizationContext";
import {PaymentStatus} from "../../models/Cart";
import {TranscriptionState} from "../../models/Localization";
import {useNavigate, useParams} from "react-router-dom";
import {PodcastLocalizationMeta} from "../../models/Podcast";
import {useApi} from "../../api/braincap-api";
import {EditorMeta} from "../../models/Editor";
import {UserCategory} from "../../models/Users";

export const ReviewerEpisodeCard = ({episode, userType}: {episode: any, userType: UserCategory}) => {
    const user: any = useUser()
    const library: any = useLibrary()
    const localization: any = useLocalization()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isInCart, setIsInCart] = useState<boolean>(false)
    const [waitingServerConfirmation, setWaitingServerConfirmation] = useState<boolean>(false)
    const [startReview, setStartReview] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate();
    const { podcastId } = useParams()
    let image_url = 'https://megaphone.imgix.net/podcasts/042e6144-725e-11ec-a75d-c38f702aecad/image/Huberman-Lab-Podcast-Thumbnail-3000x3000.png?ixlib=rails-4.3.1&max-w=3000&max-h=3000&fit=crop&auto=format,compress'
    const transcriptStartReq = useApi(`/reviewer/transcript/start/${episode.id}/${episode.podcast.id}/${episode.episode.id}`, user.token, false);
    //TODO: add podcast and image url to localization meta params

    useEffect(() => {
        if (startReview){
            if (episode.state === TranscriptionState.EDITOR_REVIEWING) {
                setStartReview(false)
                navigate(`/r/review/${episode.id}/${episode.podcast.id}/${episode.episode.id}`)
            } else {
                setLoading(true)
                let meta: EditorMeta = {
                    id: user.user.id,
                    name: user.user.name,
                    email: user.user.email,
                    category: user.user.category
                }
                transcriptStartReq.setPayloadData({
                    editor: meta,
                    session: episode
                })
            }
        }
    }, [startReview])

    useEffect(() => {
        if (!transcriptStartReq.loading && !!transcriptStartReq.data) {
            setLoading(false)
            navigate(`/r/review/${episode.id}/${episode.podcast.id}/${episode.episode.id}`)
        }
    }, [transcriptStartReq.data])
    return (
        <Flex p="12px" w={'fit-content'} flexDirection="column" alignItems="center" justifyContent={'center'} bgColor={'#fff'} borderRadius="12px" boxShadow={'sm'} wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis'>
               <Flex w={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
                   <Flex position={'relative'} alignItems="center" justifyContent={'center'}>
                       <Image fallbackSrc='https://via.placeholder.com/150'  boxSize={'full'} src={image_url} borderRadius="8px" bgColor="gray.100"/>
                   </Flex>
                   <Tooltip label={`${episode.episode.title}`}>
                       <Text  w={'full'} noOfLines={1} color="gray.700" fontSize="16px" fontWeight="500" lineHeight="24px">{episode.episode.title}</Text>
                   </Tooltip>
                   <Flex w={'full'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                       <Text  color='gray.500' fontSize={'14px'} fontFamily={'sans-serif'} fontStyle={'normal'} fontWeight={400}>
                           {secondsToHHMMSS(episode.episode.duration)}
                       </Text>
                   </Flex>
                   <Flex w={'full'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                       {loading && <Spinner alignSelf={'center'} colorScheme={'teal'}/>}
                       {!loading && userType === UserCategory.EDITOR &&
                           <Button isDisabled={loading} onClick={() => {setStartReview(true)}} size={'md'} w={'full'} variant={'outline'} borderRadius={'6px'} border={'1px solid'}>
                               {loading && <Spinner colorScheme={'teal'}/>}
                               {!loading && <Text color={'teal.500'} fontSize={'16px'} fontFamily={'sans-serif'} fontWeight={'600px'}>
                                   {episode.state === TranscriptionState.EDITOR_REVIEWING && 'Resume Review'}
                                   {episode.state === TranscriptionState.EDITOR_REVIEW && 'Start Review'}
                               </Text>
                               }
                           </Button>
                       }
                   </Flex>
               </Flex>
        </Flex>
    )
}
