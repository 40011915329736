import React from 'react';
import ReactDOM from 'react-dom/client';
import {StepsTheme} from "chakra-ui-steps";
import {ChakraProvider, extendTheme} from "@chakra-ui/react";
import axios from "axios";
import AuthProvider from "./context/AuthContext";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import UserProvider from "./context/UserContext";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

const queryClient = new QueryClient()


const colors = {
    brand: {
        100: '#B2F5EA'
    },
}

const CustomSteps = {
    ...StepsTheme,
    baseStyle: (props: any) => {
        return {
            ...StepsTheme.baseStyle(props),
            stepIndicator: {
                ...StepsTheme.baseStyle(props).stepIndicator,
                color: 'red.400',
            },
            stepContainer: {
                ...StepsTheme.baseStyle(props).stepContainer,
                h: 'fit-content',
            },
            step: {
                ...StepsTheme.baseStyle(props).step,
                h:'fit-content',
            },
            stepIconContainer: {
                ...StepsTheme.baseStyle(props).stepIconContainer,
            },
            label: {
                ...StepsTheme.baseStyle(props).label,
                color: 'gray.600'
            },
            labelContainer: {
                ...StepsTheme.baseStyle(props).labelContainer,
            }
        };
    },
};

const theme = extendTheme({
    fonts: {
        body: "sans-serif",
        font: "sans-serif",
        heading: "sans-serif",
    },
    colors,
    components: {
        Steps: CustomSteps,
        Select: {
            baseStyle: {
                _focus: {
                    borderColor: 'teal.500',
                }
            },
        },
        Button: {
            baseStyle: {
                fontWeight: "semibold",
            },
            variants: {
                "googleSignIn": {
                    bg: "#f2f2f2",
                    color: "#1f1f1f",
                    borderRadius: "4px",
                    fontFamily: "'Roboto', arial, sans-serif",
                    fontSize: "14px",
                    height: "40px",
                    paddingX: "12px",
                    transition: "background-color .218s, border-color .218s, box-shadow .218s",
                    _hover: {
                        boxShadow: "0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)",
                    },
                    _active: {
                        bg: "#f2f2f2",
                        opacity: "0.88",
                    },
                    _disabled: {
                        bg: "#ffffff61",
                        opacity: "38%",
                    }
                },
                solid: (props: any) => ({
                    bg: 'teal.500',
                    color: 'white',
                    _hover: {
                        bg: 'teal.600',
                        _disabled: {
                            bg: props.bg,
                        },
                    },
                    _active: {
                        bg: 'teal.700',
                        _disabled: {
                            bg: props.bg,
                        }
                    },
                    _disabled: {
                        bg: 'teal.400'
                    }
                }),
                outline: (props: any) => ({
                    borderColor: 'teal.500',
                    color: 'teal.500',
                    _hover: {
                        bg: 'teal.50',
                        borderColor: 'teal.600',
                        color: 'teal.600',
                        _disabled: {
                            bg: props.bg,
                        }
                    },
                    _active: {
                        bg: 'teal.50',
                        borderColor: 'teal.800',
                        color: 'teal.800',
                        _disabled: {
                            bg: props.bg,
                        }
                    },
                    _disabled: {
                        bg: 'white',
                        borderColor: 'gray.200',
                        color: 'gray.600',
                    }
                })
            },
        },
    },
})

axios.defaults.baseURL = "https://us-central1-maktub-sabr.cloudfunctions.net/api"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
            <BrowserRouter>
                <AuthProvider>
                    <UserProvider>
                        <App />
                    </UserProvider>
                </AuthProvider>
            </BrowserRouter>
        </ChakraProvider>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
