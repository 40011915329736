import {Center, Container, Flex, IconButton, Stack, useDisclosure} from "@chakra-ui/react";
import React, {useEffect} from "react";
import NavigationBar from "../components/navigation-bar";
import {useUser} from "../context/UserContext";
import {UserCategory} from "../models/Users";
import LibraryProvider from "../context/LibraryContext";
import LocalizationProvider from "../context/LocalizationContext";
import MiniDrawer from "../components/publisher-drawer";
import SimpleSidebar from "../components/publisher-drawer";

interface Page {
    name: string
    children: React.ReactNode | React.ReactNode[],
}

export default function PublisherLayout({name, children}: Page) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <LibraryProvider>
            <LocalizationProvider>
                <SimpleSidebar children={children}/>
            </LocalizationProvider>
        </LibraryProvider>

    )
}
