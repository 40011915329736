import {Drawer, DrawerContent, DrawerOverlay} from "@chakra-ui/modal";
import {
    CloseButton,
    Flex,
    useDisclosure,
    Text,
    Icon,
    Tabs,
    TabList,
    TabIndicator,
    TabPanels,
    TabPanel, Skeleton, Slide, Box, Center, Button
} from "@chakra-ui/react";
import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {GrMore} from "react-icons/gr";
import {Tab} from "../ui-utils";
import 'react-circular-progressbar/dist/styles.css';
import EpisodeModule from "./episode-module";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {ConfigurationModule} from "./configuration-module";
import {TeamSettings} from "../settings/team-settings";
import {useLibrary} from "../../context/LibraryContext";
import {Podcast} from "../../models/Podcast";
import {TranscriptionState} from "../../models/Localization";

export default function EpisodeDashboardPanel({episode, isMultiConfiguration, isOpen, onOpen, onClose, openingIndex}: {episode: PodcastEpisode | PodcastEpisode[], isOpen: boolean, onOpen: any, onClose: any, isMultiConfiguration?: boolean, openingIndex?: number}) {
    const auth: any = useAuth()
    const library: any = useLibrary()
    const [tabIndex, setTabIndex] = useState<number>(openingIndex? openingIndex : 0)
    const [singleEpisode, setSingleEpisode] = useState<PodcastEpisode>()
    const [multiEpisode, setMultiEpisode] = useState<PodcastEpisode[]>()
    const [podcast, setPodcast] = useState<Podcast>()

    useEffect(() => {
        if (episode) {
            if (isMultiConfiguration) {
                setMultiEpisode(episode as PodcastEpisode[])
            } else {
                setSingleEpisode(episode as PodcastEpisode)
            }
        }
    }, [episode])
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    const ToolbarMenuNew = () => {
        return (
            <Flex w="full" flexDirection="row" alignItems="center" justifyContent="center" boxShadow={'md'} border={'1px'} borderRadius={'4px'} bg={'white'}>
            </Flex>
        )
    }

    const FormatButtonNew = ({ format, icon }: { format: any, icon: any }) => {
        return (
            <Button variant={'unstyled'} py={'6px'} px={'8px'} gap={'6px'}></Button>
        )
    }
    return (
        <Drawer isOpen={isOpen} onClose={() => {
            onClose()
            auth.setOpenDrawer(false)
        }}
                size={'xl'}>
            <DrawerOverlay />
            <DrawerContent w={'full'} h={'100vh'} boxShadow="-4px 0px 10px 0px rgba(0, 0, 0, 0.06)">
                <Flex w="full" h={'full'} px={"2%"} pt={'2%'} pb={'10%'} flexDirection="column" alignItems="flex-start" gap="24px">
                    <Flex w="full" flexDirection="row" alignItems="center" justifyContent="space-between">
                        <CloseButton onClick={onClose} />
                        <Text color="gray.700" textAlign="center" isTruncated fontSize="lg" fontStyle="normal" fontWeight="medium" lineHeight="7">
                            {isMultiConfiguration? multiEpisode?.length + ' Episodes' : singleEpisode?.title}
                        </Text>
                        <>
                            <Icon as={GrMore} visibility={'hidden'} />
                        </>
                    </Flex>
                    {isMultiConfiguration ?
                        <ConfigurationModule closePanel={onClose} isMultiConfiguration={true} episode={isMultiConfiguration? multiEpisode as PodcastEpisode[] : singleEpisode as PodcastEpisode}/>
                        :
                        <Tabs w={'full'} style={{overflow: 'auto'}} position={'relative'} _selected={{textColor:'teal.500'}} align={'center'} isLazy={true} index={tabIndex} onChange={handleTabsChange} size={'lg'} variant={'unstyled'}>
                            <TabList>
                                {!isMultiConfiguration && <Tab>
                                    <Text>
                                        Dashboard
                                    </Text>
                                </Tab>
                                }
                                <Tab>
                                    <Text>
                                        Configuration
                                    </Text>
                                </Tab>
                            </TabList>
                            <TabIndicator pos={'fixed'} height="2px" bg="teal.500"/>
                            <TabPanels w={'full'} h={'full'} mt={'36px'} style={{overflow: 'auto'}}>
                                {!isMultiConfiguration && <TabPanel p={'0px'}>
                                    <EpisodeModule episode={singleEpisode as PodcastEpisode}/>
                                </TabPanel>
                                }
                                <TabPanel p={'0px'}>
                                    <ConfigurationModule closePanel={onClose} isMultiConfiguration={false} episode={isMultiConfiguration? multiEpisode as PodcastEpisode[] : singleEpisode as PodcastEpisode}/>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    }
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}
