import {EpisodeLocalizationMeta} from "./PodcastEpisode";
import {PodcastTask} from "./Localization";

export interface SpeakerReference {
    id?: string
    name: string
    email?: string
}

export interface Speaker extends SpeakerReference {
    created_as: SpeakerType
    created_at: any
    created_from: Pick<PodcastTask, 'id' | 'episode' | 'customer'> & {
        is_completed: boolean
    }
    host_episodes: string[]
    guest_episodes: string[]
    is_verified: boolean
}
export interface SpeakerInvitation extends SpeakerReference {
    invited_as: SpeakerType;
}

export type SpeakerEpisodeMeta = Omit<EpisodeLocalizationMeta, 'transcription'| 'translation' | 'dubbing'> & {
    invited_as: SpeakerType
}
export type SpeakerTask = Omit<PodcastTask, any>
export enum SpeakerType {
    HOST = "HOST",
    GUEST = "GUEST",
    NARRATOR = "NARRATOR",
    INTERVIEWER = "INTERVIEWER",
}
export enum SpeakerInvitationStatus {
    NOT_SENT = "NOT_SENT",
    SENT = "SENT",
    OPENED = "OPENED",
    REMINDED = "REMINDED",
    ACCEPTED = "ACCEPTED",
}
