import {PodcastTranscriptionTaskMeta, TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {Button, Divider, Flex, Image, Tag, Text, Tooltip} from "@chakra-ui/react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {CircularProgressbar} from "react-circular-progressbar";
import PodcastDashboardPanel from "../podcast/podcast-dashboard-panel";
import React, {useEffect} from "react";
import {TranscriptionProgressComponent, TranscriptionStateProgressComponent} from "./transcription-progress";
import {useLibrary} from "../../context/LibraryContext";
import { randomColor } from "@chakra-ui/theme-tools";
import {ChevronRightIcon} from "@chakra-ui/icons";
import {secondsToHHMMSS} from "../../utils/text-utils";

export const LocalizationCard = ({session}: {session: TranscriptionSession}) => {
    const library: any = useLibrary()
    const episode = (session as PodcastTranscriptionTaskMeta).episode
    const podcast = (session as PodcastTranscriptionTaskMeta).podcast
    const [imageUrl, setImageUrl] = React.useState<string>('https://via.placeholder.com/150')
    const [episodeImage, setEpisodeImage] = React.useState<string>('https://via.placeholder.com/150')
    const colors = ['yellow', 'purple', 'orange', 'blue']
    const { podcastId }: any = useParams();
    const navigate = useNavigate()


    useEffect(() => {
        if (library.episodesDownloaded && !library.episodeLoading) {
            const podcast = library.podcasts.get(podcastId)
            const ep = library.episodes[`${episode.id}`] !== undefined ? library.episodes[`${episode.id}`]  : null
            setImageUrl(podcast?.image_url)
            setEpisodeImage(ep?.image_url)
        }
    }, [library.episodesDownloaded])

    const getNavigationText = () => {
        switch (session.state) {
            case TranscriptionState.SPEECH_TO_TEXT: {
                break
            }
            case TranscriptionState.PROOFREADING: {
                return 'Open AI Transcript'
            }
            case TranscriptionState.PROOFREADING_COMPLETED: {
                return 'Open Proofread Transcript'
            }

            case TranscriptionState.READY_FOR_PROOFREADING: {
                return 'Open AI Transcript'

            }
            case TranscriptionState.EDITOR_REVIEWING: {
                return 'Open Proofread Transcript'
            }
            case TranscriptionState.EDITOR_REVIEW: {
                return 'Open Proofread Transcript'
            }

            case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                return 'Start Final Review'
            }
            case TranscriptionState.PUBLISHER_REVIEWING: {
                return 'Resume Final Review'
            }
            case TranscriptionState.COMPLETED: {
                return 'Open Final Transcript'
            }

        }
    }

    const navigateToStudio = () => {
        switch (session.state) {
            case TranscriptionState.SPEECH_TO_TEXT: {
                break
            }
            case TranscriptionState.PROOFREADING: {
                navigate(`/reader/${session.id}/asr`)
                break
            }
            case TranscriptionState.PROOFREADING_COMPLETED: {
                navigate(`/reader/${session.id}/proofread`)
                break
            }

            case TranscriptionState.READY_FOR_PROOFREADING: {
                navigate(`/reader/${session.id}/asr`)
                break
            }
            case TranscriptionState.EDITOR_REVIEWING: {
                navigate(`/reader/${session.id}/proofread`)
                break
            }
            case TranscriptionState.EDITOR_REVIEW: {
                navigate(`/reader/${session.id}/proofread`)
                break
            }

            case TranscriptionState.EDITOR_REVIEW_COMPLETED: {
                navigate(`/review/${session.id}/${(session as PodcastTranscriptionTaskMeta).podcast.id}/${(session as PodcastTranscriptionTaskMeta).episode.id}`)
                break
            }
            case TranscriptionState.PUBLISHER_REVIEWING: {
                navigate(`/review/${session.id}/${(session as PodcastTranscriptionTaskMeta).podcast.id}/${(session as PodcastTranscriptionTaskMeta).episode.id}`)
                break
            }
            case TranscriptionState.COMPLETED: {
                navigate(`/reader/${session.id}/proofread`)
                break
            }
        }
    }

    return (
        <Flex w={'full'} p="12px" flexDirection="column" alignItems="center" justifyContent={'flex-start'} bgColor={'#fff'} borderRadius="12px" gap={'8px'} boxShadow={'lg'}>
            <Flex w={'full'} flexDirection="row" alignItems="center" justifyContent="flex-start" gap="6px">
                <Image boxSize={'225px'} fallbackSrc={imageUrl} src={episodeImage} borderRadius="8px" bgColor="gray.100"/>
                <Flex p={'4px'} w={'full'} mt={'2px'} h={'full'} direction={'column'} alignItems={'flex-start'} justifyContent={'space-between'}>
                    <Flex w={'full'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Flex w={'full'} direction={'column'} justifyContent={'center'} alignItems={'flex-start'} gap={'4px'}>
                            <Text w={'full'} noOfLines={1} color="gray.700" fontSize="16px" fontWeight="600" lineHeight="24px">
                                {episode.title}
                            </Text>
                            <Text w={'full'} noOfLines={1} color="gray.500" fontSize="14px" fontWeight="500" lineHeight="24px">
                                {secondsToHHMMSS(episode.duration)}
                            </Text>
                        </Flex>
                        <Flex w={'40%'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Button variant={'ghost'} rightIcon={<ChevronRightIcon color={'teal'} w={6} h={6}/>} w={'fit-content'} alignItems={'center'} justifyContent={'flex-end'}
                                  onClick={navigateToStudio}
                            >
                                <Text color={'teal'} fontSize={'12px'} fontWeight={'600'}> {getNavigationText()}
                                </Text>
                            </Button>
                        </Flex>
                    </Flex>
                    <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                        {session.speakers.map((speaker) => (
                                <Tag key={speaker.id} size="md" variant="outline" colorScheme={randomColor({colors})}>
                                    {speaker.name}
                                </Tag>
                        ))}
                    </Flex>
                    <Flex w={'full'} flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="16px">
                        <Text wordBreak={'break-all'} color="gray.800" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            TRANSCRIPTION
                        </Text>
                        {session.state !== TranscriptionState.NOT_CREATED? <TranscriptionStateProgressComponent progress={session.state} session={session}/> : null}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
