import {Editor, Node, Point, Range, Text as SlateText, Transforms} from "slate";
import {ReactEditor, useSlate} from "slate-react";
import {ToolbarButton} from "../editors/toolbar-helper";
import {
    AlertDialog, AlertDialogBody, AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogOverlay, Avatar, Button,
    Divider, Editable, EditableInput, EditablePreview, EditableTextarea, Flex,
    Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,
    Text, Textarea, Tooltip, useDisclosure
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {ChatIcon, ChevronLeftIcon, EditIcon, SmallCloseIcon} from "@chakra-ui/icons";
import { MdTaskAlt } from "react-icons/md";
import {useUser} from "../../context/UserContext";
import {User, UserCategory} from "../../models/Users";


export const getHighlightedTimestampsComments = (editor: any) => {
    const { selection } = editor;

    if (!selection || Range.isCollapsed(selection)) return;

    const isReversed = Point.isAfter(selection.anchor, selection.focus);

    const textNodes = Array.from(
        Editor.nodes(editor, {
            at: selection,
            match: n => SlateText.isText(n),
        })
    );

    if (!textNodes.length) return;
    console.log(textNodes)

    const [firstTextNode, firstPath] = textNodes[0];
    const [lastTextNode, lastPath] = textNodes[textNodes.length - 1];


    const selectedText = Editor.string(editor, selection);
    console.log(textNodes)

    return { startTime: 'NaN', endTime: 'Nan', text: selectedText, selection: selection, nodes: textNodes };
};

// Function to find the closest word with a timestamp, searching in a specified direction
function findClosestWordWithTimestamp(textNode: any, path: any, editor: any, offset: any, direction: any) {
    let wordObj = findWordForTextNode(textNode, path, editor, offset);
    if (wordObj) return wordObj; // Return the word object if found

    let currentPoint: any = { path, offset };
    let initialDirection = direction; // Save the initial direction

    while (true) {
        if (direction === 'backward') {
            currentPoint = Editor.before(editor, currentPoint, { unit: 'word' });
        } else {
            currentPoint = Editor.after(editor, currentPoint, { unit: 'word' });
        }

        if (!currentPoint) {
            if (initialDirection === 'backward') {
                // If the initial direction was backward and no word with a timestamp is found,
                // reset the point and change direction to forward
                currentPoint = { path, offset };
                direction = 'forward';
                continue;
            } else {
                // If we are already searching forward, then break as we have reached the end
                break;
            }
        }

        const [currentNode, currentPath] = Editor.node(editor, currentPoint.path);
        wordObj = findWordForTextNode(currentNode, currentPath, editor, currentPoint.offset);
        if (wordObj) return wordObj;
    }

    return null; // Return null if no word with a timestamp is found in either direction
}
const findWordForTextNode = (textNode: any, path: any, editor: any, offset: number) => {
    const [parentNode]: any = Editor.parent(editor, path);

    if (!parentNode.children || !parentNode.children[0].words) return null;

    const wordsArray = parentNode.children[0].words;

    // Split the textNode text into individual words
    const textNodeWords = textNode.text.split(/\s+/);

    // Determine which word the offset falls within
    let currentLength = 0;
    let targetWord: any = null;
    for (let word of textNodeWords) {
        currentLength += word.length + 1; // +1 for the space
        if (currentLength >= offset) {
            targetWord = word;
            break;
        }
    }

    if (!targetWord) return null;

    // Find the corresponding word object for the target word
    const wordObj = wordsArray.find((word: any) => word.text === targetWord);

    return wordObj;
}

export const toggleFormatMark = (editor: any, format: any, start: any, end: any) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const toggleCommentMark = (editor: any, start: any, end: any, comments: any, text: any) => {
    console.log(`commenttimestamps: ${start} - ${end}`)

    Editor.addMark(editor, 'isComment', true)
    Editor.addMark(editor, 'comments', comments)
    Editor.addMark(editor, 'start', start)
    Editor.addMark(editor, 'end', end)
}

export const removeAllCommentMarks = (editor: any) => {
    Editor.removeMark(editor, 'isComment')
    Editor.removeMark(editor, 'comments')
}

export const toggleActionMark = (editor: any, start: any, end: any, text: any, newText: any, selection: any, user: User, currentDisplayedText: any) => {

    Editor.addMark(editor, 'isAction', true)
    Editor.addMark(editor, 'original_text', text)
    Editor.addMark(editor, 'current_displayed_text', 'original')
    Editor.addMark(editor, 'text', newText)
    Editor.addMark(editor, 'proposed_text', newText)
    Editor.addMark(editor, 'proposal_created_by', user)
    Editor.addMark(editor, 'current_displayed_text', currentDisplayedText)
    Editor.addMark(editor, 'selection', selection)
    Editor.addMark(editor, 'isApproved', false)
    Editor.addMark(editor, 'rejected', false)
    Editor.insertText(editor, currentDisplayedText? newText : text)

}
export const removeAllActionMarks = (editor: any, orginalText: any) => {
    Editor.removeMark(editor, 'isAction')
    Editor.removeMark(editor, 'proposed_text')
    Editor.removeMark(editor, 'original_text')
    Editor.removeMark(editor, 'selection')
    Editor.removeMark(editor, 'created_by')
    Editor.removeMark(editor, 'isApproved')
    Editor.removeMark(editor, 'rejected')
    Editor.removeMark(editor, 'proposal_created_by')
    Editor.removeMark(editor, 'current_displayed_text')
    Editor.insertText(editor, orginalText)
    Editor.addMark(editor, 'text', orginalText)

}
export const CommentContentButton = ({ format, icon, name, activeName, editor, customOnOpen}: { format: any, icon: any, name: string, activeName: string, editor: any, customOnOpen: any}) => {
    const isActive = isMarkActive(editor, format) || isMarkActive(editor, 'isAction')
    const [isOpen, setIsOpen] = useState(false)


    return (
        <ToolbarButton
            reversed
            active={isActive}
            onClick={customOnOpen}
            bg={isActive? "blue.50" : "white"}
        >
            {icon}
            <Text color={isActive? "blue.600" : "gray.600"} fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                {isActive? activeName : name}
            </Text>
        </ToolbarButton>
    )
}

export const ActionPopoverMenu = ({ isOpen, onOpen, onClose, editor, user, leaf}: { isOpen: any, onOpen: any, onClose: any, editor: any, user: User, leaf: any}) => {
    const [inputValue, setInputValue] = useState<string>('')
    const cancelRef: any = useRef<HTMLDivElement | null>()
    const [selectedText, setSelectedText] = useState<any>('')
    const [openCommentInput, setOpenCommentInput] = useState(false)
    const [openEditTextInput, setOpenEditTextInput] = useState(false)
    const [textValue, setTextValue] = useState<any>(selectedText)
    const [isTextReplaced, setIsTextReplaced] = useState<boolean>(false)
    const [comments, setComments] = useState<any[]>([])
    const [resetOriginalText, setResetOriginalText] = useState<boolean>(false)
    const [currentDisplayedText, setCurrentDisplayedText] = useState<'original' | 'proposal'>("proposal")
    const [highLightData, setHighLightData] = useState<any>(null)
    const [openApply, setOpenApply] = useState<boolean>(false)


    useEffect(() => {
        if (isOpen) {
            if (leaf.isComment) {
                let com = leaf.comments
                setComments(com)
                setHighLightData(leaf)
                setOpenApply(true)
            } else {
                setComments([])
            }

            if (leaf.isAction) {
                const marks: any = Editor.marks(editor)
                let text = leaf.current_displayed_text === 'original'? leaf.original_text : leaf.proposed_text
                setSelectedText(text)
                setTextValue(text)
                setIsTextReplaced(true)
            } else {
                setSelectedText(leaf?.text)
                setTextValue(leaf?.text)

            }

        } else {
            handleOnClose()
        }
    }, [isOpen])

    const handleOnKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            let comment = {
                user_id: user.id,
                user_category: user.category,
                user_name: user.name,
                user_email: user.email,
                created_at: Date.now(),
                comment: inputValue
            }
            setComments([...comments, comment])
            setInputValue('')
        }
    }

    const removeComment = (index: number) => {
        let newComments = [...comments]; // Create a copy
        newComments.splice(index, 1);
        if (newComments.length === 0) {
            setOpenApply(true)
        }
        setComments(newComments);
    }

    const handleOnClose = () => {
        setComments([])
        setTextValue('')
        setSelectedText(false)
        setOpenEditTextInput(false)
        setIsTextReplaced(false)
        setResetOriginalText(false)
        onClose()
    }

    const applyChanges =  () => {

        if (comments.length > 0 ) {
            Transforms.setNodes(
                editor,
                { comments: comments} as Partial<Node>,
                {
                    at: leaf.selection,
                }
            );
        } else {
            removeAllCommentMarks(editor)
        }


        handleOnClose()
    }

    const previewReset = () => {
        let marks: any = Editor.marks(editor)
        if (leaf.isAction) {
            let text = leaf.current_displayed_text === 'proposal'? leaf.original_text : leaf.proposed_text
            setSelectedText(text)
            setTextValue(text)
            setResetOriginalText(true)
            setIsTextReplaced(true)
        } else {
            setSelectedText(selectedText)
            setTextValue(selectedText)
            setIsTextReplaced(false)
        }
    }

    return (
        <Modal
            motionPreset='slideInBottom'
            onClose={handleOnClose}
            isOpen={isOpen}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Review</ModalHeader>
                <ModalCloseButton onClick={handleOnClose} />
                <ModalBody w={'full'} justifyContent={'center'} alignItems={'center'}>
                    {openCommentInput || openEditTextInput?
                        <Flex w={'full'} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                            <ChevronLeftIcon onClick={() => {
                                if (openCommentInput) {
                                    setOpenCommentInput(false)
                                } else if (openEditTextInput) {
                                    setOpenEditTextInput(false)
                                }
                            }}/>
                        </Flex>
                        :
                        null
                    }
                    <Divider w={'full'}/>
                    <Flex direction={'column'} w={'full'} p={'12px'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                        <Text color="gray.900" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                            {selectedText}
                        </Text>
                    </Flex>
                    <Divider w={'full'}/>
                    {comments.length > 0 && !openEditTextInput &&
                        <Flex direction={'column'} w={'full'} p={'12px'} gap={'12px'} justifyContent={'flex-start'}>
                            {comments.map((comment: any, index: number) => {
                                return (
                                    <>
                                        <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
                                            <Flex direction={'row'} w={'full'} gap={'8px'} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Avatar size={'sm'} name={comment.user_name}/>
                                                <Text color="gray.900" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                                                    {comment.comment}
                                                </Text>
                                            </Flex>
                                            {comment.user_id === user.id && <Tooltip label={'Delete'} placement={'left-start'} hasArrow>
                                                <SmallCloseIcon onClick={() => {removeComment(index)}} />
                                            </Tooltip>}
                                            <></>
                                        </Flex>

                                    </>
                                )
                            })}
                        </Flex>
                    }
                    {openCommentInput && <Flex direction={'row'} w={'full'} gap={'8px'} px={'12px'} py={'12px'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Avatar size={'sm'} name={'soulemane3'}/>
                        <Input variant={'filled'} value={inputValue} onChange={(e) => {setInputValue(e.target.value)}} size={'sm'} placeholder={'Add a comment'} onKeyDown={handleOnKeyDown}/>
                    </Flex>
                    }
                    {openEditTextInput && <Flex direction={'column'} w={'full'} gap={'8px'} px={'12px'} py={'12px'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Textarea resize={'vertical'} value={textValue} onChange={(e) => {setTextValue(e.target.value)}}/>
                        <Flex w={'full'} direction={'row'} gap={'8px'} justifyContent={'space-between'} alignItems={'center'}>
                            <Button  variant={'solid'} w={'full'} onClick={() => {previewReset()}}>Reset to Original</Button>
                            <Button isDisabled={textValue === selectedText} variant={'outline'} w={'full'} onClick={() => {
                                setIsTextReplaced(true)

                            }}>Save Edit</Button>
                        </Flex>
                    </Flex>
                    }
                    {!openEditTextInput && !openCommentInput && <Flex direction={'row'} w={'full'} gap={'8px'} px={'12px'} py={'12px'} justifyContent={'space-between'} alignItems={'center'}>
                        <Button leftIcon={<EditIcon/>} w={'full'} onClick={() => {setOpenEditTextInput(true)}}> {isMarkActive(editor, 'isAction')? 'Review Update' : 'Propose Update'} </Button>
                        <Button leftIcon={<ChatIcon/>} w={'full'} onClick={() => {setOpenCommentInput(true)}}> {isMarkActive(editor, 'isComment')? 'View Comments' : 'Add Comment'} </Button>
                    </Flex>
                    }
                    <Divider w={'full'}/>
                    {isTextReplaced || comments.length > 0 || openApply ?
                        <Button leftIcon={<MdTaskAlt />} variant={'ghost'} w={'full'} onClick={() => {applyChanges()}}>Apply</Button>
                        :
                        null
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const PopoverMenu = ({ isOpen, onOpen, onClose, editor, user}: { isOpen: any, onOpen: any, onClose: any, editor: any, user: User}) => {
    const [inputValue, setInputValue] = useState<string>('')
    const cancelRef: any = useRef<HTMLDivElement | null>()
    const [selectedText, setSelectedText] = useState<any>('')
    const [openCommentInput, setOpenCommentInput] = useState(false)
    const [openEditTextInput, setOpenEditTextInput] = useState(false)
    const [textValue, setTextValue] = useState<any>(selectedText)
    const [isTextReplaced, setIsTextReplaced] = useState<boolean>(false)
    const [comments, setComments] = useState<any[]>([])
    const [resetOriginalText, setResetOriginalText] = useState<boolean>(false)
    const [currentDisplayedText, setCurrentDisplayedText] = useState<'original' | 'proposal'>("proposal")
    const [highLightData, setHighLightData] = useState<any>(null)
    const [openApply, setOpenApply] = useState<boolean>(false)


    useEffect(() => {
        if (isOpen) {
            let data = getHighlightedTimestampsComments(editor)
            if (isMarkActive(editor, 'isComment')) {
                let com = isGetComments(editor)
                setComments(com)
                setHighLightData(data)
                setOpenApply(true)
            } else {
                setComments([])
            }

            if (isMarkActive(editor, 'isAction')) {
                const marks: any = Editor.marks(editor)
                let text = marks['current_displayed_text'] === 'original'? marks['original_text'] : marks['proposed_text']
                setSelectedText(text)
                setTextValue(text)
                setIsTextReplaced(true)
            } else {
                setSelectedText(data?.text)
                setTextValue(data?.text)

            }

        } else {
            handleOnClose()
        }
    }, [isOpen])

    const handleOnKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            let comment = {
                user_id: user.id,
                user_category: user.category,
                user_name: user.name,
                user_email: user.email,
                created_at: Date.now(),
                comment: inputValue
            }
            setComments([...comments, comment])
            setInputValue('')
        }
    }

    const removeComment = (index: number) => {
        let newComments = [...comments]; // Create a copy
        newComments.splice(index, 1);
        if (newComments.length === 0) {
            setOpenApply(true)
        }
        setComments(newComments);
    }

    const handleOnClose = () => {
        setComments([])
        setTextValue('')
        setSelectedText(false)
        setOpenEditTextInput(false)
        setIsTextReplaced(false)
        setResetOriginalText(false)
        onClose()
    }

    const applyChanges =  () => {
        let data: any = getHighlightedTimestampsComments(editor)
        let marks: any = Editor.marks(editor)

        if (comments.length > 0 ) {
            toggleCommentMark(editor, data?.startTime, data?.endTime, comments, data?.text)
        } else {
            removeAllCommentMarks(editor)
        }

        if (isTextReplaced) {
            if (marks['isAction']) {
                let originalText = marks['original_text']
                if (marks['current_displayed_text'] === 'proposal') {
                    if (resetOriginalText) {
                        removeAllActionMarks(editor, originalText)
                    } else {
                        toggleActionMark(editor, data?.startTime, data?.endTime, originalText, textValue, data.selection, user, 'proposal')
                    }
                }
            } else {
                toggleActionMark(editor, data?.startTime, data?.endTime, data?.text, textValue, data.selection, user, 'proposal')
            }
        } else {
            if (marks['isAction']) {
                removeAllActionMarks(editor, data?.text)
            }
        }

        handleOnClose()
    }

    const previewReset = () => {
        let marks: any = Editor.marks(editor)
        if (marks['isAction']) {
            let text = marks['current_displayed_text'] === 'proposal'? marks['original_text'] : marks['proposed_text']
            setSelectedText(text)
            setTextValue(text)
            setResetOriginalText(true)
            setIsTextReplaced(true)
        } else {
            setSelectedText(selectedText)
            setTextValue(selectedText)
            setIsTextReplaced(false)
        }
    }

    return (
        <Modal
            motionPreset='slideInBottom'
            onClose={handleOnClose}
            isOpen={isOpen}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Review</ModalHeader>
                <ModalCloseButton onClick={handleOnClose} />
                <ModalBody w={'full'} justifyContent={'center'} alignItems={'center'}>
                    {openCommentInput || openEditTextInput?
                        <Flex w={'full'} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                            <ChevronLeftIcon onClick={() => {
                                if (openCommentInput) {
                                    setOpenCommentInput(false)
                                } else if (openEditTextInput) {
                                    setOpenEditTextInput(false)
                                }
                            }}/>
                        </Flex>
                        :
                        null
                    }
                    <Divider w={'full'}/>
                    <Flex direction={'column'} w={'full'} p={'12px'} justifyContent={'flex-start'} alignItems={'flex-start'}>
                        <Text color="gray.900" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                            {selectedText}
                        </Text>
                    </Flex>
                    <Divider w={'full'}/>
                    {comments.length > 0 && !openEditTextInput &&
                        <Flex direction={'column'} w={'full'} p={'12px'} gap={'12px'} justifyContent={'flex-start'}>
                            {comments.map((comment: any, index: number) => {
                                return (
                                    <>
                                        <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'6px'}>
                                            <Flex direction={'row'} w={'full'} gap={'8px'} justifyContent={'flex-start'} alignItems={'center'}>
                                                <Avatar size={'sm'} name={comment.user_name}/>
                                                <Text color="gray.900" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                                                    {comment.comment}
                                                </Text>
                                            </Flex>
                                            {comment.user_id === user.id && <Tooltip label={'Delete'} placement={'left-start'} hasArrow>
                                                <SmallCloseIcon onClick={() => {removeComment(index)}} />
                                            </Tooltip>}
                                            <></>
                                        </Flex>

                                    </>
                                )
                            })}
                        </Flex>
                    }
                    {openCommentInput && user.category !== UserCategory.PUBLISHER && <Flex direction={'row'} w={'full'} gap={'8px'} px={'12px'} py={'12px'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Avatar size={'sm'} name={user.name}/>
                        <Input variant={'filled'} value={inputValue} onChange={(e) => {setInputValue(e.target.value)}} size={'sm'} placeholder={'Add a comment'} onKeyDown={handleOnKeyDown}/>
                    </Flex>
                    }
                    {openEditTextInput && <Flex direction={'column'} w={'full'} gap={'8px'} px={'12px'} py={'12px'} justifyContent={'flex-start'} alignItems={'center'}>
                        <Textarea resize={'vertical'} value={textValue} onChange={(e) => {setTextValue(e.target.value)}}/>
                        <Flex w={'full'} direction={'row'} gap={'8px'} justifyContent={'space-between'} alignItems={'center'}>
                            <Button  variant={'solid'} w={'full'} onClick={() => {previewReset()}}>Reset to Original</Button>
                            <Button isDisabled={textValue === selectedText} variant={'outline'} w={'full'} onClick={() => {
                                setIsTextReplaced(true)

                            }}>Save Edit</Button>
                        </Flex>
                    </Flex>
                    }
                    {!openEditTextInput && !openCommentInput && <Flex direction={'row'} w={'full'} gap={'8px'} px={'12px'} py={'12px'} justifyContent={'space-between'} alignItems={'center'}>
                        <Button leftIcon={<EditIcon/>} w={'full'} onClick={() => {setOpenEditTextInput(true)}}> {isMarkActive(editor, 'isAction')? 'Review Update' : 'Propose Update'} </Button>
                        <Button leftIcon={<ChatIcon/>} w={'full'} onClick={() => {setOpenCommentInput(true)}}> {isMarkActive(editor, 'isComment')? 'View Comments' : 'Add Comment'} </Button>
                    </Flex>
                    }
                    <Divider w={'full'}/>
                    {isTextReplaced || comments.length > 0 || openApply ?
                        <Button leftIcon={<MdTaskAlt />} variant={'ghost'} w={'full'} onClick={() => {applyChanges()}}>Apply</Button>
                        :
                        null
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export const isMarkActive = (editor: any, format: any) => {
    const marks: any = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

export const isGetComments = (editor: any) => {
    const marks: any = Editor.marks(editor)
    console.log(marks)
    return marks && marks['comments']? marks['comments'] : []
}

export const getAction = (editor: any) => {

}
