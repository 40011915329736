import {SlateParagraph, SlateWord, TranscriptData} from "../../models/Transcript";

export enum ActionFilter {
    ALL = 'ALL',
    ADVERTISEMENT = 'ADVERTISEMENT',
    INAUDIBLE_CONTENT = 'INAUDIBLE_CONTENT',
    COMMENTS = 'COMMENTS',
    TEXT_REVIEW = 'TEXT_REVIEW',
}
const getWordsForParagraph = (
    currentParagraph: SlateParagraph,
    words: SlateWord[]
): SlateWord[] => {
    const { start, end } = currentParagraph;
    return words.filter((word) => word.start >= start && word.end <= end);
};

function isEmpty(obj: Object): boolean {
    return Object.keys(obj).length === 0;
}

const generateText = (
    paragraph: SlateParagraph,
    words: SlateWord[]
): string => {
    return words
        .filter(
            (word) => word.start >= paragraph.start && word.end <= paragraph.end
        )
        .map((w) => w.text)
        .join(' ');
};

function generatePreviousTimingsUpToCurrent(start: number): string {
    const startTimeInt = parseInt(start.toString());
    if (start === 0) {
        return '';
    }
    if (start === 1) {
        return '0 1';
    }
    return new Array(startTimeInt)
        .fill(1)
        .map((_, i) => i + 1)
        .join(' ');
}

export const convertDpeToSlate = (transcript: TranscriptData): any[] => {
    if (isEmpty(transcript)) {
        return [
            {
                speaker: 'U_UKN',
                start: 0,
                previousTimings: '0',
                startTimecode: '00:00:00',
                type: 'timedText',
                children: [
                    {
                        text: 'Text',
                        words: [],
                    },
                ],
            },
        ];
    }

    const { words, paragraphs } = transcript;

    return paragraphs.map((paragraph) => ({
        speaker: paragraph.speaker,
        start: paragraph.start,
        previousTimings: generatePreviousTimingsUpToCurrent(paragraph.start),
        type: 'timedText',
        children: [
            {
                text: generateText(paragraph, words),
                words: getWordsForParagraph(paragraph, words),
            },
        ],
    }));
};
