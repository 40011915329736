import {useLibrary} from "../context/LibraryContext";
import {Flex} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {TranscriptCompleteEditor} from "../components/transcript-editors/transcript-complete-page";
import {useState} from "react";
import {TranscriptMode} from "../models/Transcript";
import {TranscriptReviewEditor} from "../components/transcript-editors/review-page";


export default function TranscriptReader({mode}: {mode: TranscriptMode}) {
    const [loading, setLoading] = useState<boolean>(true);
    const library: any = useLibrary()
    const { sessionId, podcastId, episodeId }: any = useParams();


    return (
        <Flex w={'full'} p={'4px'} direction={'column'} gap={'16px'} alignItems={'flex-start'}>
            {mode === TranscriptMode.COMPLETED && <TranscriptCompleteEditor />}
            {mode === TranscriptMode.REVIEWING &&
                <>
                    <Flex w={'full'} direction="row" justifyContent="space-between" py={'3px'}>
                        <ChevronLeftIcon  boxSize={'36px'} onClick={() => {
                            library.setNavBarEpisodeTitle('')
                            library.setOverrideNavBarEpisodeTitle(false)
                            window.history.back()
                        }}/>
                    </Flex>
                    <TranscriptReviewEditor />
                </>
            }
        </Flex>
    );
};
