import 'react-circular-progressbar/dist/styles.css';
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Icon,
    Input,
    InputGroup,
    Select,
    Text,
    Spinner,
    Divider, Avatar, Menu, MenuButton, MenuList, MenuItem, Skeleton, useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {EpisodeSpeakerMeta, PodcastEpisode} from "../../models/PodcastEpisode";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {Speaker} from "../../models/Speakers";
import {LuEdit2} from "react-icons/lu";
import {Podcast} from "../../models/Podcast";
import {MemberRole, TeamMember, TeamMemberInvitation} from "../../models/Team";
import {ChevronDownIcon} from "@chakra-ui/icons";

export const TeamSettings = ({podcast}: {podcast: Podcast}) => {
    return (
        <Flex w={'full'} direction={'column'} alignItems={'flex-start'} gap="24px" alignSelf={'stretch'}>
            <ProductionTeamSettings podcast={podcast}/>
            <MemberSettings podcast={podcast}/>
        </Flex>
    )
}

export const ProductionTeamSettings = ({podcast}: {podcast: Podcast}) => {
    const user: any = useUser()
    const [email, setEmail] = useState<string>('')
    const [role, setRole] = useState<MemberRole>(MemberRole.EDITOR)
    const [sendInviteLoading, setSendInviteLoading] = useState<boolean>(false)
    const [teamFetchLoading, setTeamFetchLoading] = useState<boolean>(true)
    const [sendInvite, setSendInvite] = useState<boolean>(false)
    const toast = useToast()

    const inviteTeamMemberReq = useApi(ENDPOINTS.PUBLISHER_INVITE_TEAM_MEMBER.concat(podcast.id as string), user.token, false)

    useEffect(() => {
        if (sendInvite) {
            setSendInviteLoading(true)
            inviteTeamMemberReq.setPayloadData({
                email: email,
                role: role,
                publisher: user.user
            })
        }
    }, [sendInvite])

    useEffect(() => {
        if (!inviteTeamMemberReq.loading && inviteTeamMemberReq.data) {
            toast({
                title: 'Success',
                description: 'Team member invited',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setEmail('')
            setRole(MemberRole.EDITOR)
            setSendInvite(false)
            setSendInviteLoading(false)
        } else if (!inviteTeamMemberReq.loading && inviteTeamMemberReq.error) {
            toast({
                title: 'Error',
                description: 'Error inviting team member',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            setSendInvite(false)
            setSendInviteLoading(false)
        }
    }, [inviteTeamMemberReq.loading])

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    INVITE
                </Text>
            </Flex>
            <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Flex w={'full'} direction={'row'} gap={'32px'} alignItems={'center'}>
                    {sendInviteLoading ?
                        <Flex w={'full'} justifyContent={'center'} alignItems={'center'}>
                            <Spinner alignSelf={'center'} size={'lg'} color={'teal'} p={'6px'}/>
                        </Flex>
                        :
                        <Flex w={'full'} direction={'row'} px={'0px'} py={'8px'} gap={'16px'} alignItems={'center'}>
                            <FormControl w={'50%'}>
                                <Input value={email} placeholder="Email" size="md" borderRadius="md" padding="2" onChange={(e) => {setEmail(e.target.value)}}/>
                                <FormErrorMessage>Please enter your email</FormErrorMessage>
                            </FormControl>
                            <Select _focus={{borderColor: 'teal.500'}} w={'30%'} placeholder={'Role'} value={role} onChange={(e) => {setRole(e.target.value as MemberRole)}}>
                                <option value={MemberRole.EDITOR}>Editor</option>
                            </Select>
                            <Button w={'20%'} variant={'outline'} onClick={() => {setSendInvite(true)}} borderRadius={'6px'} border={'1px solid'}>
                                Send
                            </Button>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}

export const MemberSettings = ({podcast}: {podcast: Podcast}) => {
    const user: any = useUser()
    const [teamFetchLoading, setTeamFetchLoading] = useState<boolean>(true)
    const [invitations, setInvitations] = useState<TeamMemberInvitation[]>([])
    const [members, setMembers] = useState<TeamMember[]>([])

    const getTeamMemberReq = useApi(ENDPOINTS.PUBLISHED_GET_TEAM_INVITATIONS.concat(podcast.id as string), user.token, true)

    useEffect(() => {
        if (!getTeamMemberReq.loading && getTeamMemberReq.data) {
            setInvitations(getTeamMemberReq.data.invitations)
            setMembers(getTeamMemberReq.data.members)
            setTeamFetchLoading(false)
        }
    }, [getTeamMemberReq.loading])

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    TEAM
                </Text>
            </Flex>
                <Flex width={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                    {!teamFetchLoading && members.length > 0 && members.map((member: TeamMember) => {
                        return (
                            <Flex w={'full'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Flex w={'full'} direction={'row'} alignItems={'center'} gap={'12px'}>
                                    <Avatar boxSize={'32px'} name={member.name}/>
                                    <Flex direction={'column'} alignItems={'flex-start'} gap={'4px'}>
                                        <Text color={'gray.900'} fontSize={'14px'} fontWeight={'500'} lineHeight={'20px'}>{member.name}</Text>
                                        <Text color={'gray.600'} fontSize={'12px'} fontWeight={'400'} lineHeight={'16px'}>{member.email}</Text>
                                    </Flex>
                                </Flex>
                                <Menu>
                                    <MenuButton>
                                        <Flex direction={'row'} alignItems={'center'} gap={'4px'}>
                                            <Text color={'gray.900'} fontSize={'14px'} fontWeight={'500'} lineHeight={'20px'}>
                                                {member.role.charAt(0).toUpperCase() + member.role.slice(1).toLowerCase()}
                                            </Text>
                                            <ChevronDownIcon boxSize={'20px'}/>
                                        </Flex>
                                    </MenuButton>
                                </Menu>
                            </Flex>
                        )
                    })}
                </Flex>
            <Divider width={'full'} borderColor={'gray.300'}/>
            <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                Pending Invitations
            </Text>
            <Flex width={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'space-between'}>
                {teamFetchLoading && <Spinner alignSelf={'center'} size={'lg'} color={'teal'} p={'6px'}/>}
                {!teamFetchLoading && invitations.length === 0 && <Text w={'full'} color="gray.600" align={'start'} fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                    No Pending Invitations.
                </Text>
                }
                {!teamFetchLoading && invitations.length > 0 && invitations.map((invitation: TeamMemberInvitation) => {
                    return (
                    <Flex w={'full'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Flex w={'full'} direction={'row'} alignItems={'center'} gap={'12px'}>
                            <Avatar boxSize={'32px'} name={invitation.email}/>
                            <Flex direction={'column'} alignItems={'flex-start'} gap={'4px'}>
                                <Text color={'gray.600'} fontSize={'12px'} fontWeight={'400'} lineHeight={'16px'}>{invitation.email}</Text>
                            </Flex>
                        </Flex>
                        <Menu>
                            <MenuButton>
                                <Flex direction={'row'} alignItems={'center'} gap={'4px'}>
                                    <Text color={'gray.900'} fontSize={'14px'} fontWeight={'500'} lineHeight={'20px'}>
                                        {invitation.role.charAt(0).toUpperCase() + invitation.role.slice(1).toLowerCase()}
                                    </Text>
                                    <ChevronDownIcon boxSize={'20px'}/>
                                </Flex>
                            </MenuButton>
                        </Menu>
                    </Flex>
                    )
                })}
            </Flex>
        </Flex>
    )
}
