const LOCAL_STORAGE_ACCESS_TOKEN = 'AUTH_TOKEN'

const getLocaleStorageToken = async () => {
    const parse = await localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
    return parse;
}

const saveLocaleStorageToken = (token: string) => {
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, token);

}

const deleteLocaleStorageToken = () => {
    return localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
}

export const StorageUtils = {
    getLocaleStorageToken,
    saveLocaleStorageToken,
    deleteLocaleStorageToken
}
