import {Flex, Icon} from "@chakra-ui/react";

export const AppLogo = () => {

    return (
        <Flex>
            <Icon xmlns="http://www.w3.org/2000/svg" width="16px" height="22px" viewBox="0 0 210 232" fill="none">
                <path
                    d="M12 102.989L64.3669 129.755C65.0437 130.1 65.7879 130.188 66.5183 129.977C74.8279 127.586 128.033 112.185 148.264 104.118M148.264 104.118C209.635 84.3916 198.009 -5.20694 139.022 14.9278L66.6647 42.5353C65.8723 42.8376 64.9889 42.7904 64.2332 42.4053L12 15.7885M148.264 104.118C202.669 86.684 225.378 172.191 148.601 194.272C128.286 202.247 74.772 217.407 66.613 219.705C65.9116 219.903 65.2013 219.826 64.5444 219.51L12 194.272"
                    stroke="#319795" stroke-width="22.5" stroke-linecap="round" stroke-linejoin="round"/>
            </Icon>
        </Flex>
    )
}
