import {
    PodcastTaskConfig,
} from "./Localization";
import {MediaContentTaskConfig} from "./MediaContent";

export type PodcastCartItem = PodcastTaskConfig & {
    status?: PaymentStatus
}

export type  MediaContentCartItem = MediaContentTaskConfig & {
    status?: PaymentStatus
}

export type BraincapCartItem = PodcastCartItem | MediaContentCartItem
export enum PaymentStatus {
    IN_CART = "IN_CART",
    CHECKED_OUT = "CHECKED_OUT",
    PAYMENT_COLLECTION_CREATION_FAILED = "PAYMENT_COLLECTION_CREATION_FAILED",
}
