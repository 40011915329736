import {Icon} from "@chakra-ui/react";
import React from "react";
export const EpisodesIcon = (props: any) => {
    return (
        <Icon color={'gray.600'} width="20px" height="14px" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.6092 0.5C25.825 0.5 26 0.658665 26 0.854086V11.4791C26 11.6748 25.825 11.8334 25.6092 11.8334H22.8749V14.3125C22.8749 14.5082 22.6999 14.6666 22.4844 14.6666H19.7498V17.1459C19.7498 17.3413 19.5751 17.5 19.3593 17.5H1.3908C1.17499 17.5 1 17.3413 1 17.1459V6.52091C1 6.32525 1.175 6.16659 1.3908 6.16659H4.12508V3.6875C4.12508 3.49184 4.30008 3.33342 4.51561 3.33342H7.25016V0.854086C7.25016 0.658659 7.4249 0.5 7.6407 0.5H25.6092ZM18.1876 7.58354H2.5628V16.0835H18.1876V7.58354ZM21.3126 4.75012H5.68722V6.16671H19.3593C19.5751 6.16671 19.7498 6.32537 19.7498 6.52103V13.25H21.3125L21.3126 4.75012ZM24.4375 1.91671H8.81271V3.33354H22.4848C22.7003 3.33354 22.8753 3.49196 22.8753 3.68762V10.4166H24.4377L24.4375 1.91671Z" fill="#2D3748" stroke="white" strokeWidth="0.2"/>
        </Icon>

    )
}
