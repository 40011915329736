import {Button, Flex, Input, Text,} from '@chakra-ui/react';
import {AppLogo} from "../../assets/AppLogo";
import React, {useEffect, useRef, useState} from "react"
import {Link} from "react-router-dom";
import {dbAuth, dbSignInWithPhoneNumber} from "../../utils/firebase";
import {multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier} from "firebase/auth";
import {useUser} from "../../context/UserContext";
import LoadingSpinner from "../../components/loading-spinner";

export default function PinVerification() {
    const user: any = useUser()
    const [loading, setLoading] = useState(true);
    const [pin, setPin] = useState(Array(6).fill(''))
    const [pinSent, setPinSent] = useState(false)
    const [pinError, setPinError] = useState(false)
    const [pinErrorMessage, setPinErrorMessage] = useState('')
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('')

    const inputsRef: any = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if (inputsRef.current[activeIndex]) {
            inputsRef.current[activeIndex].focus();
        }
    }, [activeIndex]);

    useEffect(() => {
        if (!user.loading && user.user) {
            console.log(`phone number: ${user.user.phone}`)
            setPhoneNumber(user.user.phone)
            setLoading(false);
        } else {
            console.log('No User selected');
        }
    }, [user.loading])

    const handleOnChange = (e: any, index: any) => {
        if (!e.target.value) return;
        if (!/^[0-9]+$/.test(e.target.value)) {
            setPinError(true);
            return;
        }
        setPinError(false);
        setPin([...pin.slice(0, index), e.target.value, ...pin.slice(index + 1)]);
        setActiveIndex(index + 1);
    };

    const handleKeyDown = (e: any, index: any) => {
        if (e.key !== "Backspace") return;
        if (pin[index]) {
            setPin([...pin.slice(0, index), '', ...pin.slice(index + 1)]);
        }
        setActiveIndex(index > 0 ? index - 1 : 0);
    };

    const PhoneInput = () => {
        const sendCode = async () => {
            {/**
             console.log(dbAuth)
             const recaptchaVerifier: any = await new RecaptchaVerifier('recaptcha-container', { size: 'invisible' }, dbAuth);
             multiFactor(user).getSession()
             .then(function (multiFactorSession) {
                    // Specify the phone number and pass the MFA session.
                    const phoneInfoOptions = {
                        phoneNumber: phoneNumber,
                        session: multiFactorSession
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(dbAuth);

                    // Send SMS verification code.
                    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
                }).then(function (verificationId) {
                // Ask user for the verification code. Then:
                //const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
                //const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

                // Complete enrollment.
                return multiFactor(user).enroll(multiFactorAssertion, mfaDisplayName);
            });

             try {
                const confirmation: any = await dbSignInWithPhoneNumber(phoneNumber, appVerifier);
                console.log(confirmation)
                setConfirmationResult(confirmation);
                setPinSent(true);
            } catch (error) {
                console.error("Error during sign in: ", error);
            }
            */}
        }

        const handlePhoneNumberChange = (e: any) => {
            setPhoneNumber(e.target.value);
        }

        return (
            <Flex h="40px" justifyContent="center" alignItems="flex-start" alignSelf="stretch" borderRadius="6px" gap={'12px'}>
                <Input
                    w="358px"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    alignSelf="stretch"
                    borderRadius="6px"
                    borderColor="gray.200"
                    bg="white" />
                <Button id='send-code-button' onClick={sendCode} variant={'outline'} p="10px 16px" justifyContent="center" alignItems="center" alignSelf="stretch" borderRadius="6px" borderColor="teal.500" bg="white">
                    <Text color="teal.500" fontSize="16px" fontFamily="sans-serif" fontWeight="600" lineHeight="24px">
                        Send Code
                    </Text>
                </Button>
                <div id="recaptcha-container"></div> {/* This div will hold the recaptcha */}
            </Flex>
        )
    }
    const PinInput = () => {
        return (
            <Flex flexDirection="column" justifyContent="center" alignItems="center" alignSelf="stretch" gap={'36px'}>
                <Flex p="16px" flexDirection="column" justifyContent="center" alignItems="flex-start" gap="16px" alignSelf="stretch" border={'1px'} borderRadius="8px"
                      borderColor={pinError? "red.500" : "gray.200"}>
                    <Text color="gray.600" fontSize="16px" fontWeight="400" lineHeight="24px">
                        Enter 6 Digits Code
                    </Text>
                    <Flex justifyContent="center" alignItems="center" alignSelf="stretch" gap={'24px'}>
                        {Array(6).fill('').map((_, index) => (
                            <Input
                                ref={ref => inputsRef.current[index] = ref}
                                value={pin[index]}
                                onChange={e => handleOnChange(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                maxLength={1}
                                w="42px"
                                h="42px"
                                p="8px 16px"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="4px"
                                borderColor={pinError? "red.500" : "gray.200"}
                                bg="#FFF"/>
                        ))}
                    </Flex>
                </Flex>
                {pinError && <Text alignSelf={'stretch'} color="red.500" fontSize="14px" fontWeight="400" lineHeight="20px">Each input must be a digit</Text>}
                <Button isDisabled={pin.join('').length < 6} h="40px" p="10px 16px" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch" borderRadius="6px" bg="teal.500" variant="solid" size="md">
                    <Text color="white" fontSize="16px" fontWeight="600" lineHeight="24px">
                        Verify
                    </Text>
                </Button>
            </Flex>
        )
    }

    return (
        <Flex direction="column" align="center" justify="center" minHeight="100vh" backgroundColor="gray.100">
            <Flex align="center" justify="start" position="absolute" top="0" left="0" width="1280px" height="100px" paddingLeft="8" paddingRight="1117px" paddingTop="8" paddingBottom="6">
                <Flex alignItems="center" justify="center" gap="2" paddingRight="3">
                    <Link to={'/entry'}>
                        <AppLogo/>
                    </Link>
                    <Text color="teal.500" fontSize="20px" fontWeight="medium">Braincap</Text>
                    <Flex p="2px 8px" justifyContent="center" alignItems="center">
                        {/* Your Content */}
                    </Flex>
                </Flex>
            </Flex>
            {loading?
                <LoadingSpinner/> :

                <Flex w="535px" p="24px" flexDirection="column" justifyContent="center" alignItems="center" gap="36px" borderRadius="12px" bg="white">
                    <Text textAlign="center" color="gray.700" fontSize="20px" fontWeight="500" lineHeight="7" isTruncated>
                        Two-Factor Authentication
                    </Text>
                    {!pinSent && <PhoneInput/>}
                    {pinSent && <PinInput/>}
                    <Flex flexDirection="column" alignItems="center" gap="8px">
                        {pinSent && <Text color="gray.500" textAlign="center" fontSize="14px" fontWeight="400" lineHeight="20px">Didn’t receive it ?</Text>}
                        {pinSent && <Text color="teal.500" textAlign="center" fontSize="14px" fontWeight="600" lineHeight="20px">Send Again</Text>}
                        <Text color="gray.500" textAlign="center" fontSize="14px" fontWeight="400" lineHeight="20px" textDecoration="underline">
                            Or Skip two factor authentication
                        </Text>
                    </Flex>
                </Flex>
            }
        </Flex>
)
}
