import {ReactEditor} from "slate-react";
import {CustomText, Text as SlateText, Transforms} from "slate";

const handleTimeUpdate = (e: any, lastHighlightedWordIndex: any, setLastHighlightedWordIndex: any, editor: any, value: any) => {
    const currentTime = e; // Convert to milliseconds

    // Find the word that matches the current playback time
    const val = findWordByTime(Math.round(currentTime), value)
    const wordToHighlight = val?.wordToHighlight;
    const node = val?.node;

    if (wordToHighlight) {
        if (lastHighlightedWordIndex !== null) {
            // Highlight all words between the last highlighted word and the current word
            for (let i = lastHighlightedWordIndex + 1; i <= wordToHighlight.id; i++) {
                const word = findWordById(i, value);
                if (word && !word.highlight) {
                    highlightWord(word, node, editor);
                }
            }
        } else {
            highlightWord(wordToHighlight, node, editor);
        }
        setLastHighlightedWordIndex(wordToHighlight.id);
    }
};

/**
 * This function uses binary search to find a word in the transcript based on a given timestamp.
 *
 * @param {number} time - The current timestamp (in milliseconds) from the audio player.
 * @param {Array} wordsArray - The array of words from the transcript.
 *
 * @returns {Object|null} - Returns the word object that matches the given timestamp or null if no word is found.
 */
const findWordByTimeBinarySearch = (time: any, wordsArray: any) => {
    const BUFFER = 5;  // 5 milliseconds buffer

    let low = 0;
    let high = wordsArray.length - 1;

    while (low <= high) {
        const mid = Math.floor((low + high) / 2);
        const word = wordsArray[mid];

        if (time >= word.start - BUFFER && time <= word.end + BUFFER) {
            return word;
        } else if (time < word.start - BUFFER) {
            high = mid - 1;
        } else {
            low = mid + 1;
        }
    }

    // If we're here, it means the time is in between two words.
    // Return the earlier word if `high` is within bounds.
    if (high >= 0) {
        return wordsArray[high];
    }

    return null;
};

const findWordById = (id: number, value: any) => {
    for (let node of value) {
        if (node.children && node.children[0].words) {
            const word = node.children[0].words.find((w: any) => w.id === id);
            if (word) {
                return word;
            }
        }
    }
    return null;
};

const findWordByTime = (time: any, value: any) => {
    for (let node of value) {
        if (node.children && node.children[0].words) {
            const wordObj = findWordByTimeBinarySearch(time, node.children[0].words);
            if (wordObj) {
                return {wordToHighlight: wordObj, node: node}
            }
        }
    }
    return null;
};

const findWordByIteratingThroughSpans = (time: any) => {
    //get all spans with data-start and data-end attributes
    const attributeName = 'data-word-start'; // Replace with your specific attribute name
    const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
    console.log('elements:', elements.length)
    for (let span of elements) {
        const start = Number(span.getAttribute('data-word-start'));
        const end = Number(span.getAttribute('data-word-end'));
        const id = Number(span.getAttribute('data-word-id'));
        if (start && end && time >= start && time <= end) {
            console.log('Highlighting id:', span.getAttribute('data-word-id'))
            span.setAttribute('style', 'background-color: yellow;')
        }
    }
}

const findWordByBinarySearch = (time: any) => {
    const BUFFER = 5;  // 5 milliseconds buffer
    const attributeName = 'data-word-start'; // Replace with your specific attribute name
    const elements = Array.from(document.querySelectorAll(`[${attributeName}]`));
    let currentHighlightedWordId = null;

    let left = 0;
    let right = elements.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const span = elements[mid];
        const start = Number(span.getAttribute('data-word-start'));
        const end = Number(span.getAttribute('data-word-end'));
        const id = Number(span.getAttribute('data-word-id'));

        if (time >= start - BUFFER && time <= end + BUFFER) {
            // Highlight the matching span
            span.setAttribute('style', 'background-color: teal;');
            for (let i = mid - 1; i >= 0; i--) {
                elements[i].setAttribute('style', 'background-color: transparent;');
                //remove highlight from previous spans
            }
            return;
        } else if (time < start - BUFFER) {
            right = mid - 1;
        } else {
            left = mid + 1;
        }
    }

    if (right >= 0) {
        return elements[right];
    }
    return null;
};



const highlightWord = (wordObj: any, node: any, editor: any) => {
    console.log("Trying to highlight node:", node);
    const pathToCurrentNode = ReactEditor.findPath(editor, node);
    console.log("Path to current node:", pathToCurrentNode);

    Transforms.setNodes(
        editor,
        { highlight: true } as Partial<CustomText>,
        {
            at: pathToCurrentNode,
            match: n => SlateText.isText(n) && n.text === wordObj.text,
        }
    );
};

export const filterActionItems = (transcript: any): any => {
    let actions: any[] = [];

    for (const node of transcript) {
        const children = Object.values(node.children) as any[];

        for (let i = 0; i < children.length; i++) {
            const child = children[i];

            // Processing advertisement
            if (child.isAction && child.isAction !== false) {
                actions.push(child)
            }
        }
    }

    return actions;
}
