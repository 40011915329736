import {
    Flex,
    Box,
    Stack,
    Button,
    useColorModeValue,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Text,
    Checkbox,
    useToast, InputGroup, InputRightElement, Select, Spacer, InputLeftAddon, FormErrorMessage, Spinner
} from '@chakra-ui/react';
import {useEffect, useState} from "react";
import {GoogleAuthProvider, signInWithPopup,} from "firebase/auth";
import React from "react";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {StorageUtils} from "../../utils/storage-utils";
import {AppLogo} from "../../assets/AppLogo";
import {ViewOffIcon} from "@chakra-ui/icons";
import ISO6391 from 'iso-639-1';
import {Link, useParams} from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import 'react-phone-number-input/style.css'
import {getCountryCallingCode, parsePhoneNumberFromString} from "libphonenumber-js";
import countriesISO from 'i18n-iso-countries';
import {UserRegistrationForm} from "../../models/Users";
import {dbAuth} from "../../utils/firebase";
import {FcGoogle} from "react-icons/fc";
countriesISO.registerLocale(require("i18n-iso-countries/langs/en.json")); // Register English locale


function Signup() {
    const [loading, setLoading] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [country, setCountry] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [selectError, setSelectError] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const { category, is_invite, podcastId, invitationId, google } = useParams();
    const [userId, setUserId] = useState('')
    const [hasSignedUpWithGoogle, setHasSignedUpWithGoogle] = useState(false)

    useEffect(() => {
        if (category && is_invite === 'true') {
            if (podcastId && invitationId) {
                console.log(`category: ${category}, podcastId: ${podcastId}, invitationId: ${invitationId}`)
            }
        }
        if (google === 'true') {
            setHasSignedUpWithGoogle(true)
        }
    }, [])

    const toast = useToast();

    const signupReq = useApi(ENDPOINTS.USER_SIGNUP, '', false)
    const signUpWithGoogleReq = useApi(ENDPOINTS.USER_SIGNUP_GOOGLE.concat(userId), '', false)

    const validateEmail = (email: string): boolean => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    //validate minimum 8 characters password with at least a symbol, upper and lower case letters and a number
    const validatePassword = (password: string): boolean => {
        const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(password);
    }

    //validate phone number to make sure it is a valid phone number and has a country code
    const validatePhoneNumber = (phoneNumber: string): boolean => {
        const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
        if (phoneNumberObj) {
            return phoneNumberObj.isValid();
        }
        return false;
    }

    const validateFields = (): boolean => {
        let isValid = true;
        if (first_name === '') {
            setFirstNameError(true);
            isValid = false;
        } else {
            setFirstNameError(false);
        }
        if (last_name === '') {
            setLastNameError(true);
            isValid = false;
        } else {
            setLastNameError(false);
        }
        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneNumberError(true);
            isValid = false;
        } else {
            setPhoneNumberError(false);
        }
        if (!validateEmail(email)) {
            setEmailError(true);
            isValid = false;
        } else {
            setEmailError(false);
        }
        if (!validatePassword(password)) {
            setPasswordError(true);
            isValid = false;
        } else {
            setPasswordError(false);
        }
        if (!validatePassword(password_confirmation)) {
            setConfirmPasswordError(true);
            isValid = false;
        } else {
            setConfirmPasswordError(false);
        }
        if (!termsAccepted) {
            setTermsError(true);
            isValid = false;
        } else {
            setTermsError(false);
        }

        if (password !== password_confirmation) {
            setConfirmPasswordError(true);
            isValid = false;
        }
        return isValid;
    }

    const validateFieldsForGoogle = (): boolean => {
        let isValid = true;
        if (first_name === '') {
            setFirstNameError(true);
            isValid = false;
        } else {
            setFirstNameError(false);
        }
        if (last_name === '') {
            setLastNameError(true);
            isValid = false;
        } else {
            setLastNameError(false);
        }
        if (!validatePhoneNumber(phoneNumber)) {
            setPhoneNumberError(true);
            isValid = false;
        } else {
            setPhoneNumberError(false);
        }

        if (!termsAccepted) {
            setTermsError(true);
            isValid = false;
        } else {
            setTermsError(false);
        }
        return isValid;
    }
    const signUpUser = () => {
        if (!validateFields()) {
            toast({
                title: "Error",
                description: "Please fill in all the required fields",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            return
        } else {
            setLoading(true)
            if (is_invite === 'true') {
                if (podcastId && invitationId) {
                    signupReq.setPayloadData({
                        first_name: first_name,
                        last_name: last_name,
                        category: category,
                        phone: phoneNumber,
                        email: email,
                        password: password,
                        from_invitation: true,
                        podcastId: podcastId,
                        invitationId: invitationId
                    } as UserRegistrationForm)
                }
            } else {
                signupReq.setPayloadData({
                    first_name: first_name,
                    last_name: last_name,
                    category: category,
                    phone: phoneNumber,
                    email: email,
                    password: password,
                    from_invitation: false,
                } as UserRegistrationForm)
            }
        }
    };

    const provider = new GoogleAuthProvider();

    const signInWithGoogle = async () => {
        try {
            if (!hasSignedUpWithGoogle) {
                const result = await signInWithPopup(dbAuth, provider);
                toast({
                    title: "Success",
                    description: "Account created successfully.Make sure to fill in the required fields",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                setHasSignedUpWithGoogle(true)
                result.user.displayName? setFirstName(result.user.displayName.split(' ')[0]) : setFirstNameError(true);
                result.user.displayName? setLastName(result.user.displayName.split(' ')[1]) : setLastNameError(true);
                result.user.phoneNumber? setPhoneNumber(result.user.phoneNumber) : setPhoneNumberError(true);
                result.user.email? setEmail(result.user.email) : setEmailError(true);
                setUserId(result.user.uid)
            } else {
                if (!validateFieldsForGoogle()) {
                    toast({
                        title: "Error",
                        description: "Please fill in all the required fields",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    })
                    return
                } else {
                    setLoading(true)
                    if (is_invite === 'true') {
                        if (podcastId && invitationId) {
                            signUpWithGoogleReq.setPayloadData({
                                first_name: first_name,
                                last_name: last_name,
                                category: category,
                                phone: phoneNumber,
                                email: email,
                                from_invitation: true,
                                podcastId: podcastId,
                                invitationId: invitationId
                            } as UserRegistrationForm)
                        }
                    }
                    else {
                        signUpWithGoogleReq.setPayloadData({
                            first_name: first_name,
                            last_name: last_name,
                            category: category,
                            phone: phoneNumber,
                            email: email,
                            from_invitation: false,
                        } as UserRegistrationForm)
                    }
                }
            }
        } catch (error: any) {
            console.log(error.message);
            toast({
                title: "Error",
                description: error.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            signUpUser();
        }
    }

    useEffect(() => {
        if (!signUpWithGoogleReq.loading && signUpWithGoogleReq.data) {
            console.log(`signUpWithGoogleReq.data: ${JSON.stringify(signUpWithGoogleReq.data)}`)
            setLoading(false)
            toast({
                title: "Success",
                description: "Account created successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            setLoading(false)
            window.location.href = "/";
        }

        if (!signUpWithGoogleReq.loading && signUpWithGoogleReq.error) {
            console.log(`signUpWithGoogleReq.error: ${JSON.stringify(signUpWithGoogleReq.error)}`)
            if (signUpWithGoogleReq.error.data.code === "auth/email-already-exists") {
                toast({
                    title: "Error",
                    description: "Email already exists. Please try again with a different email address",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            if (signUpWithGoogleReq.error.data.code === "auth/phone-number-already-exists") {
                toast({
                    title: "Error",
                    description: "Phone number already exists. Please try again with a different phone number",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            setLoading(false)
        }
    }, [signUpWithGoogleReq.loading])

    useEffect(() => {
        if (!signupReq.loading && signupReq.data) {
            console.log(`signupReq.data: ${JSON.stringify(signupReq.data)}`)
            setLoading(false)
            toast({
                title: "Success",
                description: "Account created successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            StorageUtils.saveLocaleStorageToken(signupReq.data.refreshToken)
            setLoading(false)
            window.location.href = "/";
        }

        if (!signupReq.loading && signupReq.error) {
            console.log(`signupReq.error: ${JSON.stringify(signupReq.error)}`)
            if (signupReq.error.data.code === "auth/email-already-exists") {
                toast({
                    title: "Error",
                    description: "Email already exists. Please try again with a different email address",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            if (signupReq.error.data.code === "auth/phone-number-already-exists") {
                toast({
                    title: "Error",
                    description: "Phone number already exists. Please try again with a different phone number",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            setLoading(false)
        }
    }, [signupReq.loading])

    return (
        <Flex direction="column" align="center" justify="center" minHeight="100vh" backgroundColor="white">
            <Flex align="center" justify="start" position="absolute" top="0" left="0" width="1280px" height="100px" paddingLeft="8" paddingRight="1117px" paddingTop="8" paddingBottom="6">
                <Flex alignItems="center" justify="center" gap="2" paddingRight="3">
                    <Text color="teal.500" fontSize="20px" fontWeight="medium">Braincap</Text>
                </Flex>
            </Flex>
            <Flex direction="column" p={'24px'} gap={'36px'} align="center" justify="center" backgroundColor="white" borderRadius="12px" border="1px" borderColor={'gray.300'}>
                <Text fontSize="24px" fontWeight="semibold" marginBottom="6">Sign up</Text>
                <Flex direction="column" align="center" justify="center" width="487px" gap="6">
                    <FormControl isRequired isInvalid={firstNameError}>
                        <Input
                            placeholder="First Name"
                            value={first_name}
                            onChange={(e) => {setFirstName(e.target.value)}}
                            size="md"
                            borderRadius="md"
                            padding="2"
                        />
                        <FormErrorMessage>Please enter your first name</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={lastNameError}>
                        <Input
                            placeholder="Last Name"
                            value={last_name}
                            onChange={(e) => {setLastName(e.target.value)}}
                            size="md"
                            borderRadius="md"
                            padding="2"
                        />
                        <FormErrorMessage>Please enter your last name</FormErrorMessage>
                    </FormControl>

                    <FormControl isRequired isInvalid={phoneNumberError}>
                        <Input type="tel" size="md" padding="2" placeholder="Phone Number" value={phoneNumber} onChange={(e) => {setPhoneNumber(e.target.value)}} />
                        <FormErrorMessage>Please enter a valid phone number with country code (e.g. +1 1234567890)</FormErrorMessage>
                    </FormControl>

                    {!hasSignedUpWithGoogle?
                        <>
                            <FormControl isRequired isInvalid={emailError}>
                                <Input
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => {setEmail(e.target.value)}}
                                    size="md"
                                    borderRadius="md"
                                    padding="2"
                                    width="487px"
                                />
                                <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
                            </FormControl>

                            <FormControl isRequired isInvalid={passwordError}>
                                <InputGroup size="md" width="487px">
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        value= {password}
                                        onChange={(e) => {setPassword(e.target.value)}}
                                        placeholder="Create Password (at least 8 characters)"
                                        borderRadius="md"
                                        padding="2"
                                    />
                                    <InputRightElement width="4.5rem">
                                        <ViewOffIcon onClick={() => {
                                            if (showPassword) {
                                                setShowPassword(false)
                                            } else {
                                                setShowPassword(true)
                                            }
                                        }} color={"gray.500"} />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>Create Password (Minimum 8 characters with at least a symbol, upper and lower case letters and a number)</FormErrorMessage>
                            </FormControl>

                            <FormControl isRequired isInvalid={confirmPasswordError}>
                                <InputGroup size="md" width="487px">
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        value={password_confirmation}
                                        onChange={(e) => {setPasswordConfirmation(e.target.value)}}
                                        borderRadius="md"
                                        padding="2"
                                    />
                                    <InputRightElement width="4.5rem">
                                        <ViewOffIcon color={"gray.500"} />
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>The passwords don't match</FormErrorMessage>
                            </FormControl>
                        </>
                        :
                        null
                    }

                    <FormControl isRequired isInvalid={termsError}>
                        <Flex align="center" width="487px">
                            <Checkbox colorScheme="teal" borderColor="slate.500" marginRight="3" checked={termsAccepted} onChange={(e) => {
                                console.log(e.target.checked)
                                setTermsAccepted(e.target.checked)
                            }} />
                            <Text fontSize="16px">
                                I agree with{" "}
                                <Text as="span" color="teal.500" fontWeight="semibold">
                                    Terms
                                </Text>{" "}
                                and{" "}
                                <Text as="span" color="teal.500" fontWeight="semibold">
                                    Privacy
                                </Text>
                            </Text>
                        </Flex>
                        <FormErrorMessage>You must agree with the Terms and Privacy to Sign up</FormErrorMessage>
                    </FormControl>
                    {!hasSignedUpWithGoogle && <Button disabled={loading} w={'full'} variant={'solid'} borderRadius="md" paddingY="2.5" width="487px" fontSize="16px" onClick={signUpUser}>
                        {loading ? <Spinner size={'md'} color={'white'}/> : 'Sign up'}
                    </Button>
                    }
                    {loading ?
                        <Spinner size={'md'} color={'teal.500'}/>
                        :
                        <>
                            {category !== 'EDITOR'&&
                                <Button w="full" onClick={signInWithGoogle} variant="googleSignIn" borderRadius="md" py="2.5" width="487px" fontSize="16px" leftIcon={<FcGoogle/>}>
                                    {hasSignedUpWithGoogle && !loading? 'Continue' : 'Sign up with Google'}
                                </Button>
                            }
                        </>
                    }
                    <Flex direction={'column'} gap={'8px'} alignItems={'center'}>
                        <Text textAlign={'center'} color="gray.500" fontSize="14px" fontWeight={400} lineHeight={'20px'}>Already have an account ?</Text>
                        <Link to={'/'}>
                            <Text textAlign={'center'} color="teal.500" fontSize="14px" fontWeight={600} lineHeight={'20px'}>Log in</Text>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default Signup
