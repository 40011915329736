import {Tab as ChakraTab} from '@chakra-ui/react';

// Custom Tab component
export const Tab = (props: any) => (
    <ChakraTab
        p={'8px 16px'}
        color="gray.600"
        fontSize="16px"
        fontWeight="400"
        lineHeight="24px"
        _selected={{
            color: "teal.500",
            fontWeight: "600"
        }}
        {...props}  // to ensure any passed props override the default styles
    >
        {props.children}
    </ChakraTab>
);

