import {getNotificationName, NotificationSettings} from "../../models/BraincapNotification";
import {Flex, Switch, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {useUser} from "../../context/UserContext";

export const NotificationComponent = ({notification}: {notification: NotificationSettings}) => {
    const [enabled, setEnabled] = useState<boolean>(notification.enabled)
    const user: any = useUser()

    const handleSwitchChange = (e: any) => {
        let currentMap = user.notifications
        if (e.target.checked) {
            currentMap = {
                ...currentMap,
                [notification.name]: {...notification, enabled: true}
            }
        } else {
            currentMap = {
                ...currentMap,
                [notification.name]: {...notification, enabled: false}
            }
        }
        setEnabled(e.target.checked)
        user.setNotifications(currentMap)
        user.setSaveNotifications(true)
    }

    return (
        <Flex w={'full'} gap="16px" flexDirection="row" alignItems="center">
            <Switch colorScheme="teal" size="md" onChange={handleSwitchChange} isChecked={enabled} />
            <Flex w={'full'} direction={'column'} gap={'4px'} alignItems={'flex-start'}>
                <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="20px">
                    {getNotificationName(notification.type)}
                </Text>
                <Text color="gray.900" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                    {notification.description}
                </Text>
            </Flex>
        </Flex>
    )
}
