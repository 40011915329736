import {Podcast} from "../../models/Podcast";
import {
    Flex,
    Skeleton,
    useDisclosure,
    Image,
    Text,
    Tooltip,
    Menu,
    MenuButton,
    MenuList,
    MenuItem, useToast, Divider
} from "@chakra-ui/react";
import EpisodeDashboardPanel from "../episode/episode-dashboard-panel";
import React, {useEffect, useState} from "react";
import PodcastDashboardPanel from "./podcast-dashboard-panel";
import {useUser} from "../../context/UserContext";
import 'react-circular-progressbar/dist/styles.css';
import {Link} from "react-router-dom";
import {useLibrary} from "../../context/LibraryContext";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

export const PodcastCard = ({podcast}: {podcast: Podcast}) => {
    const user: any = useUser()
    const library: any = useLibrary()
    const [episodeLoading, setEpisodeLoading] = useState<boolean>(true)
    const [hasEpisodes, setHasEpisodes] = useState<boolean>(false)
    const [languagesProgress, setLanguagesProgress] = useState<any>({})
    const [episodeTranscriptionProgress, setEpisodeTranscriptionProgress] = useState<any>({})
    const [currentLanguage, setCurrentLanguage] = useState<string>()

    const toast: any = useToast()

    const episodesProgressReq = useApi(ENDPOINTS.PUBLISHER_GET_EPISODES_PROGRESS, user.token, false)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleLanguageChange = () => {
        if (Object.keys(languagesProgress).length === 0) {
            toast({
                title: "No languages available",
                description: "You have not yet localized an episode for this podcast",
                status: "warning",
                duration: 3000,
            })
        }
    }

    useEffect(() => {
        if (!episodesProgressReq.loading && episodesProgressReq.data) {
            setLanguagesProgress(episodesProgressReq.data.progress)
            setEpisodeTranscriptionProgress(episodesProgressReq.data.transcriptionProgress)
            setEpisodeLoading(false)
        }
    }, [episodesProgressReq.loading])

    useEffect(() => {
        if (library.episodesDownloaded && !library.episodesLoading) {
            let episodes = library.podcastEpisodes.get(podcast.id)
            episodesProgressReq.setPayloadData({
                episodes: episodes
            })
        }

    }, [library.episodesDownloaded, library.episodesLoading, library.languagesProgress, library.episodeTranscriptionProgress])

    const styles = buildStyles({
        pathColor: '#38B2AC',
        textColor: '#2D3748',
        trailColor: '#E2E8F0',
    })

    return (
        <Flex w={'full'} p="12px" flexDirection="column" alignItems="center" justifyContent={'flex-start'} bgColor={'#fff'} borderRadius="12px" gap={'8px'} boxShadow={'lg'}>
            <Flex w={'full'} flexDirection="row" alignItems="center" justifyContent="flex-start" gap="6px">
                <Link to={`/${podcast.id}/episodes`} key={podcast.id}>
                    <Image w={'225px'} fallbackSrc='https://via.placeholder.com/150' src={podcast.image_url} borderRadius="8px" bgColor="gray.100"/>
                </Link>
                <Flex mt={'2px'} h={'full'} direction={'column'} alignItems={'flex-start'} justifyContent={'space-between'} gap={'8px'}>
                    <Link to={`/${podcast.id}/episodes`} key={podcast.id}>
                        <Text color="gray.800" textAlign="left" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            {podcast.title}
                        </Text>
                    </Link>
                    <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                        <Tooltip label="Episodes" aria-label="Episodes">
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.500" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="16px">
                                    {podcast.episodes.length} Episodes
                                </Text>
                            </Flex>
                        </Tooltip>
                    </Flex>
                    <Divider/>
                    <Flex p="16px" flexDirection="column" alignSelf="stretch" alignItems="center" justifyContent="center" gap="36px">
                        <Flex w={'full'} gap="120px" flexDirection="row" alignSelf="stretch" justifyContent="center" alignItems="center">
                            {!episodeLoading  && <Flex boxSize={'92px'} direction="column" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch">
                                <CircularProgressbar
                                    value={episodeTranscriptionProgress && episodeTranscriptionProgress['completed'] ? episodeTranscriptionProgress['completed'] : 0}
                                    text={`${episodeTranscriptionProgress && episodeTranscriptionProgress['completed'] ? episodeTranscriptionProgress['completed'] : 0}%`}
                                    strokeWidth={6}
                                    styles={styles}
                                />
                                <Text textAlign={'center'} color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="20px">
                                    Transcription
                                </Text>
                            </Flex>
                            }
                            {!episodeLoading  && <Flex boxSize={'92px'} direction="column" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch">
                                <CircularProgressbar
                                    value={currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}
                                    text={`${currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}%`}
                                    strokeWidth={6}
                                    styles={styles}
                                />
                                <Text textAlign={'center'} color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="20px">
                                    Translation
                                </Text>
                            </Flex>
                            }
                            {!episodeLoading && <Flex boxSize={'92px'} direction="column" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch">
                                <CircularProgressbar
                                    value={currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}
                                    text={`${currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}%`}
                                    strokeWidth={6}
                                    styles={styles}
                                />
                                <Text textAlign={'center'} color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="20px">
                                    Dubbing
                                </Text>
                            </Flex>
                            }
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>
            <PodcastDashboardPanel podcast={podcast} isOpen={isOpen} onOpen={onOpen} onClose={onClose}/>
        </Flex>
    )
}
