import {Drawer, DrawerContent, DrawerOverlay} from "@chakra-ui/modal";
import {
    CloseButton,
    Flex,
    useDisclosure,
    Text,
    Icon,
    Tabs,
    TabList,
    TabIndicator,
    TabPanels,
    TabPanel, Skeleton
} from "@chakra-ui/react";
import {useAuth} from "../../context/AuthContext";
import React, {useEffect, useState} from "react";
import {GrMore} from "react-icons/gr";
import {Tab} from "../ui-utils";
import 'react-circular-progressbar/dist/styles.css';
import PodcastModule from "./podcast-module";
import {Podcast} from "../../models/Podcast";
import {MemberSettings, ProductionTeamSettings, TeamSettings} from "../settings/team-settings";
import {SubscriptionSettings} from "../settings/subscription-settings";
import {PublisherNotificationSettings} from "../settings/publisher-notification-settings";
import {IntegrationModule} from "../integrations/integration-module";

export default function PodcastDashboardPanel({podcast, isOpen, onOpen, onClose}: {podcast: Podcast, isOpen: boolean, onOpen: any, onClose: any}) {
    const auth: any = useAuth()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const handleTabsChange = (index: number) => {
        setTabIndex(index)
    }

    return (
        <Drawer isOpen={isOpen} onClose={() => {
            onClose()
            auth.setOpenDrawer(false)
        }}
                size={'xl'}>
            <DrawerOverlay />
            <DrawerContent>
                <Flex h={'full'} px={"2%"} py={'2%'} flexDirection="column" alignItems="flex-start" gap="48px" flexShrink={0} boxShadow="-4px 0px 10px 0px rgba(0, 0, 0, 0.06)">
                    <Flex w="full" flexDirection="column" alignItems="flex-start" gap="24px">
                        <Flex w="full" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <CloseButton onClick={onClose} />
                            <Text color="gray.700" textAlign="center" isTruncated fontSize="lg" fontStyle="normal" fontWeight="medium" lineHeight="7">
                                {podcast.title}
                            </Text>
                            <Icon as={GrMore} visibility={'hidden'} />
                        </Flex>
                        <Tabs w={'full'} position={'relative'} _selected={{textColor:'teal.500'}} align={'center'} isLazy={true} index={tabIndex} onChange={handleTabsChange} size={'lg'} variant={'unstyled'}>
                            <TabList>
                                <Tab>
                                    <Text>
                                        Dashboard
                                    </Text>
                                </Tab>
                                <Tab>
                                    <Text>
                                        Members
                                    </Text>
                                </Tab>
                                <Tab>
                                    <Text>
                                        Notifications
                                    </Text>
                                </Tab>
                                <Tab>
                                    <Text>
                                        Plan
                                    </Text>
                                </Tab>
                            </TabList>
                            <TabIndicator
                                height="2px"
                                bg="teal.500"
                                alignSelf={'stretch'}
                            />
                            <TabPanels w={'full'} pt={'48px'}>
                                <TabPanel p={'0px'}>
                                    <PodcastModule podcast={podcast}/>
                                </TabPanel>
                                <TabPanel p={'0px'}>
                                    <Flex w={'full'} direction={'column'} alignItems={'flex-start'} gap="24px" alignSelf={'stretch'}>
                                        <ProductionTeamSettings podcast={podcast}/>
                                        <MemberSettings podcast={podcast}/>
                                    </Flex>
                                </TabPanel>
                                <TabPanel p={'0px'}>
                                    <PublisherNotificationSettings/>
                                </TabPanel>
                                <TabPanel p={'0px'}>
                                    <SubscriptionSettings />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Flex>
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}
