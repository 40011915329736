import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import {Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Skeleton, Text, useToast} from "@chakra-ui/react";
import {EpisodesIcon} from "../../assets/EpisodesIcon";
import React, {useEffect, useState} from "react";
import {Podcast} from "../../models/Podcast";
import {removePTags} from "../../utils/text-utils";
import {useLibrary} from "../../context/LibraryContext";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {LibraryNavigationRoutes, useLibraryNavigation} from "../../context/LibraryNavigationContext";

export default function PodcastModule({podcast, setContent, links}: {podcast: Podcast, setContent?: any, links?: any}) {
    const user: any = useUser()
    const library: any = useLibrary()
    const libraryNavigation: any = useLibraryNavigation()
    const [tabIndex, setTabIndex] = useState<number>(0)
    const [episodeLoading, setEpisodeLoading] = useState<boolean>(true)
    const [hasEpisodes, setHasEpisodes] = useState<boolean>(false)
    const [languagesProgress, setLanguagesProgress] = useState<any>({})
    const [episodeTranscriptionProgress, setEpisodeTranscriptionProgress] = useState<any>({})
    const [currentLanguage, setCurrentLanguage] = useState<string>()
    const [speakers, setSpeakers] = useState<any>([])
    const toast: any = useToast()

    const episodesProgressReq = useApi(ENDPOINTS.PUBLISHER_GET_EPISODES_PROGRESS, user.token, false)

    //const speakersReq = useApi(`/publisher/speakers/${podcast.id}`, user.token, true)

    useEffect(() => {
        if (!episodesProgressReq.loading && episodesProgressReq.data) {
            setLanguagesProgress(episodesProgressReq.data.progress)
            setEpisodeTranscriptionProgress(episodesProgressReq.data.transcriptionProgress)
            setEpisodeLoading(false)
        }
    }, [episodesProgressReq.loading])


    useEffect(() => {
        if (library.episodesDownloaded && !library.episodesLoading) {
            let episodes = library.podcastEpisodes.get(podcast.id)
            episodesProgressReq.setPayloadData({
                episodes: episodes
            })
        }

    }, [library.episodesDownloaded, library.episodesLoading, library.languagesProgress, library.episodeTranscriptionProgress])

    const styles = buildStyles({
        pathColor: '#38B2AC',
        textColor: '#2D3748',
        trailColor: '#E2E8F0',
    })

    const handleLanguageChange = () => {
        if (Object.keys(languagesProgress).length === 0) {
            toast({
                title: "No languages available",
                description: "You have not yet localized an episode for this podcast",
                status: "warning",
                duration: 3000,
            })
        }
    }

    const colors = ["green.500", "red.500", "yellow.200"];
    return (
        <Flex w={'full'} h={'fit-content'} direction={'column'} alignItems={'flex-start'} gap="56px" alignSelf={'stretch'}>
            <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="12px" flex="1 0 0" borderRadius="12px" border="1px" borderColor="gray.200" boxShadow={'md'}>
                <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                    <Image src={podcast.image_url} boxSize={'64px'} borderRadius="12px" />
                    <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                        <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                            {podcast.title}
                        </Text>
                        <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                            <EpisodesIcon/>
                            <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                {podcast.episodes.length} Episodes
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex display={'flex'} direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                    <Text textAlign={'left'} wordBreak={'break-all'} color="gray.600" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="16px">
                        {removePTags(podcast.summary)}
                    </Text>
                </Flex>
            </Flex>
            <Flex w={'100%'} p="16px" flexDirection="column" alignSelf="stretch" alignItems="flex-start" justifyContent="flex-start" gap="36px" borderRadius="12px" border="1px" borderColor="gray.200" boxShadow={'md'}>
                <Flex w={'full'} direction={'row'} alignItems="center" justifyContent="space-between">
                    <Text wordBreak={'break-all'} color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                        EPISODES
                    </Text>
                    <Menu>
                        <Flex w={'25%'} gap={'8px'} px={'12px'} py={'6px'} borderRadius={'6px'} border={'1px'} borderColor={'gray.200'} justifyContent={'center'} alignItems={'center'}>
                            <MenuButton onClick={handleLanguageChange}>
                                <Text color="gray.400" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    {currentLanguage ? currentLanguage : 'Select Language'}
                                </Text>
                            </MenuButton>
                        </Flex>
                        <MenuList>
                            {Object.keys(languagesProgress).length > 0 ?
                                Object.keys(languagesProgress).map((language: any) => {
                                    return (
                                        <MenuItem key={language} onClick={() => setCurrentLanguage(language)}>
                                            {language}
                                        </MenuItem>
                                    )}
                                )
                                :
                                <MenuItem>
                                    <Skeleton height="20px" />
                                </MenuItem>
                            }
                        </MenuList>
                    </Menu>
                </Flex>
                <Flex p="16px" flexDirection="column" alignSelf="stretch" alignItems="center" justifyContent="center" gap="36px">
                    <Flex w={'full'} gap="120px" flexDirection="row" alignSelf="stretch" justifyContent="center" alignItems="center">
                        {!episodeLoading  && <Flex boxSize={'92px'} direction="column" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch">
                            <CircularProgressbar
                                value={episodeTranscriptionProgress && episodeTranscriptionProgress['completed'] ? episodeTranscriptionProgress['completed'] : 0}
                                text={`${episodeTranscriptionProgress && episodeTranscriptionProgress['completed'] ? episodeTranscriptionProgress['completed'] : 0}%`}
                                strokeWidth={6}
                                styles={styles}
                            />
                            <Text textAlign={'center'} color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="20px">
                                Transcription
                            </Text>
                        </Flex>
                        }
                        {!episodeLoading  && <Flex boxSize={'92px'} direction="column" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch">
                            <CircularProgressbar
                                value={currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}
                                text={`${currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}%`}
                                strokeWidth={6}
                                styles={styles}
                            />
                            <Text textAlign={'center'} color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="20px">
                                Translation
                            </Text>
                        </Flex>
                        }
                        {!episodeLoading && <Flex boxSize={'92px'} direction="column" justifyContent="center" alignItems="center" gap="8px" alignSelf="stretch">
                            <CircularProgressbar
                                value={currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}
                                text={`${currentLanguage && languagesProgress[currentLanguage] ? languagesProgress[currentLanguage].processing : 0}%`}
                                strokeWidth={6}
                                styles={styles}
                            />
                            <Text textAlign={'center'} color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="500" lineHeight="20px">
                                Dubbing
                            </Text>
                        </Flex>
                        }
                    </Flex>
                </Flex>
            </Flex>
            <Flex w={'full'} mt={'56px'} direction={'row'} alignItems="center" justifyContent="space-between">
                <Flex w={'45%'}  p="16px" flexDirection="row" justifyContent={'space-between'} alignItems="center"  borderRadius="12px" border="1px" borderColor="gray.200" boxShadow={'md'}>
                    <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                        <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                            <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                                Localization
                            </Text>
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    Track your transcription, translation and dubbing jobs.
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex display={'flex'} direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                        <Button w={'full'} variant={'ghost'} h={'fit-content'} p={0} alignContent={'flex-end'} alignItems={'flex-end'} justifyContent={'flex-end'} onClick={() => libraryNavigation.setRoute(LibraryNavigationRoutes.LIBRARY)}>
                            <Text color="teal.500" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="16px">
                                See more
                            </Text>
                        </Button>
                    </Flex>
                </Flex>
                <Flex w={'45%'} p="16px" flexDirection="row" justifyContent={'space-between'} alignItems="center" gap="12px"  borderRadius="12px" border="1px" borderColor="gray.200" boxShadow={'md'}>
                    <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                        <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                            <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                                Back-Catalog
                            </Text>
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    Browse and start localizing your back-catalog.
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex display={'flex'} direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                        <Button w={'full'} variant={'ghost'} h={'fit-content'} p={0} alignContent={'flex-end'} alignItems={'flex-end'} justifyContent={'flex-end'} onClick={() => libraryNavigation.setRoute(LibraryNavigationRoutes.CATALOG)}>
                            <Text color="teal.500" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="16px">
                                See more
                            </Text>
                        </Button>
                    </Flex>
                </Flex>

            </Flex>
            <Flex w={'full'} mt={'8px'} direction={'row'} alignItems="center" justifyContent="space-between">
                <Flex w={'45%'}  p="16px" flexDirection="row" justifyContent={'space-between'} alignItems="center" gap="12px"  borderRadius="12px" border="1px" borderColor="gray.200" boxShadow={'md'}>
                    <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                        <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                            <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                                Review Tasks
                            </Text>
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    Complete the final review of your team's tasks.
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex display={'flex'} direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                        <Button w={'full'} variant={'ghost'} h={'fit-content'} p={0} alignContent={'flex-end'} alignItems={'flex-end'} justifyContent={'flex-end'} onClick={() => libraryNavigation.setRoute(LibraryNavigationRoutes.LIBRARY)}>
                            <Text color="teal.500" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="16px">
                                See more
                            </Text>
                        </Button>
                    </Flex>
                </Flex>
                <Flex w={'45%'} p="16px" flexDirection="row" justifyContent={'space-between'} alignItems="center" gap="12px"  borderRadius="12px" border="1px" borderColor="gray.200" boxShadow={'md'}>
                    <Flex alignItems={'flex-start'} gap="16px" alignSelf={'stretch'}>
                        <Flex direction={'column'} alignItems={'flex-start'} gap={'8px'} flex={'1 0 0'}>
                            <Text color="gray.800" textAlign="center" isTruncated fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                                Team Management
                            </Text>
                            <Flex direction={'row'} alignItems={'center'} gap={'8px'} alignSelf={'stretch'}>
                                <Text color="gray.700" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                    Add and manage your team.
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex display={'flex'} direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                        <Button w={'full'} variant={'ghost'} h={'fit-content'} p={0} alignContent={'flex-end'} alignItems={'flex-end'} justifyContent={'flex-end'} onClick={() => libraryNavigation.setRoute(LibraryNavigationRoutes.TEAMS)}>
                            <Text color="teal.500" fontSize="14px" fontStyle="normal" fontWeight="600" lineHeight="16px">
                                See more
                            </Text>
                        </Button>
                    </Flex>
                </Flex>

            </Flex>
        </Flex>
    )
}
