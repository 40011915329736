import {Box, Button, Center, Flex, Slide, Spinner, useDisclosure, useToast} from "@chakra-ui/react";
import {GuestConfiguration, HostConfiguration} from "../configuration/speakers-configuration";
import {PublisherEditingConfiguration} from "../configuration/publisher-editing-configuration";
import {ServiceConfiguration} from "../configuration/service-configuration";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import React, {useEffect, useState} from "react";
import {BraincapService, ServiceType} from "../../models/Localization";
import {LanguageConfiguration} from "../configuration/language-configuration";
import {
    Podcast,
} from "../../models/Podcast";
import {useUser} from "../../context/UserContext";
import {useLibrary} from "../../context/LibraryContext";
import {ConfigurationValidation, useLocalization} from "../../context/LocalizationContext";
import {PaymentStatus} from "../../models/Cart";
export const ConfigurationModule = ({episode, isMultiConfiguration, closePanel}: {episode: PodcastEpisode | PodcastEpisode[], isMultiConfiguration: boolean, closePanel: () => void}) => {
    const user: any = useUser()
    const library: any = useLibrary()
    const localization: any = useLocalization()
    const [selectedService, setSelectedService] = useState<BraincapService>()
    const [validate, setValidate] = useState<boolean>(false)
    const [readyToCheckout, setReadyToCheckout] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [isInCart, setIsInCart] = useState<boolean>(false)
    const toast = useToast()

    useEffect(() => {
        if (!localization.addItemsToCartReq.loading && localization.addItemsToCartReq.data) {
            toast({
                title: 'Success',
                description: 'Item added to cart',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setLoading(false)
            localization.resetConfiguration()
            closePanel()
        }

        if (!localization.addItemsToCartReq.loading && localization.addItemsToCartReq.error) {
            toast({
                title: 'Error',
                description: 'Error adding item to cart',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            setLoading(false)
        }

    }, [localization.addItemsToCartReq.loading])

    useEffect(() => {
        if (!isMultiConfiguration && episode) {
            if (library.cartDownloaded && !library.cartLoading) {
                if (library.cartMap.has((episode as PodcastEpisode).id)) {
                    setIsInCart(true)
                } else {
                    setIsInCart(false)
                }
            }

            if (library.cart.size === 0) {
                setIsInCart(false)
            }
        }
    }, [library.cart])

    useEffect(() => {
        if (selectedService) {
            setValidate(false)
        }
    }, [selectedService])

    const handleAddToCart = async () => {
        if (!selectedService) {
            toast({
                title: 'Invalid configuration',
                description: 'Please select a service',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return
        }

        if (localization.validateConfiguration()) {
            setValidate(true)
            toast({
                title: 'Invalid configuration',
                description: 'Please complete all required fields',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return
        } else if (isInCart) {
            toast({
                title: 'Episode already in cart',
                description: 'You can only have one service per episode in your cart',
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
            return
        }
        else {
            toast({
                title: 'Success',
                description: 'Configuration is valid',
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            setLoading(true)
            localization.handleAddToCart()
        }

    }


return (
<Flex w={'full'} style={{overflow: 'auto', height: '100%'}} direction={'column'} gap={'12px'} alignItems={'flex-start'} justifyContent={'center'}>
    <Flex w={'full'} h={'100%'} direction={'column'} overflowY="auto" flex="1" gap={'24px'}>
        <ServiceConfiguration episode={isMultiConfiguration? episode as PodcastEpisode[] : episode as PodcastEpisode} isMultiConfiguration={isMultiConfiguration} setSelectedService={setSelectedService}/>
        {selectedService?
            <>
                {selectedService?.type && <LanguageConfiguration episode={isMultiConfiguration? episode as PodcastEpisode[] : episode as PodcastEpisode} service={selectedService} isMultiConfiguration={isMultiConfiguration} validate={validate}/>}
                {selectedService?.type === ServiceType.TRANSLATION || selectedService?.type === ServiceType.DUBBING?
                    <PublisherEditingConfiguration episode={isMultiConfiguration? episode as PodcastEpisode[] : episode as PodcastEpisode} service={selectedService} isMultiConfiguration={isMultiConfiguration} validate={validate}/>
                    :
                    null
                }
                {selectedService?.type && <HostConfiguration validate={validate} isMultiConfiguration={isMultiConfiguration} episode={isMultiConfiguration? episode as PodcastEpisode[] : episode as PodcastEpisode}/>}
                {selectedService?.type && <GuestConfiguration validate={validate} isMultiConfiguration={isMultiConfiguration} episode={isMultiConfiguration? episode as PodcastEpisode[] : episode as PodcastEpisode}/>}
            </>
            :
            null
        }
    </Flex>
    <Slide in={true} direction='bottom' style={{ zIndex: 10 }}>
        <Box pos={'fixed'} alignItems="center" bottom="0" bg="white" boxShadow={'lg'} h={20} w="100%" zIndex={2}>
            <Center>
                {!loading && <Button isDisabled={loading} onClick={handleAddToCart} w={'25%'} mt="5" size= "md" colorScheme="teal">
                    Add to Cart
                </Button>
                }
                {loading && <Spinner size="md" color="teal.500" thickness="4px" />}
            </Center>
        </Box>
    </Slide>
</Flex>
)

}
