import {Button, Checkbox, Flex, Image, Tag, TagLabel, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useUser} from "../../context/UserContext";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {useLibrary} from "../../context/LibraryContext";
import {useLocalization} from "../../context/LocalizationContext";
import {PaymentStatus} from "../../models/Cart";
import {TranscriptionState} from "../../models/Localization";
import EpisodeDashboardPanel from "./episode-dashboard-panel";
import {BraincapSettingsIcon} from "../../assets/BraincapSettingsIcon";
import {useNavigate, useParams} from "react-router-dom";

export const EpisodeCard = ({episode, displayCheck, isInMultiConfiguration}: {episode: PodcastEpisode, displayCheck: "hidden" | "visible", isInMultiConfiguration?: any}) => {
    const user: any = useUser()
    const library: any = useLibrary()
    const localization: any = useLocalization()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isMultiConfiguration, setIsMultiConfiguration] = useState<boolean>(false)
    const [isInCart, setIsInCart] = useState<boolean>(false)
    const [waitingServerConfirmation, setWaitingServerConfirmation] = useState<boolean>(false)
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const navigate = useNavigate();
    const { podcastId } = useParams()
    const [hasBeenProcessed, setHasBeenProcessed] = useState<boolean>(false)

    useEffect(() => {
        if (episode.transcription.state !== TranscriptionState.NOT_CREATED) {
            setHasBeenProcessed(true)
        }
    }, [episode])

    useEffect(() => {
        if (displayCheck === 'visible') {
            setIsMultiConfiguration(true)
        }

        if (displayCheck === 'hidden') {
            setIsMultiConfiguration(false)
        }

    }, [displayCheck])

    useEffect(() => {
        if (isInMultiConfiguration && displayCheck === 'visible') {
            setIsChecked(true)
        } else if (!isInMultiConfiguration && displayCheck === 'visible') {
            setIsChecked(false)
        }
    }, [isInMultiConfiguration, displayCheck])

    useEffect(() => {
        if (Object.keys(localization.episodesConfiguration).length === 0) {
            setIsChecked(false)
        }
    }, [localization.episodesConfiguration])

    useEffect(() => {
        if (library.cartDownloaded && !library.cartLoading) {
            if (library.cartMap.has(episode.id)) {
                setIsInCart(true)
                if (library.cartMap.get(episode.id)['status'] === PaymentStatus.CHECKED_OUT) {
                    setWaitingServerConfirmation(true)
                } else {
                    setWaitingServerConfirmation(false)
                }
            } else {
                setIsInCart(false)
            }
        }

        if (library.cart.size === 0) {
            setIsInCart(false)
        }
    }, [library.cart])

    const goToAiTranscript = () => {
        let sessionId = episode.transcription.session?.id
        if (sessionId) {
            navigate(`/reader/${sessionId}/asr`)
        }
    }

    return (
        <Flex p="12px" w={'fit-content'} flexDirection="column" alignItems="center" justifyContent={'center'} bgColor={'#fff'} borderRadius="12px" boxShadow={'sm'} wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis'>
               <Flex w={'100%'} direction={'column'} justifyContent={'center'} alignItems={'center'} gap={'8px'}>
                   <Flex position={'relative'} alignItems="center" justifyContent={'center'}>
                       {!isInCart && <Checkbox colorScheme={'teal'} position="absolute" top={0} right={0} visibility={displayCheck} isChecked={isChecked} onChange={e => {
                           setIsChecked(e.target.checked)
                           localization.configureSingleEpisode(e.target.checked, episode.id)
                       }} borderColor={'gray.700'} borderRadius={'4px'} bgColor={'white'}/>
                       }
                       <Image fallbackSrc='https://via.placeholder.com/150'  boxSize={'full'} src={episode.image_url? episode.image_url: episode.podcast.image_url} borderRadius="8px" bgColor="gray.100"/>
                   </Flex>
                   <Tooltip label={`${episode.title}`}>
                       <Text  w={'full'} noOfLines={1} color="gray.700" fontSize="16px" fontWeight="500" lineHeight="24px">{episode.title}</Text>
                   </Tooltip>
                   <Flex w={'full'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                       <Text  color='gray.500' fontSize={'14px'} fontFamily={'sans-serif'} fontStyle={'normal'} fontWeight={400}>
                           {secondsToHHMMSS(episode.duration)}
                       </Text>
                       {isInCart && displayCheck === 'visible' &&
                            <Tag colorScheme={'teal'}>
                                <TagLabel>
                                    <Text  fontSize={'14px'} fontFamily={'sans-serif'} fontStyle={'normal'} fontWeight={400}>
                                        In Cart
                                    </Text>
                                </TagLabel>
                            </Tag>
                       }
                   </Flex>
                   {hasBeenProcessed?
                       <Flex w={'full'} flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
                           <Flex w={'full'} flexDirection={'row'} alignItems={'center'} gap={'8px'}>
                               {episode.translation.processing.length > 0 || episode.dubbing.processing.length > 0 || episode.transcription.state !== TranscriptionState.COMPLETED &&
                                   <Tag size={'md'} key={episode.id} borderRadius='full' variant='solid' bgColor='green.50'>
                                       <TagLabel color={waitingServerConfirmation? 'orange.500' : 'green.500'}>
                                           {waitingServerConfirmation? 'Creating tasks...' : 'Transcribing'}
                                       </TagLabel>
                                   </Tag>
                               }
                               {(episode.transcription.state === TranscriptionState.PROOFREADING || episode.transcription.state === TranscriptionState.READY_FOR_PROOFREADING) &&
                                   <Tag size={'md'} key={episode.id} borderRadius='full' variant='solid' bgColor='green.50' onClick={goToAiTranscript}>
                                       <TagLabel color={waitingServerConfirmation? 'orange.500' : 'green.500'}>
                                           {'Open AI transcript'}
                                       </TagLabel>
                                   </Tag>
                               }
                           </Flex>
                           {!waitingServerConfirmation &&
                               <Flex w={'full'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                   <Flex justifyContent={'flex-start'}>
                                   </Flex>
                                   <Flex justifyContent={'flex-end'} onClick={() => {
                                       localization.configureSingleEpisode(true, episode.id)
                                       onOpen()
                                   }}>
                                       <BraincapSettingsIcon/>
                                       <EpisodeDashboardPanel isMultiConfiguration={isMultiConfiguration} episode={episode} isOpen={isOpen} onOpen={onOpen} onClose={onClose} openingIndex={0}/>
                                   </Flex>
                               </Flex>
                           }
                       </Flex>
                       :
                       <>
                           {!isMultiConfiguration?
                               <>
                                   <Button onClick={() => {localization.configureSingleEpisode(true, episode.id)
                                       onOpen()}} isDisabled={isInCart} size={'md'} w={'full'} variant={'outline'} borderRadius={'6px'} border={'1px solid'}>
                                       {isInCart? 'In Cart' : 'Configure'}
                                   </Button>
                                   <EpisodeDashboardPanel isMultiConfiguration={false} episode={episode} isOpen={isOpen} onOpen={onOpen} onClose={onClose} openingIndex={1}/>
                               </>
                               :
                               null
                           }
                       </>
                   }
               </Flex>
        </Flex>
    )
}
