import {PodcastCartItem} from "../../models/Cart";
import {Stack, Table, TableContainer, Tbody, Td, Th, Text, Thead, Tooltip, Tr, Flex, Tfoot} from "@chakra-ui/react";
import {convertSecondsToMinutes, countWords} from "../../utils/firebase";
import {useLocalization} from "../../context/LocalizationContext";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {secondsToHHMMSS} from "../../utils/text-utils";
import {useLibrary} from "../../context/LibraryContext";
import React, {useEffect, useState} from "react";
import {ENDPOINTS, useApi} from "../../api/braincap-api";
import {useUser} from "../../context/UserContext";
import {PodcastTranscriptionConfig, PodcastTranslationConfig} from "../../models/Localization";
import {FiDelete, FiEdit} from "react-icons/fi";
import {Publisher} from "../../models/Publisher";
import {it} from "node:test";


export const CartElement = ({episode}: {episode: Partial<PodcastEpisode>}) => {
    let localization: any = useLocalization()

    //const languages: string[] = configuration? configuration.language.target : []
    return (
        <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' p={'0px'} gap={'4px'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
            <Tooltip label={episode.title} placement={'top'}>
                <Text fontSize='md' fontWeight='medium' lineHeight='normal' overflow='hidden' textOverflow='ellipsis' noOfLines={2}>
                    {episode.title}
                </Text>
            </Tooltip>
            <Text color='gray.600' fontSize='md'>
                {secondsToHHMMSS(episode.duration? episode.duration : 0)}
            </Text>
        </Flex>
    )
}
export const TranscriptionOnlyItems = ({cart, setTotalPrice, setUpdateCart}: {cart: PodcastCartItem[], setTotalPrice: (price: number) => void, setUpdateCart: (update: PodcastCartItem[]) => void}) => {
    const user: any = useUser()
    const localization: any = useLocalization()
    const library: any = useLibrary()
    let finalPrice = 0
    const [loading, setLoading] = useState<boolean>(true)
    const removeCartItemReq = useApi(ENDPOINTS.CART_DELETE, user.token, false)
    const updateCartReq = useApi(ENDPOINTS.CART_UPDATE, user.token, false)
    useEffect(() => {
        setTotalPrice(finalPrice)
    }, [finalPrice])

    const handleRemoveCartItem = (cartItem: PodcastCartItem) => {
        if (cartItem.episode.id && user.userId) {
            setLoading(true)
            removeCartItemReq.setPayloadData({
                episodeId: cartItem.episode.id,
                publisherId: user.userId,
            })
        }
    }

    useEffect(() => {
        if (!removeCartItemReq.loading && removeCartItemReq.data) {
            library.updateCart(removeCartItemReq.data)
            setLoading(false)
        }
    }, [removeCartItemReq.loading])

    return (
        <TableContainer style={{overflow: 'auto'}}>
            <Table variant='simple' size={'sm'}>
                <Thead>
                    <Tr>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">EPISODES</Th>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">DURATION (MIN)</Th>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">LANGUAGE</Th>
                        <Th isNumeric color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">PRICE</Th>
                    </Tr>
                </Thead>
                <Tbody w={'full'}>
                    {cart.map((cartItem: PodcastCartItem) => {
                        let item: PodcastTranscriptionConfig = cartItem as PodcastTranscriptionConfig
                        let duration = convertSecondsToMinutes(item.episode.duration)
                        let totalPrice = duration * item.service.price
                        console.log(`Total price: ${JSON.stringify(item.source_language)}`)
                        finalPrice += totalPrice
                        return (
                            <Tr>
                                <Td>
                                    <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' p={'0px'} gap={'4px'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                        <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' p={'0px'} gap={'4px'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                            <Tooltip label={item.episode.title} placement={'top'}>
                                                <Text fontSize='md' fontWeight='medium' lineHeight='normal' overflow='hidden' textOverflow='ellipsis' noOfLines={2}>
                                                    {item.episode.title}
                                                </Text>
                                            </Tooltip>
                                            <Flex w={'full'} direction={'row'} justifyContent={'space-between'}>
                                                <Text color='gray.600' fontSize='md'>
                                                    {secondsToHHMMSS(item.episode.duration? item.episode.duration : 0)}
                                                </Text>
                                                <Flex w={'full'} direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'8px'}>
                                                    <FiDelete onClick={() => {handleRemoveCartItem(item)}}/>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Td>
                                <Td>{duration}</Td>
                                <Td>{item.source_language.name}</Td>
                                <Td isNumeric>{`$${totalPrice}`}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">Total</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th isNumeric color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">{`$${finalPrice}`}</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}

export const TranslationOnlyItems = ({cart, setTotalPrice, setUpdateCart}: {cart: PodcastCartItem[], setTotalPrice: (price: number) => void, setUpdateCart: (update: PodcastCartItem[]) => void}) => {
    const user: any = useUser()
    const library: any = useLibrary()
    const localization: any = useLocalization()
    let finalPrice = 0
    const [loading, setLoading] = useState<boolean>(true)
    const removeCartItemReq = useApi(ENDPOINTS.CART_DELETE, user.token, false)
    let publisher: Publisher = user.user as Publisher

    useEffect(() => {
        setTotalPrice(finalPrice)
    }, [finalPrice])

    const handleRemoveCartItem = (cartItem: PodcastCartItem) => {
        if (cartItem.episode.id && user.userId) {
            setLoading(true)
            library.setCartLoading(true)
            removeCartItemReq.setPayloadData({
                episodeId: cartItem.episode.id,
                publisherId: user.userId,
            })
        }
    }

    useEffect(() => {
        if (!removeCartItemReq.loading && removeCartItemReq.data) {
            library.updateCart(removeCartItemReq.data)
            setLoading(false)
        }
    }, [removeCartItemReq.loading])

    return (
        <TableContainer style={{overflow: 'auto'}}>
            <Table variant='simple' size={'sm'}>
                <Thead>
                    <Tr>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">EPISODES</Th>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">TITLE (WORDS)</Th>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">DESCRIPTION (WORDS)</Th>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">DURATION (MIN)</Th>
                        <Th color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">LANGUAGES</Th>
                        <Th isNumeric color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">PRICE</Th>
                    </Tr>
                </Thead>
                <Tbody w={'full'}>
                    {cart.map((cartItem: PodcastCartItem) => {
                        let item: PodcastTranslationConfig = cartItem as PodcastTranslationConfig
                        console.log(item.target_languages)
                        let nbLanguage = item.target_languages.length
                        //TODO: On hover on the number of languages, podcast the list of languages in tooltip
                        let titleTranslationPrice = Math.round(countWords(item.episode.title) * 0.05)
                        let descriptionTranslationPrice = Math.round(countWords(item.episode.description) * 0.05)
                        let totalPrice = convertSecondsToMinutes(item.episode.duration) * nbLanguage * item.service.price + titleTranslationPrice + descriptionTranslationPrice
                        finalPrice += totalPrice
                        return (
                            <Tr w={'full'}>
                                <Td>
                                    <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' p={'0px'} gap={'4px'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                        <Flex wordBreak={'break-word'} overflow='hidden' textOverflow='ellipsis' p={'0px'} gap={'4px'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'}>
                                            <Tooltip label={item.episode.title} placement={'top'}>
                                                <Text fontSize='md' fontWeight='medium' lineHeight='normal' overflow='hidden' textOverflow='ellipsis' noOfLines={2}>
                                                    {item.episode.title}
                                                </Text>
                                            </Tooltip>
                                            <Flex w={'full'} direction={'row'} justifyContent={'space-between'}>
                                                <Text color='gray.600' fontSize='md'>
                                                    {secondsToHHMMSS(item.episode.duration? item.episode.duration : 0)}
                                                </Text>
                                                <Flex w={'full'} direction={'row'} justifyContent={'flex-end'} alignItems={'center'} gap={'8px'}>
                                                    <FiDelete onClick={() => {handleRemoveCartItem(item)}}/>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Td>
                                <Td>{countWords(item.episode.title)} </Td>
                                <Td>{countWords(item.episode.description)}</Td>
                                <Td>{convertSecondsToMinutes(item.episode.duration)}</Td>
                                <Td>{nbLanguage}</Td>
                                <Td isNumeric>{`$${totalPrice}`}</Td>
                            </Tr>
                        )
                    })}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">Total</Th>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        <Th></Th>
                        <Th isNumeric color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">{`$${finalPrice}`}</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
    )
}
