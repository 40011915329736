import {Button, Flex, Text, useDisclosure} from "@chakra-ui/react";
import {SplashLogo} from "../assets/SplashIcon";
import React, {useEffect} from "react";
import {useUser} from "../context/UserContext";

export default function NoTaskModal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const user: any = useUser()


    return (
        <Flex w="full" flexDirection="column">
            <Flex w="full" p="56px 48px" flexDirection="column" justifyContent="center" alignItems="center" gap="56px" borderRadius="12px" bg="#FFF" boxShadow="0px 8px 24px 0px rgba(0, 0, 0, 0.06)">
                <SplashLogo/>
                <Text alignSelf="stretch" color="#2D3748" textAlign="center" fontSize="36px" fontWeight="500" lineHeight="40px">
                    There are currently no new episodes to review at the moment. Please check back later.
                </Text>
            </Flex>
        </Flex>
    )
}
