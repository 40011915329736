export enum NotificationType {
    ORDER_SUBMITTED = "ORDER_SUBMITTED",
    TRANSCRIPTION_SESSION_STARTED = "TRANSCRIPTION_SESSION_STARTED",
    TRANSCRIPTION_SESSION_COMPLETED = "TRANSCRIPTION_SESSION_COMPLETED",
    REVIEW_SESSION_STARTED = "REVIEW_SESSION_STARTED",
    REVIEW_SESSION_COMPLETED = "REVIEW_SESSION_COMPLETED",
    TRANSLATION_SESSION_STARTED = "TRANSLATION_SESSION_STARTED",
    TRANSLATION_SESSION_COMPLETED = "TRANSLATION_SESSION_COMPLETED",
    DUBBING_SESSION_STARTED = "DUBBING_SESSION_STARTED",
    DUBBING_SESSION_COMPLETED = "DUBBING_SESSION_COMPLETED",
    COMMENT_ADDED = "COMMENT_ADDED",
}

export enum NotificationStatus {
    READ = "READ",
    UNREAD = "UNREAD",
}

export interface BraincapNotification {
    id: string;
    type: NotificationType;
    status: NotificationStatus;
    message: string;
    created_at: Date;
    read_at?: Date;
}

export interface NotificationSettings {
    type: NotificationType,
    name: string;
    description: string;
    enabled: boolean;
    email: boolean;

}

export interface PublisherNotificationSettings {
    order: NotificationSettings;
    transcription: NotificationSettings;
    review: NotificationSettings;
    translation: NotificationSettings;
    dubbing: NotificationSettings;
    comment: NotificationSettings;
}

 export const getNotificationName = (type: NotificationType): string => {
    switch (type) {
        case NotificationType.ORDER_SUBMITTED:
            return "Order Confirmation";
        case NotificationType.TRANSCRIPTION_SESSION_STARTED:
            return "Proofreading session Start";
        case NotificationType.TRANSCRIPTION_SESSION_COMPLETED:
            return "Proofreading session Completion";
        case NotificationType.REVIEW_SESSION_STARTED:
            return "Editor Review Start";
        case NotificationType.REVIEW_SESSION_COMPLETED:
            return "Editor Review Completion";
        case NotificationType.TRANSLATION_SESSION_STARTED:
            return "Translation Session Start";
        case NotificationType.TRANSLATION_SESSION_COMPLETED:
            return "Translation Session Completion";
        case NotificationType.DUBBING_SESSION_STARTED:
            return "Dubbing Session Start";
        case NotificationType.DUBBING_SESSION_COMPLETED:
            return "Dubbing Session Completion";
        case NotificationType.COMMENT_ADDED:
            return "Editor Comments";
    }
 }
