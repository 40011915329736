import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "plyr-react/plyr.css"
import {Box, Button, Center, Divider, Flex, Slide, Text} from "@chakra-ui/react";

import {ChevronLeftIcon} from "@chakra-ui/icons";
import {useLibrary} from "../../context/LibraryContext";
import {ReviewerEditor} from "./reviewer-editor";

export default function ReviewerTranscriptPage() {
    const [loading, setLoading] = useState<boolean>(true);
    const library: any = useLibrary()

    return (
        <Flex w={'full'} direction={'column'} gap={'16px'} alignItems={'flex-start'}>
            <Flex w={'full'} direction="row" justifyContent="space-between" py={'3px'}>
                <Link to={`/r/dashboard`}>
                    <ChevronLeftIcon  boxSize={'36px'} onClick={() => {
                        library.setNavBarEpisodeTitle('')
                        library.setOverrideNavBarEpisodeTitle(false)
                    }}/>
                </Link>
            </Flex>
            <ReviewerEditor />
        </Flex>
    );
};
