
export interface User {
    id: string,
    created_at: any
    first_name: string,
    last_name: string,
    name: string,
    phone: string,
    email: string,
    category: UserCategory
    password?: string,
    is_2fa_verified: boolean
}
export type UserRegistrationForm = Pick<User, 'first_name' | 'last_name' | 'category' | 'phone' | 'email' | 'password'> & {
    from_invitation: boolean
    podcastId?: string
    invitationId?: string
}

export enum UserCategory {
    PUBLISHER = "PUBLISHER",
    LABELER = "LABELER",
    SPEAKER = "SPEAKER",
    EDITOR = "EDITOR",
}

export enum PublisherType {
    PODCASTER = "PODCASTER",
    YOUTUBER = "YOUTUBER",
}
