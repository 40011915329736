import {Box, Flex, Grid, Spinner, Text, useToast} from "@chakra-ui/react";
import {TranscriptionIcon} from "../../assets/TranscriptionIcon";
import {TranslationIcon} from "../../assets/TranslationIcon";
import {DubbingIcon} from "../../assets/DubbingIcon";
import React, {useEffect, useState} from "react";
import {BraincapService, LocalizationProcessingType, ServiceType, TranscriptionState} from "../../models/Localization";
import {useLocalization} from "../../context/LocalizationContext";
import LoadingSpinner from "../loading-spinner";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {FiCheckCircle} from "react-icons/fi";
import {MediaContent} from "../../models/MediaContent";
import {MediaContentCartItem} from "../../models/Cart";

export const ServiceConfiguration = ({episode, isMultiConfiguration, setSelectedService}: {episode: PodcastEpisode | PodcastEpisode[], isMultiConfiguration: boolean, setSelectedService: (selectedService: BraincapService) => void}) => {
    const localization: any = useLocalization()
    const [services, setServices] = useState<BraincapService[]>([])
    const [activeService, setActiveService] = useState<Partial<BraincapService>>();

    useEffect(() => {
        if (!!activeService && !localization.appConfigLoading && localization.appConfigDownloaded) {
            let configs: any = {...localization.episodesConfiguration};
            let validations: any = {...localization.configurationValidation};

            if (isMultiConfiguration) {
                Object.entries(localization.episodesConfiguration).forEach((config: any) => {
                    const [key, value] = config;
                    switch (activeService.type) {
                        case ServiceType.TRANSCRIPTION:
                            configs[key] = {
                                ...value,
                                service: activeService,
                                source_language: null,
                                speakers: [],

                            };
                            break
                        case ServiceType.TRANSLATION:
                            configs[key] = {
                                ...value,
                                service: activeService,
                                source_language: null,
                                target_languages: [],
                                pre_translation_editing: null,
                                speakers: [],

                            };
                            break
                        case ServiceType.DUBBING:
                            configs[key] = {
                                ...value,
                                service: activeService,
                                source_language: null,
                                target_languages: [],
                                pre_translation_editing: null,
                                speakers: [],

                            };
                    }
                });

                Object.entries(localization.configurationValidation).forEach((validation: any) => {
                    const [key, value] = validation;
                    switch (activeService.type) {
                        case ServiceType.TRANSCRIPTION:
                            validations[key] = {
                                ...value,
                                service: true,
                                host: false,
                                guest: false,
                                source: false,
                            }
                            break
                        case ServiceType.TRANSLATION:
                            validations[key] = {
                                ...value,
                                service: true,
                                publisher_editing: false,
                                host: false,
                                guest: false,
                                target: false,
                                source: false,
                            };
                            break
                        case ServiceType.DUBBING:
                            validations[key] = {
                                ...value,
                                service: true,
                                publisher_editing: false,
                                host: false,
                                guest: false,
                                target: false,
                                source: false,
                            };
                    }
                });
            } else {
                let config: any = {...localization.episodesConfiguration[(episode as PodcastEpisode).id]};
                let validation: any = {...localization.configurationValidation[(episode as PodcastEpisode).id]};

                switch (activeService.type) {
                    case ServiceType.TRANSCRIPTION:
                        configs[(episode as PodcastEpisode).id] = {
                            ...config,
                            service: activeService,
                            source_language: null,
                            speakers: [],
                        }
                        validations[(episode as PodcastEpisode).id] = {
                            ...validation,
                            service: true,
                            host: false,
                            guest: false,
                            source: false,
                        }
                        break
                    case ServiceType.TRANSLATION:
                        configs[(episode as PodcastEpisode).id] = {
                            ...config,
                            service: activeService,
                            source_language: null,
                            target_languages: [],
                            pre_translation_editing: null,
                            speakers: [],
                        }
                        validations[(episode as PodcastEpisode).id] = {
                            ...validation,
                            service: true,
                            publisher_editing: false,
                            host: false,
                            guest: false,
                            target: false,
                            source: false,
                        }
                        break
                    case ServiceType.DUBBING:
                        configs[(episode as PodcastEpisode).id] = {
                            ...config,
                            service: activeService,
                            source_language: null,
                            target_languages: [],
                            pre_translation_editing: null,
                            speakers: [],
                        }
                        validations[(episode as PodcastEpisode).id] = {
                            ...validation,
                            service: true,
                            publisher_editing: false,
                            host: false,
                            guest: false,
                            target: false,
                            source: false,
                        };
                }
            }
            localization.setEpisodesConfiguration(configs)
            localization.setConfigurationValidation(validations)
            setSelectedService(activeService as BraincapService)
        }
    }, [activeService])

    useEffect(() => {
        if (!localization.appConfigLoading && localization.appConfigDownloaded) {
            let current: any = []
            localization.services.forEach((service: BraincapService) => {
                current.push(service)
            })
            setServices(current)
        }
    }, [localization.appConfigLoading, localization.appConfigDownloaded])

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    SERVICES
                </Text>
            </Flex>
            {localization.appConfigLoading?
                <Spinner alignSelf={'center'} size={'lg'} color={'teal'} p={'6px'}/>
                :
                <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                    <Flex w={'full'} h={'full'} direction={'row'} gap={'43px'} justifyContent={'space-between'} alignItems={'center'}>
                        {services.map((service: BraincapService) => {
                            if (service.type === ServiceType.TRANSCRIPTION && !isMultiConfiguration) {
                                if ((episode as PodcastEpisode).transcription.state !== TranscriptionState.NOT_CREATED) {
                                    return null
                                } else {
                                    return (
                                        <ServiceComponent key={service.type} processing_type={LocalizationProcessingType.POST_PUBLISHING} content={episode as PodcastEpisode} service={service} isMultiConfiguration={isMultiConfiguration} isActive={!!activeService && activeService.type === service.type} onClick={() => setActiveService(service)}/>
                                    )
                                }
                            }
                            return (
                                <ServiceComponent key={service.type} processing_type={LocalizationProcessingType.POST_PUBLISHING} service={service} content={episode as PodcastEpisode} isMultiConfiguration={isMultiConfiguration} isActive={!!activeService && activeService.type === service.type} onClick={() => setActiveService(service)}/>
                            )
                        })}
                    </Flex>
                    {!!activeService && <Box w={'full'} h={'full'} p={'12px'} justifyContent={'flex-start'} alignItems={'center'} sx={{border: '1px', borderColor: 'gray.200'}}>
                        <Grid w={'full'} templateColumns='repeat(2, 10fr)' gap={2} alignItems={'center'}>
                            {activeService.description?.map((tag: string) => (
                                <Flex key={tag} direction={'row'} gap={'4px'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <FiCheckCircle color={'teal'}/>
                                    <Text align={'start'} color="gray.600" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                        {tag}
                                    </Text>
                                </Flex>
                            ))}
                        </Grid>
                    </Box>
                    }
                </Flex>
            }
        </Flex>
    )
}
export const MediaContentServiceConfiguration = ({config,  setSelectedService}: {config: Partial<MediaContentCartItem>, setSelectedService: (selectedService: BraincapService) => void}) => {
    const localization: any = useLocalization()
    const [services, setServices] = useState<BraincapService[]>([])
    const [activeService, setActiveService] = useState<BraincapService>();

    useEffect(() => {
        if (!!activeService && !localization.appConfigLoading && localization.appConfigDownloaded) {

            setSelectedService(activeService as BraincapService)
        }
    }, [activeService])

    useEffect(() => {
        if (!localization.appConfigLoading && localization.appConfigDownloaded) {
            let current: any = []
            localization.services.forEach((service: BraincapService) => {
                current.push(service)
            })
            setServices(current)
        }
    }, [localization.appConfigLoading, localization.appConfigDownloaded])

    return (
        <Flex w={'full'} p="16px" flexDirection="column" alignItems="flex-start" gap="24px" borderRadius="12px" border="1px" borderColor="gray.300">
            <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
                <Text color="gray.800" fontSize="16px" fontStyle="normal" fontWeight="600" lineHeight="24px">
                    SERVICES
                </Text>
            </Flex>
            {localization.appConfigLoading?
                <Spinner alignSelf={'center'} size={'lg'} color={'teal'} p={'6px'}/>
                :
                <Flex w={'full'} direction={'column'} gap={'24px'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                    <Flex w={'full'} h={'full'} direction={'row'} gap={'43px'} justifyContent={'space-between'} alignItems={'center'}>
                        {services.map((service: BraincapService) => {
                            return (
                                <ServiceComponent key={service.type} service={service} processing_type={LocalizationProcessingType.PRE_PUBLISHING} isActive={!!activeService && activeService.type === service.type} onClick={() => setActiveService(service)}/>
                            )
                        })}
                    </Flex>
                    {!!activeService && <Box w={'full'} h={'full'} p={'12px'} justifyContent={'flex-start'} alignItems={'center'} sx={{border: '1px', borderColor: 'gray.200'}}>
                        <Grid w={'full'} templateColumns='repeat(2, 10fr)' gap={2} alignItems={'center'}>
                            {activeService.description?.map((tag: string) => (
                                <Flex key={tag} direction={'row'} gap={'4px'} justifyContent={'flex-start'} alignItems={'center'}>
                                    <FiCheckCircle color={'teal'}/>
                                    <Text align={'start'} color="gray.600" fontSize="14px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                        {tag}
                                    </Text>
                                </Flex>
                            ))}
                        </Grid>
                    </Box>
                    }
                </Flex>
            }
        </Flex>
    )
}
const ServiceComponent = ({service, isActive , processing_type, onClick, isMultiConfiguration, content}: {service: BraincapService, isActive: boolean, processing_type: LocalizationProcessingType, onClick: () => void, isMultiConfiguration?: boolean, content?: PodcastEpisode | MediaContent}) => {
    const localization: any = useLocalization()
    const [icon, setIcon] = useState<any>(<LoadingSpinner/>)
    const [addTranscriptionPrice, setAddTranscriptionPrice] = useState<boolean>(false)
    const [transcriptionPrice, setTranscriptionPrice] = useState<number>(0)
    const [translationPrice, setTranslationPrice] = useState<number>(0)
    const toasts: any = useToast()

    useEffect(() => {
        if (!localization.appConfigLoading && localization.appConfigDownloaded) {
            setTranscriptionPrice(localization.services.get(ServiceType.TRANSCRIPTION).price)
            setTranslationPrice(localization.services.get(ServiceType.TRANSLATION).price)
            if (service) {
                switch (service.type) {
                    case ServiceType.TRANSCRIPTION:
                        setIcon(<TranscriptionIcon/>)
                        break
                    case ServiceType.TRANSLATION: {
                        if (!isMultiConfiguration) {
                            if (processing_type === LocalizationProcessingType.POST_PUBLISHING) {
                                if (content?.transcription.state === TranscriptionState.NOT_CREATED) {
                                    setAddTranscriptionPrice(true)
                                }
                            } else if (processing_type === LocalizationProcessingType.PRE_PUBLISHING) {
                                setAddTranscriptionPrice(true)
                            }
                        }
                        setIcon(<TranslationIcon/>)
                    }
                        break
                    case ServiceType.DUBBING: {
                        if (!isMultiConfiguration) {
                            if (processing_type === LocalizationProcessingType.POST_PUBLISHING) {
                                if (content?.transcription.state === TranscriptionState.NOT_CREATED) {
                                    setAddTranscriptionPrice(true)
                                }
                            } else if (processing_type === LocalizationProcessingType.PRE_PUBLISHING) {
                                setAddTranscriptionPrice(true)
                            }
                        }
                        setIcon(<DubbingIcon/>)
                    }
                        break
                }
            }
        }
    }, [localization.appConfigLoading, localization.appConfigDownloaded, service, isMultiConfiguration, content])

    const handleOnclick = () => {
        if (service.type === ServiceType.DUBBING) {
            toasts({
                title: 'Coming soon',
                description: 'Dubbing is not available yet. Coming in 2024!',
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
        } else if (service.type === ServiceType.TRANSLATION && processing_type === LocalizationProcessingType.PRE_PUBLISHING) {
            toasts({
                title: 'Coming soon',
                description: 'Translation is not available yet for pre-publishing.',
                status: 'info',
                duration: 3000,
                isClosable: true,
            })
        }
        else {
            onClick()
        }
    }

    return (
        <Box
            as={'button'}
            w={'full'}
            h={'full'}
            p={'12px'}
            justifyContent={'flex-start'}
            _hover={{borderRadius:'12px', boxShadow: 'lg'}}
            sx={{borderRadius:'12px', border: '1px', borderColor: isActive? 'gray.200' : 'transparent', boxShadow: isActive ? 'md' : 'none'}}
            onClick= {handleOnclick}
        >
            <Flex w={'full'} gap={'12px'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                <Text align={'start'} color="gray.700" fontSize="16px" fontStyle="normal" fontWeight="500" lineHeight="24px">
                    {service.type}
                </Text>
                {icon}
                <Flex w={'full'} p={'6px 12px'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent' flexDirection={'column'} justifyContent={'space-between'} gap={'4px'}>
                        <Text align={'start'} color="gray.500" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                            {service.type.charAt(0).toUpperCase() + service.type.slice(1).toLowerCase()} fee:
                        </Text>
                    <Text as={'span'} fontWeight={'500'} color={'teal.500'}>
                        {service.type === ServiceType.TRANSCRIPTION? `$${service.price}/min per episode` : `$${service.price}/min per language`}
                    </Text>
                </Flex>
                {service.type === ServiceType.DUBBING && isActive &&
                    <>
                        <Flex w={'full'} p={'6px 12px'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent' flexDirection={'column'} justifyContent={'space-between'} gap={'4px'}>
                            <Text align={'start'} color="gray.500" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Untranslated languages fee:
                            </Text>
                            <Text as={'span'}  fontWeight={'500'} color={'teal.500'}>+ ${translationPrice}/min per language</Text>
                        </Flex>
                        <Flex w={'full'} p={'6px 12px'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent' flexDirection={'column'} justifyContent={'space-between'} gap={'4px'}>
                            <Text align={'start'} color="gray.500" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Description and Title translation fee:
                            </Text>
                            <Text as={'span'} fontWeight={'500'} color={'teal.500'}>$0.05/word per language</Text>
                        </Flex>
                    </>
                }
                {addTranscriptionPrice && service.type !== ServiceType.TRANSCRIPTION && isActive &&
                    <>
                        <Flex w={'full'} p={'6px 12px'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent' flexDirection={'column'} justifyContent={'space-between'} gap={'4px'}>
                            <Text align={'start'} color="gray.500" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Description and Title translation fee:
                            </Text>
                            <Text as={'span'} fontWeight={'500'} color={'teal.500'}>$0.05/word per language</Text>
                        </Flex>
                        <Flex w={'full'} p={'6px 12px'} alignItems={'center'} borderRadius='6px' border={'1px'} borderColor={'gray.300'} bgColor='transparent' flexDirection={'column'} justifyContent={'space-between'} gap={'4px'}>
                            <Text align={'start'} color="gray.500" fontSize="12px" fontStyle="normal" fontWeight="400" lineHeight="20px">
                                Non-transcribed episode fee:
                            </Text>
                            <Text as={'span'} fontWeight={'500'} color={'teal.500'}>+ ${transcriptionPrice}/min per episode</Text>
                        </Flex>
                    </>
                }
            </Flex>
        </Box>
    )
}
