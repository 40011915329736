import React, {createContext, useContext, useEffect, useState} from "react";
import {ENDPOINTS, useApi} from "../api/braincap-api";
import {useAuth} from "./AuthContext";
import {User, UserCategory} from "../models/Users";
import {NotificationSettings} from "../models/BraincapNotification";
import {useToast} from "@chakra-ui/react";

export const LibraryNavigationContext = createContext(null)

export enum LibraryNavigationRoutes {
    DASHBOARD = 'Dashboard',
    LIBRARY = 'Library',
    NOTIFICATIONS = 'Notifications',
    CATALOG = 'Catalog',
    TEAMS = 'Teams',
}

export default function LibraryNavigationProvider(props: any) {
    const auth: any = useAuth()
    const [route, setRoute] = useState<LibraryNavigationRoutes>(LibraryNavigationRoutes.DASHBOARD)

    return (
        <LibraryNavigationContext.Provider value = {{
            route,
            setRoute
        }} {...props} />
    )
}

export const useLibraryNavigation = () => useContext(LibraryNavigationContext)



