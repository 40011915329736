import {IconType} from "react-icons";
import {
    FiAlertTriangle,
    FiArchive,
    FiCompass,
    FiHome,
    FiMenu,
    FiMessageCircle,
    FiSettings,
    FiStar,
    FiTarget,
    FiTrendingUp
} from "react-icons/fi";
import { RiDashboardFill } from "react-icons/ri";
import { GrMultimedia } from "react-icons/gr";
import { RiTeamFill } from "react-icons/ri";
import { VscFolderLibrary } from "react-icons/vsc";
import {
    Box,
    BoxProps,
    CloseButton, color, Drawer, DrawerContent,
    Flex,
    FlexProps,
    Icon,
    IconButton,
    Text,
    useColorModeValue, useDisclosure
} from "@chakra-ui/react";
import {AppLogo} from "../../assets/AppLogo";
import React, {ReactNode, ReactText, useEffect, useState} from "react";
import PodcastModule from "../../components/podcast/podcast-module";
import {useLibrary} from "../../context/LibraryContext";
import {PodcastEpisode} from "../../models/PodcastEpisode";
import {TranscriptionSession, TranscriptionState} from "../../models/Localization";
import {PaymentStatus} from "../../models/Cart";
import {BackCatalogModule} from "../../components/episode/backcatalog-module";
import CartPanel from "../../components/cart/cart-panel";
import {ChevronLeftIcon} from "@chakra-ui/icons";
import {Link, useParams} from "react-router-dom";
import {PublisherReviewModule} from "../../components/episode/publisher-review-module";
import { FaTasks } from "react-icons/fa";
import PodcastPostProductionLibrary from "./PodcastPostProductionLibrary";
import {MemberSettings, ProductionTeamSettings} from "../../components/settings/team-settings";
import {PublisherNotificationSettings} from "../../components/settings/publisher-notification-settings";
import {AiFillNotification} from "react-icons/ai";
import {LibraryNavigationRoutes, useLibraryNavigation} from "../../context/LibraryNavigationContext";



interface LinkItemProps {
    name: string;
    icon: IconType;
    content?: ReactNode;
}


export default function PodcastSidebar({ podcast }: { podcast: any }) {
    const library: any = useLibrary()
    const libraryNavigation: any = useLibraryNavigation()
    const [episodes, setEpisodes] = useState<PodcastEpisode[]>([])
    const [processingEpisodes, setProcessingEpisodes] = useState<PodcastEpisode[]>([])
    const [episodeInReview, setEpisodeInReview] = useState<TranscriptionSession[]>([])
    const [completedEpisodes, setCompletedEpisodes] = useState<PodcastEpisode[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const { podcastId }: any = useParams();

    useEffect(() => {
        if (library.episodesDownloaded && !library.episodeLoading && library.cartDownloaded && !library.cartLoading && !library.reviewEpisodesLoading) {
            let episodes = library.podcastEpisodes.get(podcastId)
            //removes episode from episodes if library.cartMap.has(episode.id)
            let backCatalogEpisodes: PodcastEpisode[] = []
            let processedEpisodes: PodcastEpisode[] = []
            let doneEpisodes: PodcastEpisode[] = []
            if (episodes) {
                for (let i = 0; i < episodes.length; i++) {
                    let episode = episodes[i]
                    if (episode.transcription.state !== TranscriptionState.NOT_CREATED &&
                        episode.transcription.state !== TranscriptionState.COMPLETED &&
                        episode.transcription.state !== TranscriptionState.PROOFREADING_COMPLETED
                        && episode.transcription.state !== TranscriptionState.PUBLISHER_REVIEWING &&
                        episode.transcription.state !== TranscriptionState.EDITOR_REVIEW &&
                        episode.transcription.state !== TranscriptionState.EDITOR_REVIEWING &&
                        episode.transcription.state !== TranscriptionState.EDITOR_REVIEW_COMPLETED) {
                        processedEpisodes.push(episode)
                    } else if (episode.transcription.state === TranscriptionState.COMPLETED) {
                        doneEpisodes.push(episode)
                    } else if (episode.transcription.state === TranscriptionState.PROOFREADING_COMPLETED) {
                        doneEpisodes.push(episode)
                    }

                    if (episode.transcription.state === TranscriptionState.NOT_CREATED) {
                        {
                            if (library.cartMap.has(episode.id)) {
                                if (library.cartMap.get(episode.id)['status'] === PaymentStatus.CHECKED_OUT) {
                                    processedEpisodes.push(episode)
                                } else {
                                    backCatalogEpisodes.push(episode)
                                }
                            } else {
                                backCatalogEpisodes.push(episode)
                            }
                        }
                    }
                }
            }

            backCatalogEpisodes.sort((a: PodcastEpisode, b: PodcastEpisode) => {
                return a.pubDate > b.pubDate ? -1 : 1
            })
            setEpisodes(backCatalogEpisodes)
            setProcessingEpisodes(processedEpisodes)
            if (library.episodeInReview.length > 0) {
                setEpisodeInReview(library.episodeInReview)
            }
            setCompletedEpisodes(doneEpisodes)
            setLoading(false)
        }

        if (library.episodeLoading) {
            setLoading(true)
        }
    }, [library.episodesDownloaded, library.episodeLoading, library.cartLoading, library.cartDownloaded, library.cart, library.reviewEpisodesLoading])

    const { isOpen: isOpenCart, onOpen: onOpenCart, onClose: onCloseCart } = useDisclosure();


    const { isOpen, onOpen, onClose } = useDisclosure();

    let LinkItems: Map<LibraryNavigationRoutes, LinkItemProps> = new Map<LibraryNavigationRoutes, LinkItemProps>([])
    LinkItems.set(LibraryNavigationRoutes.DASHBOARD, { name: LibraryNavigationRoutes.DASHBOARD, icon: RiDashboardFill , content: <PodcastModule podcast={podcast} />})
    LinkItems.set(LibraryNavigationRoutes.LIBRARY, { name: LibraryNavigationRoutes.LIBRARY, icon: VscFolderLibrary , content: <PodcastPostProductionLibrary podcast={podcast} loading={loading} />})
    LinkItems.set(LibraryNavigationRoutes.CATALOG, { name: LibraryNavigationRoutes.CATALOG, icon: FiArchive , content: <>
                <BackCatalogModule episodes={episodes as  PodcastEpisode[]} podcastId={podcastId} loading={loading}/>
                <CartPanel isOpen={isOpenCart} onOpen={onOpenCart} onClose={onCloseCart}/>
            </> })
    LinkItems.set(LibraryNavigationRoutes.TEAMS, { name: LibraryNavigationRoutes.TEAMS, icon: RiTeamFill , content:
            <Flex w={'full'} direction={'column'} alignItems={'flex-start'} gap="24px" alignSelf={'stretch'}>
                <ProductionTeamSettings podcast={podcast}/>
                <MemberSettings podcast={podcast}/>
            </Flex>
    })
    LinkItems.set(LibraryNavigationRoutes.NOTIFICATIONS, { name: LibraryNavigationRoutes.NOTIFICATIONS, icon: AiFillNotification , content: <PublisherNotificationSettings/>})

    const [content, setContent] = React.useState<ReactNode>(LinkItems.get(LibraryNavigationRoutes.DASHBOARD)?.content as ReactNode);


    useEffect(() => {
        console.log(`LibraryNavigationRoutes: ${libraryNavigation.route}`)
        setContent(LinkItems.get(libraryNavigation.route)?.content as ReactNode)
    }, [libraryNavigation.route]);


    return (
        <Flex w={'full'} minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={() => onClose}
                display={{ base: 'none', md: 'block' }}
                linkItems={LinkItems}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} linkItems={LinkItems} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <Flex w={'full'} ml={{ base: 0, md: '150px' }} p="4">
                {content}
            </Flex>
        </Flex>
    );
}
interface SidebarProps extends BoxProps {
    onClose: () => void;
    linkItems: Map<LibraryNavigationRoutes, LinkItemProps>
}

const SidebarContent = ({ onClose, linkItems, ...rest }: SidebarProps) => {
    return (
        <Box
            bg={'#111827'}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={'fit-content'}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" justifyContent={'center'} mx="8" gap={'8px'}>
                <Link to={'/library'}>
                    <ChevronLeftIcon  boxSize={'36px'} style={{color: 'white'}}/>
                </Link>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {Array.from(linkItems.values()).map((link) => (
                <NavItem key={link.name} icon={link.icon} link={link}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    link: LinkItemProps;
}
const NavItem = ({ icon, link, children, ...rest }: NavItemProps) => {
    const libraryNavigation: any = useLibraryNavigation()
    return (
        <Flex
            align="center"
            p="4"
            borderRadius="lg"
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            onClick={() => libraryNavigation.setRoute(link.name as LibraryNavigationRoutes)}
            role="group"
            cursor="pointer"
            bg={libraryNavigation.route === link.name ? 'cyan.400' : 'transparent'}
            {...rest}>
            {icon && (
                <Icon
                    fontSize="16"
                    color={'white'}
                    _groupHover={{
                        color: 'white',
                    }}
                    as={icon}
                />
            )}
            <Text color="white">{children}</Text>
        </Flex>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 24 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
                Logo
            </Text>
        </Flex>
    );
};
