import {Publisher} from "./Publisher";
import {Language} from "./AppConfig";
import {SpeakerEpisodeMeta, SpeakerInvitation} from "./Speakers";
import {EpisodeLocalizationMeta} from "./PodcastEpisode";
import {PodcastLocalizationMeta} from "./Podcast";
import {LabelerMeta} from "./Labeler";
import {AssemblyResponse, ProofreadTranscriptProcessor} from "./Transcription";
import {ReviewSession} from "./Editor";
import {MediaContentTranscriptionTaskMeta} from "./MediaContent";

//Service Request
export interface ServiceRequest {
    service: BraincapService
    customer: Pick<Publisher, 'id' | 'name' | 'email' | 'phone' | 'category'>
}

export interface TranscriptionServiceRequest extends ServiceRequest {
    source_language: Language
    speakers: SpeakerInvitation[]
    guidelines?: string
    instructions?: string[]
}

export interface TranslationServiceRequest extends TranscriptionServiceRequest {
    target_languages: Language[]
    ads_configuration: boolean
    pre_translation_editing: boolean
}

export interface DubbingServiceRequest extends TranslationServiceRequest {
    audio_tracks?: string[]
}

//Localization Tasks
export interface LocalizationTask {
    id: string
    created_at: any
    completed_at?: any
    status: LocalizationStatus
    processing_type: LocalizationProcessingType
}

export interface PodcastTranscriptionTask extends TranscriptionServiceRequest, LocalizationTask {
    podcast: PodcastLocalizationMeta,
    episode: Omit<EpisodeLocalizationMeta, 'transcription'>
}

export interface PodcastTranslationTask extends PodcastTranscriptionTask, TranslationServiceRequest {
    current_transcription_state: TranscriptionState
}
export interface PodcastDubbingTask extends PodcastTranslationTask, DubbingServiceRequest {}

export type TranscriptionSessionTracker = Pick<PodcastTranscriptionTask, 'id'| 'service' | 'created_at'| 'status' | 'completed_at'>
export type TranslationSessionsTracker = Pick<PodcastTranslationTask, 'id'| 'service' | 'created_at'| 'status' | 'current_transcription_state'| 'completed_at'>
export type DubbingSessionsTracker = Pick<PodcastDubbingTask, 'id'| 'service' | 'created_at'| 'status' | 'current_transcription_state'| 'completed_at'>

export type LocalizationTaskConfiguration<LocalizationTask> = Omit<LocalizationTask, 'id' | 'created_at' | 'completed_at' | 'status'>;

export type PodcastTranscriptionConfig = LocalizationTaskConfiguration<PodcastTranscriptionTask> & {
    episode: EpisodeLocalizationMeta
}
export type PodcastTranslationConfig = LocalizationTaskConfiguration<Omit<PodcastTranslationTask, 'current_transcription_state'>> & {
    episode: EpisodeLocalizationMeta
}
export type PodcastDubbingConfig = LocalizationTaskConfiguration<PodcastDubbingTask> & {
    episode: EpisodeLocalizationMeta
}

export type PodcastTaskConfig = PodcastTranscriptionConfig | PodcastTranslationConfig | PodcastDubbingConfig
export type PodcastTask = PodcastTranscriptionTask | PodcastTranslationTask | PodcastDubbingTask
export interface LocalizationSession {
    price: number
    created_at: any
    labeler?: LabelerMeta,
    review_session?: ReviewSession,
    started_at?: any
    last_updated?: any
    completed_at?: any
}

export type PodcastTranscriptionTaskMeta = Pick<PodcastTranscriptionTask, 'id' | 'source_language' | 'podcast' |'episode' | 'speakers'| 'processing_type'>
export type TranscriptionSession =  LocalizationSession & (PodcastTranscriptionTaskMeta | MediaContentTranscriptionTaskMeta)
    & {
    state: TranscriptionState
    type: ServiceType.TRANSCRIPTION,
    assembly_response?: AssemblyResponse
    youtube_url?: string
    youtube_video_download?: 'pending' | 'completed' | 'failed'
    rev_response?: any
}

export type PodcastTranslationTaskMeta = Pick<PodcastTranslationTask, 'id' | 'source_language' | 'podcast' |'episode'| 'speakers'| 'processing_type'>
export type TranslationSession =  LocalizationSession & PodcastTranslationTaskMeta & {
    target_language: Language
    type: ServiceType.TRANSLATION
    state: TranslationState
    youtube_url?: string
}

export type DubbingSession =  LocalizationSession & Pick<PodcastDubbingTask, 'id' | 'source_language' | 'podcast' |'episode'| 'audio_tracks'| 'processing_type'> & {
    speaker: SpeakerEpisodeMeta
    target_language: Language
    type: ServiceType.DUBBING
    state: DubbingState
}

export type BraincapTask = TranscriptionSession | TranslationSession | DubbingSession

export type TranscriptionSessionMeta = Pick<TranscriptionSession, 'id' | 'source_language'| 'created_at' | 'started_at' | 'completed_at'>

export interface TranscriptionProgress {
    state: TranscriptionState
    session?: TranscriptionSessionMeta
    processor?: ProofreadTranscriptProcessor
}

export interface LocalizationProgress {
    processing: Language[]
    completed: Language[]
}
export interface LanguageConfiguration {
    source: Language,
    target: Language[]
}

export interface AdsConfiguration {
    localize_ads: boolean
    ads?: string[]
}

export interface BraincapService {
    type: ServiceType
    price: number
    stripe_id: string
    description: string[]
}

export enum ServiceType {
    TRANSCRIPTION = "TRANSCRIPTION",
    TRANSLATION = "TRANSLATION",
    TRANSCREATION = "TRANSCREATION",
    DUBBING = "DUBBING",
}
export enum LocalizationStatus {
    NOT_STARTED = "NOT_STARTED",
    CREATED = "CREATED",
    TRANSCRIBING = "TRANSCRIBING",
    PUBLISHER_EDITING = "PUBLISHER_EDITING",

    REVIEWING = "REVIEWING",
    TRANSLATING = "TRANSLATING",
    TRANSCREATION = "TRANSCREATION",
    DUBBING = "DUBBING",
    MIXING = "MIXING",
    TRANSCRIBED = "TRANSCRIBED",
    TRANSLATED = "TRANSLATED",
    DUBBED = "DUBBED",
    BLOCKED = "BLOCKED",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED"
}

export enum LocalizationProcessingType {
    PRE_PUBLISHING = "PRE_PUBLISHING",
    POST_PUBLISHING = "POST_PUBLISHING",
    LIVE = "LIVE"
}

export enum TranscriptionState {
    NOT_CREATED = "NOT_CREATED",
    CREATED = "CREATED",
    SPEECH_TO_TEXT = 'SPEECH_TO_TEXT',
    SPEECH_TO_TEXT_RUNNING = 'SPEECH_TO_TEXT_RUNNING',
    STORAGE_SAVING_ERROR = 'STORAGE_SAVING_ERROR',
    ASSEMBLY_AI_TRANSCRIPTION_ERROR = 'ASSEMBLY_AI_TRANSCRIPTION_ERROR',
    ASSEMBLY_AI_FETCHING_ERROR = 'ASSEMBLY_AI_FETCHING_ERROR',
    SPEECH_TO_TEXT_CREATION_ERROR = 'SPEECH_TO_TEXT_CREATION_ERROR',
    SPEECH_TO_TEXT_ERROR = 'SPEECH_TO_TEXT_ERROR',
    READY_FOR_PROOFREADING = "READY_FOR_PROOFREADING",
    READY_FOR_PROOFREADING_ERROR = "READY_FOR_PROOFREADING_ERROR",
    PROOFREADING = "PROOFREADING",
    PROOFREADING_COMPLETED = "PROOFREADING_COMPLETED",
    BLOCKED = "BLOCKED",
    CANCELED = "CANCELED",
    EDITOR_REVIEW = "EDITOR_REVIEW",
    EDITOR_REVIEWING = "EDITOR_REVIEWING",
    PUBLISHER_EDITING = "PUBLISHER_EDITING",
    PUBLISHER_REVIEWING = "PUBLISHER_REVIEWING",
    EDITOR_REVIEW_COMPLETED = "EDITOR_REVIEW_COMPLETED",
    COMPLETED = "COMPLETED",

}
export enum TranslationState {
    NOT_CREATED = "NOT_CREATED",
    CREATED = "CREATED",
    WAITING_FOR_TRANSCRIPTION = 'WAITING_FOR_TRANSCRIPTION',
    WAITING_FOR_PUBLISHER_EDIT = 'WAITING_FOR_PUBLISHER_EDIT',
    READY_FOR_TRANSLATION = "READY_FOR_TRANSLATION",
    TRANSLATING = "TRANSLATING",
    BLOCKED = "BLOCKED",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED"
}

export enum DubbingState {
    NOT_CREATED = "NOT_CREATED",
    CREATED = "CREATED",
    WAITING_FOR_TRANSLATION = 'WAITING_FOR_TRANSLATION',
    WAITING_FOR_SPEAKER_SELECTION = 'WAITING_FOR_VOICE_CONFIGURATION',
    BLOCKED = "BLOCKED",
    CANCELED = "CANCELED",
    COMPLETED = "COMPLETED"
}
